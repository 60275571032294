import { ActionTypes } from '../actions/actionTypes';
import { PaymentResult } from '../dataObjects/space'
import {
  RESERVATION_BEGIN_PAYMENT_START,
  RESERVATION_BEGIN_PAYMENT_SUCCESS,
  RESERVATION_BEGIN_PAYMENT_INVALID,
  RESERVATION_BEGIN_PAYMENT_FAIL
} from '../actions/spaceReservationAction';

/* 決済情報 */
const initialPaymentResultState: PaymentResult = {  
  payload: {        
    client_secret: ''
  },
  validationResult: [{
    item: undefined,
    error: undefined
  }]
}

/* 決済Reducer */
const payment = (
  state: PaymentResult = initialPaymentResultState,
  action: ActionTypes,
): PaymentResult => {
  switch (action.type) {
    // 決済処理
    case RESERVATION_BEGIN_PAYMENT_START:
      return {
        ...state,
        ...action.payload
      }
    case RESERVATION_BEGIN_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case RESERVATION_BEGIN_PAYMENT_INVALID:
      return { ...state }
    case RESERVATION_BEGIN_PAYMENT_FAIL:
      return { ...state };
    
    default:
      return { ...state };
  }
};

export default payment

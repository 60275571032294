import React, { useEffect } from 'react'
import moment from 'moment'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import {
  fetchPeriodAtDayAction,
  selectPeriodAtDayAction,
  fetchSpaceMonthlyScheduleStatusAction,
} from '../actions/spacePageDataAction'
import {
  reservationCreateInvoiceAction
} from '../actions/spaceReservationAction'

import ScheduleCalendar, { DayContent, ScheduleCalendarProps } from '../components/Space/ScheduleCalendar'
import { Period, DayStatus } from '../dataObjects/space';
import { observeShownAction } from 'actions/pagingAction';
import { isArray } from 'util';


interface OwnProps {
  space_id: number,
  basedate: Date
}

export type ScheduleCalendarContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

export default function ScheduleCalendarContainer(props: ScheduleCalendarContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()  

  /* カレンダー日付選択 */
  const onChangeDate = (date: Date | Date[]) => {
    console.log(date)
    let selDate
    if (Array.isArray(date)) {      
      selDate = date[0]
    } else {
      selDate = date
    }
    console.log('calendar.onChangeDate')
    console.log({selDate})
    dispatch(fetchPeriodAtDayAction(props.space_id, selDate))
  }

  /* 予約時間コマ選択 */
  const onChangeSelectedPeriod = (event: any, value: any) => {
    console.log(value)
    let periodData: Period[] = []
    if (Array.isArray(value)) {
      value.map(v => {
        const d = state.viewingSpace.periodsAtDate.find(p => p.id === v)
        if (d) {
          periodData.push(d)
        }
      })
    } else {
      const d = state.viewingSpace.periodsAtDate.find(p => p.id === value)
      if (d) {
        periodData.push(d)
      }    
    }

    // 選択コマ保存
    dispatch(selectPeriodAtDayAction({
      periodsAtDate: periodData
    }))
    // 請求情報作成
    dispatch(reservationCreateInvoiceAction({
      periodPrices: periodData,
      discount: state.reservationSpace.payload.invoice.discount
    }))
  }

  /* DayContent生成 */    
  const createDayContentArray = (schedules: DayStatus[]) => {
    const obj = {}
    if(schedules) {
      if(isArray(schedules)){
        schedules.forEach(s => {
          const mday = moment(s.day)
          const content: DayContent = {
            isClose: s.isClosing,
            text: '',
            value: s.occupancyRate 
          }
          obj[mday.format('YYYYMMDD')] = content              
        });
      }
    }
    return obj    
  }

  const baseDate = state.viewingSpace.selectedDate ? state.viewingSpace.selectedDate
                    : props.basedate ? props.basedate
                    : new Date()


  const maxDate = new Date(baseDate)
  maxDate.setMonth(maxDate.getMonth() + 3)

  // 初期化
  useEffect(() => {
    console.log('ScheduleCalendarContainer useEffect Executing')
    if(props.space_id !== 0) {
      dispatch(fetchSpaceMonthlyScheduleStatusAction(props.space_id, new Date(), maxDate))
      dispatch(fetchPeriodAtDayAction(props.space_id, baseDate))    
    }
    dispatch(observeShownAction())
  }, []);
  
  const _props: ScheduleCalendarProps = {
    minDate: new Date(),
    maxDate: maxDate,
    baseDate: baseDate,
    monthDays: createDayContentArray(state.viewingSpace.monthlyScheduleStatus),
    periods: state.viewingSpace.periodsAtDate,
    periodsLoading: state.page.loading,
    onChangeDate: onChangeDate,
    onChangeSelectedPeriod: onChangeSelectedPeriod
  }

  return <ScheduleCalendar {..._props} />

}
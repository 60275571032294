import * as React from 'react';
import { Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container, makeStyles, Typography, Grid } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      width: '450px'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.4rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'center',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
    contentContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px',
        whiteSpace: 'pre-line'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px',
          whiteSpace: 'pre-line'
        },
      }
    },
  }),
);

interface OwnProps {  
  dialogOpen: boolean,
  handleClose: () => void
}

type NotifyDialogProps = OwnProps


const NotifyDialog: React.FC<NotifyDialogProps> = (props: NotifyDialogProps) => {
  const classes = useStyles({})
    
  return (
    <Dialog
      open={props.dialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">対応ブラウザ</DialogTitle>
      <DialogContent dividers style={{display: 'flex'}} className={classes.dialogContainer}>
        <div className={classes.contentContainer}>
          <Typography variant="body1" className={classes.description} style={{ whiteSpace: 'pre-line' }}>
            当サイトはInternetExplorerに対応しておりません。
            対応ブラウザは以下になります。
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>

              <Typography variant="h3" className={classes.subtitle}>
                デスクトップ・ノートPC
              </Typography>

              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>Windows</th>
                    <th>mac</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chrome 68以上</td>
                    <td>Chrome 68以上</td>
                  </tr>
                  <tr>
                    <td>Edge 42以上</td>
                    <td>Safari 11以上</td>
                  </tr>
                  <tr>
                    <td>Firefox 61以上</td>
                    <td>Firefox 61以上</td>
                  </tr>
                </tbody>
              </table>

              <Typography variant="h3" className={classes.subtitle}>
                スマートフォン
              </Typography>

              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>Android</th>
                    <th>iOS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chrome 61, 68以上</td>
                    <td>Chrome 68以上</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Safari 11以上</td>
                  </tr>
                </tbody>
              </table>

            </Grid>
          </Grid>
                  
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
  
}


export default NotifyDialog
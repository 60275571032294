import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import DirectionsSubwayIcon from "@material-ui/icons/DirectionsSubway";
import PowerIcon from "@material-ui/icons/Power";
import SignalWifi4BarLockIcon from "@material-ui/icons/SignalWifi4BarLock";
import VideocamIcon from "@material-ui/icons/Videocam";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import WcIcon from "@material-ui/icons/Wc";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";
import DeckIcon from "@material-ui/icons/Deck";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import AccessibleIcon from "@material-ui/icons/Accessible";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AirlineSeatLegroomReducedIcon from '@material-ui/icons/AirlineSeatLegroomReduced';
import AirplayIcon from '@material-ui/icons/Airplay';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import Forward5Icon from '@material-ui/icons/Forward5';
import Forward10Icon from '@material-ui/icons/Forward10';
import Forward30Icon from '@material-ui/icons/Forward30';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import HdIcon from '@material-ui/icons/Hd';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import WeekendIcon from '@material-ui/icons/Weekend';



export const iconList = {
  AddAPhotoIcon: AddAPhotoIcon,
  ChildCareIcon: ChildCareIcon,
  DirectionsSubwayIcon: DirectionsSubwayIcon,
  PowerIcon: PowerIcon,
  SignalWifi4BarLockIcon: SignalWifi4BarLockIcon,
  VideocamIcon: VideocamIcon,
  ViewCarouselIcon: ViewCarouselIcon,
  WcIcon: WcIcon,
  WbIncandescentIcon: WbIncandescentIcon,
  LocalParkingIcon: LocalParkingIcon,
  DirectionsBikeIcon: DirectionsBikeIcon,
  SmokingRoomsIcon: SmokingRoomsIcon,
  SmokeFreeIcon: SmokeFreeIcon,
  RestaurantIcon: RestaurantIcon,
  HotelIcon: HotelIcon,
  DeckIcon: DeckIcon,
  AudiotrackIcon: AudiotrackIcon,
  AccessibleIcon: AccessibleIcon,  
  AccessTimeIcon: AccessTimeIcon,
  AccessibilityNewIcon: AccessibilityNewIcon,
  AccountCircleIcon: AccountCircleIcon,
  AirlineSeatLegroomReducedIcon: AirlineSeatLegroomReducedIcon,
  AirplayIcon: AirplayIcon,
  FireplaceIcon: FireplaceIcon,
  Forward5Icon: Forward5Icon,
  Forward10Icon: Forward10Icon,
  Forward30Icon: Forward30Icon,
  GTranslateIcon: GTranslateIcon,
  HdIcon: HdIcon,
  SurroundSoundIcon: SurroundSoundIcon,
  VideogameAssetIcon: VideogameAssetIcon,
  WeekendIcon: WeekendIcon
};

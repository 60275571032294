import React from 'react';
import BasePageContainer from '../containers/BasePageContainer';
import { routes } from '../routes/Route';
import { Button, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link, LinkProps } from 'react-router-dom';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import 'moment/locale/ja';
import moment from 'moment-timezone'

import './Shareal.css';
import BasePageComponent from 'components/BasePageComponent';
import { News } from 'dataObjects/news';

import * as Sentry from "@sentry/browser";

moment.tz.setDefault('Asia/Tokyo'); // タイムゾーンをJSTに設定

interface StypeProps {
  paper: BaseCSSProperties,
  textarea: BaseCSSProperties,
  checklist: BaseCSSProperties,
  img: BaseCSSProperties,
  button: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textarea: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  checklist: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  img: {
    width: '70%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface OwnProps {
  newsList: { [key: number]: News }
}

export type TopPageProps = OwnProps

const TopPage: React.FC<TopPageProps> = (props: TopPageProps) => {
  const theme = useTheme();
  const classes = useStyles({} as StypeProps);
  const xssmMatches = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <BasePageContainer>
      <article id="title">
        <h1>
          <img src="/images/logomark.svg" alt="Shareal あなたの生活を彩るレンタルスペース" />
        </h1>
        <h1 id="introduction">
          パーティー？カルチャースクール？スタジオ？会議室？
          <br />  使い方次第で多様な使い道
          <br />
          西鈴蘭台駅前１階のおしゃれなレンタルスペース
        </h1>
        <div id="scroll">
          <a href="#concept">
            <i>SCROOL</i>
            <span></span>
          </a>
        </div>
      </article>
      <article id="concept">
        <div>
          <header>
            <h2>私たちの想い</h2>
            <i>Concept</i>
          </header>
          <section>
            <p>
              この名前は「Share(共有)」と「Realestate(不動産)」で作った造語です。
              <br />
              不動産を月極で借りるとなると毎月大きな出費が必要。
              <br />
              この現状を変えたい。不動産を時間貸しさせていただくことで、
              <br className="pc" />
              もっと気軽に、もっと安く、必要な時間だけ地域の方々にご提供したい。
              <br className="pc" />
              そうすることで不動産の可能性はもっと広がり、地域も元気になっていく。
              <br />
              こんな想いを描きながら、この事業を立ち上げました。
              <br />
              あなたの生活空間に「Shareal」をプラスしてあなたの人生にもっと彩りを。
              <br className="pc" />
              そして「Shareal」で沢山の方々が共に同じ時間を過ごし(Share)、
              <br className="pc" />
              たくさんのリアル(Real)な出会いや繋がりができますように。
            </p>
          </section>
        </div>
      </article>
      <article id="howto">
        <header>
          <h2>利用方法</h2>
          <i>How to use</i>
        </header>
        <section>
          <ol>
            <li>
              <i className="num">01</i>
              <figure>
                <img src="/images/howto01.jpg" />
              </figure>
              <div>
                <h3>スペースを選ぶ</h3>
                <p>利用条件や設備をご確認ください</p>
              </div>
            </li>
            <li>
              <i className="num">02</i>
              <figure>
                <img src="/images/howto02.jpg" />
              </figure>
              <div>
                <h3>スケジュール予約</h3>
                <p>ご利用希望日時を指定し利用予約を行ってください。</p>
              </div>
            </li>
            <li>
              <i className="num">03</i>
              <figure>
                <img src="/images/howto03.jpg" />
              </figure>
              <div>
                <h3>スペースを利用</h3>
                <p>ご利用日当日に直接スペースにお越しください。</p>
              </div>
            </li>
          </ol>
        </section>
      </article>
      <article id="search">
        <section className="btn">
          <Link to={routes.space.getPath("1")}>スペースを選ぶ</Link>
        </section>
        <br />
        <section className="btn">
          <Link to={routes.spaceRegularCalendar.getPath("1")}>
            イベントを探す
          </Link>
        </section>
      </article>
      <article id="application">
        <header>
          <h2>スペース利用用途</h2>
          <i>Application</i>
        </header>
        <section>
          <ul>
            <li>
              <figure>
                <img src="/images/application01.jpg" />
                <figcaption>
                  各種パーティー<i>Party</i>
                </figcaption>
              </figure>
              <p>お誕生日会やスポーツ観戦、友人との集まりに</p>
            </li>
            <li>
              <figure>
                <img src="/images/application02.jpg" />
                <figcaption>
                  会議や会合<i>Meeting</i>
                </figcaption>
              </figure>
              <p>会社の会議や地元の会合に</p>
            </li>
            <li>
              <figure>
                <img src="/images/application03.jpg" />
                <figcaption>
                  セミナー<i>Seminar</i>
                </figcaption>
              </figure>
              <p>お客様向けセミナーや各種団体のセミナーに</p>
            </li>
            <li>
              <figure>
                <img src="/images/application04.jpg" />
                <figcaption>
                  カルチャースクール<i>Culture School</i>
                </figcaption>
              </figure>
              <p>各種カルチャースクールの開催に</p>
            </li>
            <li>
              <figure>
                <img src="/images/application05.jpg" />
                <figcaption>
                  撮影会<i>Photograph</i>
                </figcaption>
              </figure>
              <p>モデルの撮影会や各種商品の撮影に</p>
            </li>
          </ul>
        </section>
      </article>
      <article id="regular">
        <div>
          <header>
            <h2>定期利用</h2>
            <i>Regular use</i>
          </header>
          <section>
            <p>
              Sharealでは定期利用を受け付けております。定期利用申込いただくことで、毎週または毎月、決まった時間を最優先で確保させていただきます。カルチャースクールや定期的な会議・会合に最適です。
            </p>
            <div className="btn">
              <Link to={routes.regularInquiry.path}>
                定期利用申し込みはこちら
              </Link>
            </div>
          </section>
        </div>
      </article>
      <article id="link">
        <div>
          <header>
            <h2>予約システムご紹介</h2>
            <i>RESERABLE</i>
          </header>          
          <div className="link--image">
            <a href="https://www.kawakami-bldg.co.jp/reserable.html" target="_blank">
              <figure>
                {xssmMatches ? (
                  <img src="/images/reserable_banner_sp.jpg" />
                ) : (
                  <img src="/images/reserable_banner_pc.jpg" />
                )}
              </figure>  
            </a>
          </div>
        </div>
      </article>
      <article id="news">
        <header>
          <h2>お知らせ</h2>
          <i>News</i>
        </header>
        <section className="narrow">
          <ul>
            {Object.keys(props.newsList).map(key => {
              return (
                props.newsList[key].id && (
                  <li>
                    <i>
                      {moment(props.newsList[key].publish_date).format(
                        "YYYY-MM-DD"
                      )}
                    </i>
                    <div>
                      <Link
                        to={routes.news.getPath(
                          props.newsList[key].id.toString()
                        )}
                      >
                        {props.newsList[key].title}
                      </Link>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </section>
      </article>
      <picture><img src="/images/application05.jpg" /></picture>
    </BasePageContainer>
  );
};

export default TopPage;

import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { routes } from '../../routes/Route';
import LoadingComponent from '../LoadingComponent';
import { InternalServerErrorPage } from '../../pages/error/InternalServerErrorPage';
import { InternalServerError, AuthenticationError } from 'reducers/errorReducer';
import InternalServerErrorContainer from 'containers/InternalServerErrorContainer';

export type AuthRouteProps = {
  hasAuthenticate: boolean;  
  error?: string;
} & RouteProps;

const AuthRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  hasAuthenticate,  
  error,
  ...rest
}) => {
  if (!Component) {
    throw new Error('component attribute required for AuthRoute component');
  }

  if (error === InternalServerError) {
    return <InternalServerErrorContainer />;
  } else if(error === AuthenticationError) {
    return <Redirect to={routes.signIn.path} />
  }

  return (
    <Route
      {...rest}
      render={props =>
        hasAuthenticate ? (
          <Component {...props} />
        ) : (
          <Redirect to={routes.signIn.path} />
        )
      }
    />
  );
};

export { AuthRoute };

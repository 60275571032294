import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import SpaceBottomNav, { SpaceBottomNavProps } from '../components/Space/SpaceBottomNav'

interface OwnProps {
}

export type SpaceBottomNavContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function SpaceBottomNavContainer(props: SpaceBottomNavContainerProps) {
  const state = useSelector(appStateSelector)
  
  const _props: SpaceBottomNavProps = {
    invoice: state.reservationSpace.payload.invoice
  }

  return <SpaceBottomNav {..._props} />

}
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import ForgotPasswordPage, { ForgotPasswordPageProps } from '../pages/ForgotPasswordPage'
import { observeShownAction } from 'actions/pagingAction';

interface OwnProps {
}

export type ForgotPasswordPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state


export default function ForgotPasswordPageContainer(props: ForgotPasswordPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);


  return <ForgotPasswordPage />

}
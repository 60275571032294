import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';

export const SHOULD_SHOW_RESTRICT_DIALOG = "SHOULD_SHOW_RESTRICT_DIALOG"
export const DISABLE_RESTRICT_DIALOG = "DISABLE_RESTRICT_DIALOG"
export const SHOW_RESTRICT_DIALOG = "SHOW_RESTRICT_DIALOG"
export const CLOSE_RESTRICT_DIALOG = "CLOSE_RESTRICT_DIALOG"

/* 一般問い合わせアクション */
export type ShowRestrictDialogActions =
  | ShowRestrictDialog
  | DisableRestrictDialog
  | ErrorActions

type ShowRestrictDialogThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ShowRestrictDialogActions
>


export interface ShouldShowRestrictDialog extends Action<typeof SHOULD_SHOW_RESTRICT_DIALOG> {
}

export interface DisableRestrictDialog extends Action<typeof DISABLE_RESTRICT_DIALOG> {  
}

export interface ShowRestrictDialog extends Action<typeof SHOW_RESTRICT_DIALOG> {
}

export interface CloseRestrictDialog extends Action<typeof CLOSE_RESTRICT_DIALOG> {  
}

/* ブラウザ非対応ダイアログ表示可能状態に変更する */
export function shouldShowRestrictDialogAction(): ShouldShowRestrictDialog {
  return { type: SHOULD_SHOW_RESTRICT_DIALOG };
}

/* ブラウザ非対応ダイアログを表示する */
export function showRestrictDialogAction(): ShowRestrictDialog {
  return { type: SHOW_RESTRICT_DIALOG };
}

export function disableRestrictDialogAction(): DisableRestrictDialog {
  return { type: DISABLE_RESTRICT_DIALOG };
}

export function closeRestrictDialogAction(): CloseRestrictDialog {
  return { type: CLOSE_RESTRICT_DIALOG };
}

/* ブラウザ非対応ダイアログを表示する */
export function showRestrictDialog(): ShowRestrictDialogThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ShowRestrictDialogActions>,
    getState: () => AppState) => {
    
      dispatch(
        disableRestrictDialogAction()
      )

      dispatch(
        showRestrictDialogAction()
      )
    
  }
}


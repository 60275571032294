import * as React from 'react';
import useReactRouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import ReservationStepperContainer from '../containers/ReservationStepperContainer'
import CustomerInfoFormContainer from '../containers/CustomerInfoFormContainer'
import SpaceBottomNavContainer from '../containers/SpaceBottomNavContainer'

import { Invoice, Period } from '../dataObjects/space'
import { clearFields } from 'redux-form';
import { isIE } from 'react-device-detect';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
  })
);

interface OwnProps {
  space_id: number,
  space_name: string,
  timerange: string,
  selectedPeriods: Period[],
  users_adult: number | string,
  users_children: number | string,
  usage: string,
  detail: string,
  invoice: Invoice,
  onBack: () => void
}

export type SpaceCustomerInfoPageProps = OwnProps;

const SpaceCustomerInfoPage: React.FC<SpaceCustomerInfoPageProps> = (props: SpaceCustomerInfoPageProps) => {
  const classes = useStyles({} as StyleProps);
  const steperProps = { activeStep: 1 }
  const { space_id, space_name, timerange, selectedPeriods, users_adult, users_children, usage, detail, invoice, onBack } = props

  const periods = selectedPeriods.filter((v) => {
    return v.reserved === '2';      
  }).map((value,idx,arr) => {    
    return value.startTimeFromatted + " ～ " + value.endTimeFromatted;    
  });

  const periodsRendere = () => {
    const items = []
    for(let i = 0; i < periods.length; i++) {
      items.push(<span>{periods[i]}<br /></span>)
    }
    return <table style={{width: '60%'}}>
      <tr>
        <th style={{marginRight: '12px', verticalAlign: 'top', textAlign: 'left'}}>{timerange}</th>
        <td style={{textAlign: 'right', lineHeight: '1.5em'}}>{items}</td>
      </tr>
      <tr>
        <td></td>
        <td style={{marginTop: '4px', textAlign: 'right'}}>計 {periods.length} コマ</td>
      </tr>
    </table>
  }

  if (isIE) {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />
            
            <Typography variant="h3" className={classes.subtitle}>
              お客様のブラウザでは表示できません。
            </Typography>            

          </div>                    
        </Container>
      </BasePageContainer>

    );
  } else {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />

            <Typography variant="h2" className={classes.title}>
              [お客様情報入力] {space_name}
            </Typography>

            <ReservationStepperContainer {...steperProps} />

            {/* 予約スケジュール表示 */}
            <div className={classes.reserveInfoContainer}>
              <dl>
                <dt>利用スペース</dt>
                <dd>{space_name}</dd>
              </dl>
              <dl>
                <dt>利用時間</dt>
                <dd>
                  {periodsRendere()}
                </dd>
              </dl>
              <dl>
                <dt>利用人数</dt>
                <dd>大人：{users_adult}人&nbsp;&nbsp;子供：{users_children}人</dd>
              </dl>
              <dl>
                <dt>利用目的</dt>
                <dd>{usage}</dd>
              </dl>
              <dl>
                <dt>連絡事項</dt>
                <dd>{detail}</dd>
              </dl>
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              ご利用になるお客様の情報をご記入ください
            </Typography>

            <CustomerInfoFormContainer {...{ space_id: space_id } } />

          </div>
          
          <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '200px'}}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={onBack}>
                戻る
              </Button>
            </Grid>
          </Grid>  

          <SpaceBottomNavContainer />
        </Container>
      </BasePageContainer>

    );
  }

}

export default SpaceCustomerInfoPage
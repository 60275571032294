import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import { routes } from '../../routes/Route';
import { Button, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      marginTop: theme.spacing(2.5),
      marginLeft: theme.spacing(3),
      fontSize: '1.2rem',
      fontFamily: '"Noto Sans JP", "游ゴシック体", YuGothic, "Yu Gothic Medium", sans-serif',
      fontWeight: 400,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.95rem',
        marginTop: theme.spacing(1.7),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '.75rem',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
      },
      marginBlockEnd: '0.67em',
      display: 'block'
    },

  })
)

interface Props {
  hasLogin: boolean,
  isNavMenuOpen: boolean,
  username: string,
  changeMenuView: () => void,
  logout: () => void
}



export const Header: React.FC<Props> = (props) => {
  const theme = useTheme();
  const classes = useStyles({})
  const smMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const xssmMatches = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  // メニューボタン切り替え
  const changeMenuView = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.changeMenuView) {
      props.changeMenuView()
    }
  }

  const logout = () => {
    if (props.logout) {
      props.logout()
    }
  }

  const navMenuClass = props.isNavMenuOpen ? 'open' : ''

  return (
    <MuiThemeProvider theme={theme}>
      <header id="header" className={navMenuClass}>
        <div id="logo">
          <div>
            <Link to={routes.top.path}><img src="/images/logo.svg" alt="Shareal" /></Link>
          </div>
          <div>
            <Typography component="span" variant="h1" className={classes.subtitle}>
              {xssmMatches ? (
                <>
                  【シェアール】
                  <br />
                  あなたの生活を彩る
                  <br />
                  レンタルスペース
                </>

              ) : smMatches ? (
                <>
                  【シェアール】
                  <br />
                  あなたの生活を彩るレンタルスペース
                </>
              ) : (
                <>
                  【シェアール】あなたの生活を彩るレンタルスペース
                </>
              )}

            </Typography>
          </div>
        </div>
        <nav id="nav">
          <ul>
            <li><Link to={routes.space.getPath('1')}>今すぐ予約</Link></li>
            <li><Link to={routes.spaceRegularCalendar.getPath('1')}>イベントを探す</Link></li>
            <li><Link to={routes.usage.path}>ご利用方法</Link></li>
            <li><Link to={routes.operationPolicy.path}>運営ポリシー</Link></li>
            <li><Link to={routes.termsOfService.path}>利用規約</Link></li>
            <li><Link to={routes.sctl.path}>特定商取引法</Link></li>
            <li><Link to={routes.regularInquiry.path}>定期利用の申し込み</Link></li>
            <li><Link to={routes.inquiry.path}>お問い合わせ</Link></li>
            <li><Link to={routes.faq.path}>よくある質問</Link></li>
            {
              !props.hasLogin ? (
                <li><div id="login"><Link to={routes.signIn.path}>ログイン</Link></div></li>
              ) : (
                <>
                  <li>{props.username} 様専用メニュー</li>
                  <li><Link to={routes.userInfo.path}>ユーザー情報編集</Link></li>
                  <li><Link to={routes.userInquiry.path}>問い合わせ履歴</Link></li>
                  <li><Link to={routes.userReservationList.path}>予約履歴(キャンセル)</Link></li>
                  <li></li>
                  <li><div id="login" onClick={logout}><Link to="">ログアウト</Link></div></li>
                </>
              )
            }
          </ul>
        </nav>
        <div id="open" onClick={changeMenuView}><div><span></span><span></span><span></span></div>MENU</div>
      </header>
    </MuiThemeProvider>
  );
};

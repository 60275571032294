import { Action } from 'redux';
import { SnackBarAction } from './actionTypes';
import { RequestValidationResult } from 'errors/RequestValidationError';

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const SS_VALIDATION_ERROR = 'SS_VALIDATION_ERROR'
export const SS_VALIDATION_CLEAR = 'SS_VALIDATION_CLEAR'

export type ErrorActions = NotFoundErrorAction | InternalServerErrorAction | AuthenticationErrorAction | ServerValidationErrorAction | ServerValidationClearAction;

export interface NotFoundErrorAction extends Action<typeof NOT_FOUND_ERROR> {
  message: string;
}

export function notFoundError(message: string): NotFoundErrorAction {
  return { type: NOT_FOUND_ERROR, message };
}

export interface InternalServerErrorAction
  extends Action<typeof INTERNAL_SERVER_ERROR> {
  message: string;
}

export function internalServerError(
  message: string,
): InternalServerErrorAction {
  return { type: INTERNAL_SERVER_ERROR, message };
}

export interface AuthenticationErrorAction
  extends Action<typeof AUTHENTICATION_ERROR> {
    message: string;
}

export function authenticationError(
  message: string
): AuthenticationErrorAction {
  return {type: AUTHENTICATION_ERROR, message};
}

/* SS 422 Request Validationエラー発生 Action */
export interface ServerValidationErrorAction
  extends SnackBarAction<typeof SS_VALIDATION_ERROR> {
  payload: RequestValidationResult
}

export function serverValidationError (
  message: string,
  payload: RequestValidationResult
  ) : ServerValidationErrorAction {
  return {
    type: SS_VALIDATION_ERROR,
    snackBarMessage: message,
    variant: 'error',
    payload
  }
}

export interface ServerValidationClearAction
  extends Action<typeof SS_VALIDATION_CLEAR> {
}

export function serverValidationClear (  
  ) : ServerValidationClearAction {
  return {
    type: SS_VALIDATION_CLEAR
  }
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../../types/dispatchTypes'
import { emphasize, withStyles, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { routes } from '../../routes/Route'
import { push } from "connected-react-router"
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


interface StyleProps {
  root: BaseCSSProperties,
  avatar: BaseCSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    avatar: {
      background: 'none',
      marginRight: -theme.spacing(1.5),
    },
  }),
);

export default function CustomizedBreadcrumbs() {
  const classes = useStyles({} as StyleProps);

  const dispatch = useDispatch()


  const homeClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    dispatch(push(routes.top.path))
  }

  const spaceClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    dispatch(push(routes.space.getPath('1')))
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Home"
          icon={<HomeIcon fontSize="small" />}
          onClick={homeClick}
        />
        <StyledBreadcrumb
          label="スペース"          
          onClick={spaceClick}
        />
      </Breadcrumbs>
    </Paper>
  );
}
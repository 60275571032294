import * as React from 'react';
import { Theme, createStyles, Button, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';

import RegularInquiryFormContainer from '../containers/RegularInquiryFormContainer';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    }
  })
);

interface OwnProps {

}

export type RegularInquiryPageProps = OwnProps;

const RegularInquiryPage: React.FC<RegularInquiryPageProps> = (props: RegularInquiryPageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

      <Typography variant="h3" className={classes.subtitle}>
        定期利用のお問い合わせ
      </Typography>

      <Typography variant="body1" className={classes.description}>
        受付時間：月曜日〜金曜日 9時〜18時 (土日・祝・年末年始は翌営業日での確認となります)<br />
        お名前、返信用メールアドレス、定期利用内容をご記入し送信ボタンを押下してください。<br />
        折り返し、担当者より定期利用のお手続きについての説明をさせていただきます。
      </Typography>
      
      <RegularInquiryFormContainer />

      </Container>
    </BasePageContainer>

  );

}

export default RegularInquiryPage
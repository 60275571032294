import { ActionTypes } from '../actions/actionTypes'
import {
  MENU_VISIBLE_INITIALIZE,
  MENU_VISIBLE_CHANGE
} from '../actions/navAction'


export interface NavMenuVisibleState {
  isOpen: boolean
}

const initialNavMenuVisibleState: NavMenuVisibleState = {
  isOpen: false
};

/* navMenuVisible Reducer */
const navMenuVisible = (
  state: NavMenuVisibleState = initialNavMenuVisibleState,
  action: ActionTypes,
): NavMenuVisibleState => {
  switch (action.type) {
    case MENU_VISIBLE_INITIALIZE: 
      return { ...state, isOpen: false };
    case MENU_VISIBLE_CHANGE:
      return { ...state, isOpen: !state.isOpen };   
    default:
      return { ...state };
  }
};

export default navMenuVisible
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/authenticationActions';
import { ActionTypes } from '../actions/actionTypes';
import { AuthenticatedUser } from 'dataObjects/auth';


export interface AuthenticatedUserState extends AuthenticatedUser {
  hasAuthenticate: boolean,
}

const initialAuthenticatedUserState: AuthenticatedUserState = {
  hasAuthenticate: false,
  grantType: '',
  id: 0,
  email: '',
  role: '',
  access_token: '',
  expires_in: 0,
  name: ''
};

const authStatus = (
  state: AuthenticatedUserState = initialAuthenticatedUserState,
  action: ActionTypes,
): AuthenticatedUserState => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        hasAuthenticate: true,
        ...action.payload,
      };
    }
    case LOGOUT_SUCCESS: {
      return {...state, ...initialAuthenticatedUserState}
    }
    
    default:
      return state;
  }
};

export default authStatus;

import moment from 'moment'
import { isArray } from 'util';

export const required = (value: any): boolean => (value ? true : false);

export const requiredArray = (values: [], fields: string[]): any[] => {
  const membersArrayErrors = []
  if (values && isArray(values)) {
    values.forEach((member, memberIndex) => {
      const memberErrors: { [key: string]: string } = {};
      fields.forEach(field => {
        if (!required(member[field])) {
          memberErrors[field] = "必須項目です";
        }
      });
      if (Object.keys(memberErrors).length > 0) {
        membersArrayErrors[memberIndex] = memberErrors;
      }
    })
  }
  return membersArrayErrors
}

export const isEmail = (value: string): boolean => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? true : false);

export const maxLength = (value: string, max: number) => (value.length > max ? false : true);

export const number = (value, previousValue, allValues) => {
  if(!value) {
    return value
  }

  return value && isFinite(value) ? value : previousValue
}

export const digit = (value, previousValue, allValues) => {
  if(!value) {
    return value
  }

  return value && value.search(new RegExp('/^([1-9]\d*|0)(\.\d+)?$/')) ? value : previousValue
}

export const datetime = (value, previousValue, allValues)  => value && moment(value).isValid() ? value : previousValue

export const upper = value => value && value.toUpperCase()

export const lower = value => value && value.toLowerCase()

export const lessThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) < parseFloat(allValues[otherField]) ? value : previousValue

export const greaterThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) > parseFloat(allValues[otherField]) ? value : previousValue

export const lessThanDateTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment(value)
  const otherDate = moment(allValues[otherField])
  if(m1.isValid()) {
    return m1.isBefore(otherDate) ? value : previousValue
  } else {
    return previousValue
  }
}

export const greaterThanDateTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment(value)
  const otherDate = moment(allValues[otherField])
  if(m1.isValid()) {
    return m1.isAfter(otherDate) ? value : previousValue
  } else {
    return previousValue
  }
}

export const lessThanTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + allValues[otherField])
  if(m1.isValid()) {
    return m1.isBefore(otherDate, 'minute') ? value : previousValue
  } else {
    return previousValue
  }
}

export const greaterThanTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + allValues[otherField])
  if(m1.isValid()) {
    return m1.isAfter(otherDate, 'minute') ? value : previousValue
  } else {
    return previousValue
  }
}
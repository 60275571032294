import { ActionTypes } from '../actions/actionTypes'
import { SpaceReservation } from '../dataObjects/space';
import {
  FETCH_MY_RESERVATION_START,
  FETCH_MY_RESERVATION_SUCCESS,
  FETCH_MY_RESERVATION_FAIL
} from '../actions/userAction'
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';


export interface UserSpaceReservationState extends SpaceReservation {

}

const initialUserSpaceReservationState: UserSpaceReservationState = {
  reserve_id: undefined,
  space_id: undefined,
  space_name: '',
  status: '0',
  beforeStatus: '0',
  selectedDate: undefined,
  selectedPeriods: [],
  reserveTimeRange: {
    start_time: '',
    end_time: ''
  },
  enquete: {
    users_adult: 0,
    users_children: 0,
    usage: undefined,
    detail: '',
    terms_of_service: false
  },
  invoice: {
    invoice_id: 0,
    reserve_id: 0,
    amount: 0,
    details: [],
    discount: 0
  },
  contractor: {
    reserve_id: 0,
    family_name: '',
    family_name_kana: '',
    given_name: '',
    given_name_kana: '',
    postal_code: '',
    address_pref: '',
    address_city: '',
    address_street: '',
    address_room: '',
    email: '',
    email_confirmation: '',
    tel: '',
    promotion_code: '',
    company_name: ''
  },
  payment: {
    
  }
}


/* userReservationList Reducer */
const userReservation = (
  state: UserSpaceReservationState = initialUserSpaceReservationState,
  action: ActionTypes,
): UserSpaceReservationState => {
  switch (action.type) {
    // ユーザー問い合わせ読み込み
    case FETCH_MY_RESERVATION_START: 
      return { ...state }
    case FETCH_MY_RESERVATION_SUCCESS:
      console.log(action.payload)
      return { ...state, ...action.payload };
    case FETCH_MY_RESERVATION_FAIL:
      return { ...state }   
    case LOGOUT_SUCCESS:
        return { ...state, ...initialUserSpaceReservationState}
    default:
      return { ...state };
  }
};

export default userReservation

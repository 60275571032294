import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import { renderTextField, renderRadio, renderFormHelper } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'
    },    
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    }, 
    clearButton: {
      color: '#000000',
      backgroundColor: theme.palette.secondary.main,
      height: '50px',
      marginBottom: '50px'
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)

export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'name',
    'email',
    'email_confirmation',
    'content'
  ]
  requiredFields.forEach(field => {
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })

  if(values['email'] !== values['email_confirmation']){
    errors['email_confirmation'] = '一つ目のメールアドレスと一致しません'
  }

  return errors
}


const Form: React.FC<InjectedFormProps> = ({ handleSubmit, pristine, submitting, reset }) => {
  const classes = useStyles({})

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <dl>
          <dt>氏名</dt>
          <dd>
            <div>
              <Field
                name="name"
                component={renderTextField}
                label="氏名"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
        <dl style={{ height: '230px'}}>
          <dt>メールアドレス</dt>
          <dd>
            <div>
              <Field
                name="email"
                autocomplete="email"
                component={renderTextField}
                label="メールアドレス"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
              <Field
                name="email_confirmation"
                autocomplete="email_confirmation"
                component={renderTextField}
                label="メールアドレス(確認)"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
        <dl style={{height: '400px'}}>
          <dt>お問い合わせ内容</dt>
          <dd>
            <div>
              <Field
                name="content"
                component={renderTextField}
                label="お問い合わせ内容"
                multiline
                rows="10"
                rowsMax="10"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField80}
              />
            </div>
          </dd>
        </dl>
      </div>

      <Grid container spacing={2} alignItems="center" justify="center" style={{marginBottom: '200px'}}>
        <Grid item xs={6}>
          <Button type="submit" disabled={pristine || submitting} fullWidth className={classes.submitButton}>
            送信
          </Button>
        </Grid>
      </Grid>

    </form>    
  )
}

const InquriyForm = reduxForm({
  form: 'InquriyForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)

export default InquriyForm
import React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

interface StypeProps {
  root:  BaseCSSProperties, 
  table: BaseCSSProperties,  
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '$FFF',
      color: '#222',
    },
    body: {
      fontSize: '1.5rem',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      
    },
  }),
)(TableRow);

/* 
  広さ 
  収容人数 
  住所
  周辺情報 */
const createData = (area: string, capacity: string, address: string, info: string) => {
    return { area, capacity, address, info };
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
  }),
);


interface OwnProps {
  area: string,
  capacity: string,
  address: string,
  around_info: string
}

type CustomizeTablesProps = OwnProps

export default function CustomizedTables(props: CustomizeTablesProps) {
    const classes = useStyles({});
  
    const rows = [
      createData('50㎡', '40人', '神戸市北区鈴蘭台', '周辺にコンビニ、パーキング多数あり')
    ];

    return (
      
      <table className={classes.table} aria-label="customized table">
        <thead>
          <tr>
            <th>広さ</th>
            <th>収容人数</th>
            <th>住所</th>
            <th>周辺情報</th>
          </tr>
        </thead>
        <tbody>
          <tr key={props.area}>
            <td>{props.area}</td>
            <td>{props.capacity}</td>
            <td>{props.address}</td>
            <td>{props.around_info}</td>
          </tr>
        </tbody>
      </table>
      
    );
  }
import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { routes } from '../routes/Route';
import moment from 'moment'
import { push, CallHistoryMethodAction } from "connected-react-router"
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { 
  Period,
  SpaceReservation, 
  SpaceReservationResult, 
  Invoice,
  InvoiceDetail,
  Payment,
  ReservationValidationError,  
  ReservationProcessStatus,
  PaymentResult  
} from '../dataObjects/space'
import { SnackBarAction } from './actionTypes'
import { any } from 'prop-types';
import { VerificationError } from 'types/squareApiTypes';
import { AxiosResponse } from 'axios';
import { ValidationError422, Normal } from 'types/appApiTypes';
import { Errors } from 'types/formTypes';
import { ReservationValidationErrorListState } from 'reducers/validationErrorReducer';
import { dateToString } from 'utils/convertor'
import { SetupIntent } from '@stripe/stripe-js';

/* コマ予約アクションType */
export const RESERVATION_PERIOD_START = 'RESERVATION_PERIOD_START'
export const RESERVATION_PERIOD_SUCCESS = 'RESEVATION_PERIOD_SUCCESS'
export const RESERVATION_PERIOD_INVALID = 'RESEVATION_PERIOD_INVALID'
export const RESERVATION_PERIOD_FAIL = 'RESEVATION_PERIOD_FAIL'

/* 契約者情報登録アクションType */
export const RESERVATION_CONTRACTOR_START = 'RESERVATION_CONTRACTOR_START'
export const RESERVATION_CONTRACTOR_SUCCESS = 'RESERVATION_CONTRACTOR_SUCCESS'
export const RESERVATION_CONTRACTOR_INVALID = 'RESERVATION_CONTRACTOR_INVALID'
export const RESERVATION_CONTRACTOR_FAIL = 'RESERVATION_CONTRACTOR_FAIL'

/* 請求情報作成アクションType */
export const RESERVATION_CREATEINVOICE_START = 'RESERVATION_CREATEINVOICE_START'
export const RESERVATION_CREATEINVOICE_SUCCESS = 'RESERVATION_CREATEINVOICE_SUCCESS'
export const RESERVATION_CREATEINVOICE_FAIL = 'RESERVATION_CREATEINVOICE_FAIL'

/* Stripe 決済開始アクションType */
export const RESERVATION_BEGIN_PAYMENT_START = 'RESERVATION_BEGIN_PAYMENT_START'
export const RESERVATION_BEGIN_PAYMENT_SUCCESS = 'RESEVATION_BEGIN_PAYMENT_SUCCESS'
export const RESERVATION_BEGIN_PAYMENT_INVALID = 'RESEVATION_BEGIN_PAYMENT_INVALID'
export const RESERVATION_BEGIN_PAYMENT_FAIL = 'RESEVATION_BEGIN_PAYMENT_FAIL'

/* Stripe 決済アクションType */
export const RESERVATION_PAYMENT_START = 'RESERVATION_PAYMENT_START'
export const RESERVATION_PAYMENT_SUCCESS = 'RESEVATION_PAYMENT_SUCCESS'
export const RESERVATION_PAYMENT_INVALID = 'RESEVATION_PAYMENT_INVALID'
export const RESERVATION_PAYMENT_FAIL = 'RESEVATION_PAYMENT_FAIL'

/* 予約キャンセルアクションType */
export const RESERVATION_CANCEL_START = 'RESERVATION_CANCEL_START'
export const RESERVATION_CANCEL_SUCCESS = 'RESERVATION_CANCEL_SUCCESS'
export const RESERVATION_CANCEL_INVALID = 'RESERVATION_CANCEL_INVALID'
export const RESERVATION_CANCEL_FAIL = 'RESERVATION_CANCEL_FAIL'


/* 予約Stateリセット */
export const RESERVATION_STATE_RESET = 'RESERVATION_STATE_RESET'


/**
 * 予約 取得アクション
 */
export const FETCH_RESERVATION_START = 'FETCH_RESERVATION_START';
export const FETCH_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
export const FETCH_RESERVATION_FAIL = 'FETCH_RESERVATION_FAIL';

export type FetchReservationActions =
  | FetchReservationStartAction
  | FetchReservationSuccessAction
  | FetchReservationFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchReservationThunkResult<R> = ThunkAction<R, AppState, undefined, FetchReservationActions>;



/* コマ予約処理アクション */
export type ReservationPeriodActions = 
  | ReservationPeriodStartAction
  | ReservationPeriodSuccessAction
  | ReservationPeriodInvalidAction
  | ReservationPeriodFailAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationPeriodThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationPeriodActions
>

/* 契約者情報登録アクション */
export type ReservationContractorActions = 
  | ReservationContractorStartAction
  | ReservationContractorSuccessAction
  | ReservationContractorInvalidAction
  | ReservationContractorFailAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationContractorThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationContractorActions
>

/* 請求情報作成アクション */
export type ReservationCreateInvoiceActions =
  | ReservationCreateInvoiceStartAction
  | ReservationCreateInvoiceSuccessAction
  | ReservationCreateInvoiceFailAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationCreateInvoiceThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationCreateInvoiceActions
>

/* Stripe 決済開始アクション */
export type ReservationBeginPaymentActions = 
  | ReservationBeginPaymentStartAction
  | ReservationBeginPaymentSuccessAction
  | ReservationBeginPaymentFailAction
  | ReservationBeginPaymentInvalidAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationBeginPaymentThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationBeginPaymentActions
>

/* Stripe 決済処理アクション */
export type ReservationPaymentActions = 
  | ReservationPaymentStartAction
  | ReservationPaymentSuccessAction
  | ReservationPaymentFailAction
  | ReservationPaymentInvalidAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationPaymentThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationPaymentActions
>


/* キャンセル処理 アクション */
export type ReservationCancelActions = 
  | ReservationCancelStartAction
  | ReservationCancelSuccessAction
  | ReservationCancelInvalidAction
  | ReservationCancelFailAction
  | ErrorActions
  | CallHistoryMethodAction

type ReservationCancelThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  ReservationCancelActions
  >


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchReservationStartAction
  extends Action<typeof FETCH_RESERVATION_START> { }

const fetchReservationStartAction = (): FetchReservationStartAction => ({
  type: 'FETCH_RESERVATION_START',
});

export interface FetchReservationSuccessAction
  extends Action<typeof FETCH_RESERVATION_SUCCESS> {
  payload: SpaceReservation
}

const fetchReservationSuccessAction = (
  headerList: SpaceReservation,
): FetchReservationSuccessAction => ({
  type: 'FETCH_RESERVATION_SUCCESS',
  payload: headerList
})

export interface FetchReservationFailAction
  extends SnackBarAction<typeof FETCH_RESERVATION_FAIL> {

}

const fetchReservationFailAction = (message: string): FetchReservationFailAction => ({
  type: 'FETCH_RESERVATION_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* 予約 取得 ThunkAction */
export function fetchReservation(reserve_id: number, callback): FetchReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchReservationStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/dropin/reservation/${reserve_id}`
      const response = await AppClient.get(url, header)
      const body: SpaceReservation = await response.data;
      dispatch(
        fetchReservationSuccessAction(body)
      )

      if(callback != null) {
        dispatch(callback())
      }
    } catch (err) {
      dispatch<FetchReservationActions>(
        await ajaxErrorHandler(err, fetchReservationFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/* 予約Stateリセット Action */
export interface ReservationSteteResetAction
  extends Action<typeof RESERVATION_STATE_RESET> { }

export const reservationSteteResetAction = (): ReservationSteteResetAction => {
  return {
    type: RESERVATION_STATE_RESET,
  }
}

/* ---------------------------------------------------------------------------------------------------- */

/* コマ予約処理START Action */
export interface ReservationPeriodStartAction
  extends Action<typeof RESERVATION_PERIOD_START> { }

const reservationPeriodStartAction = (): ReservationPeriodStartAction => {
  return {
    type: RESERVATION_PERIOD_START,
  }
}

/* コマ予約処理成功 Action */
export interface ReservationPeriodSuccessAction
  extends Action<typeof RESERVATION_PERIOD_SUCCESS> {
  payload: SpaceReservationResult
}

const reservationPeriodSuccessAction = (payload: SpaceReservationResult)
  : ReservationPeriodSuccessAction => {
  return {
    type: RESERVATION_PERIOD_SUCCESS,
    payload,
  };
};

/* コマ予約処理失敗 Action */
export interface ReservationPeriodFailAction
  extends SnackBarAction<typeof RESERVATION_PERIOD_FAIL>  {}

const reservationPeriodFailAction = (
  message: string
): ReservationPeriodFailAction => {
  return {
    type: RESERVATION_PERIOD_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* コマ予約処理Validationエラー発生 Action */
export interface ReservationPeriodInvalidAction
  extends Action<typeof RESERVATION_PERIOD_INVALID> {
  payload: ReservationValidationErrorListState
}

const reservationPeriodInvalidAction = (payload: ReservationValidationErrorListState)
  : ReservationPeriodInvalidAction => {
  return {
    type: RESERVATION_PERIOD_INVALID,
    payload
  }
}

/* ---------------------------------------------------------------------------------------------------- */

/* 契約者情報登録START Action */
export interface ReservationContractorStartAction
  extends Action<typeof RESERVATION_CONTRACTOR_START> { }

const reservationContratorStartAction = (): ReservationContractorStartAction => {
  return {
    type: RESERVATION_CONTRACTOR_START,
  }
}

/* 契約者情報登録成功 Action */
export interface ReservationContractorSuccessAction
  extends Action<typeof RESERVATION_CONTRACTOR_SUCCESS> {
  payload: SpaceReservationResult
}

const reservationContractorSuccessAction = (payload: SpaceReservationResult)
  : ReservationContractorSuccessAction => {
  return {
    type: RESERVATION_CONTRACTOR_SUCCESS,
    payload,
  };
};

/* 契約者情報登録失敗 Action */
export interface ReservationContractorFailAction
  extends SnackBarAction<typeof RESERVATION_CONTRACTOR_FAIL> {  
}

const reservationContractorFailAction = (
  message: string
): ReservationContractorFailAction => {
  return {
    type: RESERVATION_CONTRACTOR_FAIL,
    snackBarMessage: message,
    variant: 'error'
  };
};


/* 契約者情報登録Validationエラー発生 Action */
export interface ReservationContractorInvalidAction
  extends Action<typeof RESERVATION_CONTRACTOR_INVALID> {
  payload: ReservationValidationErrorListState
}

const reservationContractorInvalidAction = (
  payload: ReservationValidationErrorListState
  ) : ReservationContractorInvalidAction => {
  return {
    type: RESERVATION_CONTRACTOR_INVALID,
    payload
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/* 請求情報作成 START Action */
export interface ReservationCreateInvoiceStartAction
  extends Action<typeof RESERVATION_CREATEINVOICE_START> { }

const reservationCreateInvoiceStartAction = (): ReservationCreateInvoiceStartAction => {
  return {
    type: RESERVATION_CREATEINVOICE_START,
  }
}

/* 請求情報作成 成功Action */
export interface ReservationCreateInvoiceSuccessAction
  extends Action<typeof RESERVATION_CREATEINVOICE_SUCCESS> {
  payload: Invoice
}

const reservationCreateInvoiceSuccessAction = (payload: Invoice)
  : ReservationCreateInvoiceSuccessAction => {
  return {
    type: RESERVATION_CREATEINVOICE_SUCCESS,
    payload
  }
}

/* 請求情報作成失敗 Action */
export interface ReservationCreateInvoiceFailAction
  extends SnackBarAction<typeof RESERVATION_CREATEINVOICE_FAIL> {
  
}

const reservationCreateInvoiceFailAction = (
  message: string
): ReservationCreateInvoiceFailAction => {
  return {
    type: RESERVATION_CREATEINVOICE_FAIL,
    snackBarMessage: message,
    variant: 'error'
  };
};


/* ---------------------------------------------------------------------------------------------------- */

/* Stripe 決済SetupIntent作成処理START Action */
export interface ReservationBeginPaymentStartAction
  extends Action<typeof RESERVATION_BEGIN_PAYMENT_START> { 
    payload: Payment
  }

const reservationBeginPaymentStartAction = (payload: Payment): ReservationBeginPaymentStartAction => {
  return {
    type: RESERVATION_BEGIN_PAYMENT_START,
    payload
  }
}

/* Stripe 決済SetupIntent作成処理成功 Action */
export interface ReservationBeginPaymentSuccessAction
  extends Action<typeof RESERVATION_BEGIN_PAYMENT_SUCCESS> {
  payload: PaymentResult
}

const reservationBeginPaymentSuccessAction = (payload: PaymentResult)
  : ReservationBeginPaymentSuccessAction => {
  return {
    type: RESERVATION_BEGIN_PAYMENT_SUCCESS,
    payload,
  };
};

/* Stripe 決済SetupIntent作成処理失敗 Action */
export interface ReservationBeginPaymentFailAction
  extends SnackBarAction<typeof RESERVATION_BEGIN_PAYMENT_FAIL>  {

}

const reservationBeginPaymentFailAction = (
  message: string
): ReservationBeginPaymentFailAction => {
  return {
    type: RESERVATION_BEGIN_PAYMENT_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* Stripe 決済SetupIntent作成処理Validationエラー発生 Action */
export interface ReservationBeginPaymentInvalidAction
  extends Action<typeof RESERVATION_BEGIN_PAYMENT_INVALID> {
  payload: ReservationValidationErrorListState
}

const reservationBeginPaymentInvalidAction = (
  payload: ReservationValidationErrorListState
)
  : ReservationBeginPaymentInvalidAction => {
  return {
    type: RESERVATION_BEGIN_PAYMENT_INVALID,
    payload: payload
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/* Stripe 決済処理START Action */
export interface ReservationPaymentStartAction
  extends Action<typeof RESERVATION_PAYMENT_START> { 
  }

const reservationPaymentStartAction = (): ReservationPaymentStartAction => {
  return {
    type: RESERVATION_PAYMENT_START
  }
}

/* Stripe 決済処理成功 Action */
export interface ReservationPaymentSuccessAction
  extends Action<typeof RESERVATION_PAYMENT_SUCCESS> {
  payload: SpaceReservationResult
}

const reservationPaymentSuccessAction = (payload: SpaceReservationResult)
  : ReservationPaymentSuccessAction => {
  return {
    type: RESERVATION_PAYMENT_SUCCESS,
    payload,
  };
};

/* Stripe 決済処理失敗 Action */
export interface ReservationPaymentFailAction
  extends SnackBarAction<typeof RESERVATION_PAYMENT_FAIL>  {

}

const reservationPaymentFailAction = (
  message: string
): ReservationPaymentFailAction => {
  return {
    type: RESERVATION_PAYMENT_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* Stripe 決済処理Validationエラー発生 Action */
export interface ReservationPaymentInvalidAction
  extends Action<typeof RESERVATION_PAYMENT_INVALID> {
  payload: ReservationValidationErrorListState
}

const reservationPaymentInvalidAction = (
  payload: ReservationValidationErrorListState
)
  : ReservationPaymentInvalidAction => {
  return {
    type: RESERVATION_PAYMENT_INVALID,
    payload: payload
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/* 指定したスペースと日付とコマ情報で予約処理を実行する */
export function reservationPeriodAction(payload: SpaceReservation
  ): ReservationPeriodThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationPeriodActions>,
    getState: () => AppState) => {
    console.log('reservationPeriodAction START')

    dispatch(reservationPeriodStartAction());
    
    console.log({payload})
    try {
      const state = getState()
      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token
      const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/dropin/period`, header, payload);
      
      const result: SpaceReservationResult = await reservationResultRes.data;  
      if (result.validationResult && result.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(
          reservationPeriodInvalidAction({
            message: '時間枠予約処理が中断しました。',
            errors: result.validationResult
          })
        )
      } else {
        /* 予約成功 */
        dispatch(
          reservationPeriodSuccessAction({
            payload: result.payload,
            validationResult: result.validationResult
          })
        )
        /* 契約者情報入力画面へ遷移 */
        const nextViewPath = routes.spaceCustomerInfo.getPath(result.payload.space_id.toString())
        dispatch(push(nextViewPath))          
      }
      
    
    } catch (err) {
      dispatch<ReservationPeriodActions>(
        await ajaxErrorHandler(err, reservationPeriodFailAction)
      );
    }
  };
}


/* ---------------------------------------------------------------------------------------------------- */

/* 契約者情報登録を実行する */
export function reservationContractorAction(payload: SpaceReservation): ReservationContractorThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationContractorActions>,
    getState: () => AppState) => {
    dispatch(reservationContratorStartAction());
    
    try {
      const state = getState()
      const data = await reservationContractor(payload, state.authStatus.access_token);
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(reservationContractorInvalidAction({
          message: 'お客様情報の登録処理が中断しました。',
          errors: data.validationResult
        }))   

      } else {
        /* 契約者情報登録成功 */
        dispatch(
          reservationContractorSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          }),
        )
        /* 決済処理画面へ遷移 */
        const nextViewPath = routes.spacePayment.getPath(data.payload.space_id.toString())
        dispatch(push(nextViewPath))          
      }
    
    } catch (err) {
      dispatch<ReservationContractorActions>(
        await ajaxErrorHandler(err, reservationContractorFailAction),
      );
    }
  };
}

/* 契約者情報登録 */
async function reservationContractor(payload: SpaceReservation, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/dropin/contractor`, header, payload);

  const result: SpaceReservationResult = await reservationResultRes.data;
    
  return result;

}


/* ---------------------------------------------------------------------------------------------------- */

/* 請求情報作成を実行する */
export function reservationCreateInvoiceAction(payload: {
  periodPrices: Period[],
  discount: number
}): ReservationCreateInvoiceThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationCreateInvoiceActions>,
    getState: () => AppState) => {
    dispatch(reservationCreateInvoiceStartAction())
    try {
      // 請求金額計算
      const today = new Date()
      const state = getState()
      
      const reserve_id = state.reservationSpace.payload.reserve_id;
      const invoice_id = state.reservationSpace.payload.invoice.invoice_id;

      const details: InvoiceDetail[] = []
      const subtotalByItemTax = payload.periodPrices.reduce((result, current) => {
        const elm = result.find(p => {
          return p.item_id === current.item_id
            && p.tax_kind === current.tax_kind
        })
        if (elm) {
          // 品目、税区分毎に金額合計
          elm.price += current.price; 
        } else {
          result.push({
            item_id: current.item_id,
            tax_kind: current.tax_kind,
            price: current.price
          })
        }
        return result;
      }, [])
      const toString = Object.prototype.toString
      subtotalByItemTax.forEach(sub => {
        console.log({sub})
        const taxratetypes = state.master.taxratetypes
        console.log({taxratetypes})
        const tax = state.master.taxrates.find(tax => {
          const rate_type = state.master.taxratetypes.find(rateType => rateType.id === tax.tax_rate_type_id)
          const from = toString.call(tax.enable_from) == '[object Date]' ? tax.enable_from : dateToString(tax.enable_from).replace(/-/g,"/")
          const to = toString.call(tax.enable_to) == '[object Date]' ? tax.enable_to : dateToString(tax.enable_to).replace(/-/g,"/")
          return today >= (new Date(from)) && today <= (new Date(to)) && sub.tax_kind === rate_type.id
        })
        // 消費税計算して明細レコードとして登録
        console.log({tax})
        const rate = tax ? tax.rate : 0
        const t = Math.floor(sub.price * rate)
        details.push({
          id: 0,
          dropin_invoice_id: invoice_id,
          item_id: sub.item_id,
          subtotal: sub.price,
          tax: t,
          tax_rate: rate,
          amount: sub.price + t
        })
      })
      // 請求書合計 計算
      const amount = details.reduce((result, current) => result + current.subtotal + current.tax, 0)
        - payload.discount
      dispatch(
        reservationCreateInvoiceSuccessAction({
          invoice_id: invoice_id,
          reserve_id: reserve_id,
          details: details,
          discount: payload.discount,
          amount: amount
        }),
      )

    } catch (err) {
      dispatch<ReservationCreateInvoiceActions>(
        await ajaxErrorHandler(err, reservationCreateInvoiceFailAction),
      );
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/* 決済処理を開始する */
export function reservationBeginPaymentAction(): ReservationBeginPaymentThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationBeginPaymentActions>,
    getState: () => AppState) => {

    const payment: Payment = {          
    }
    dispatch(reservationBeginPaymentStartAction(payment))

    try {      
      // 決済処理開始
      const state = getState()

      let payload = null
      if(state.reservationSpace.payload.space_id) {
        payload = {
          space_id: state.reservationSpace.payload.space_id,
          reserve_id: state.reservationSpace.payload.reserve_id
        }        
      } else {
        payload = {
          space_id: state.userReservation.space_id,
          reserve_id: state.userReservation.reserve_id
        }        
      }

      const data = await startPayment(payload, state.authStatus.access_token)
      console.log(data)
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(reservationBeginPaymentInvalidAction({
          message: 'エラーが発生したため決済処理が行えません。',
          errors: data.validationResult
        }))   

      } else {
        dispatch(
          reservationBeginPaymentSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          }),
        )       
      }        
    } catch (err) {
      console.log(err)
      dispatch<ReservationBeginPaymentActions>(
        await ajaxErrorHandler(err, reservationBeginPaymentFailAction),
      );
    }
  }
}

/* 決済処理開始 */
async function startPayment(payload: any, access_token: string): Promise<PaymentResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/dropin/beginPayment`, header, payload);

  const result: PaymentResult = await reservationResultRes.data;
  
  return result;
}


/* ---------------------------------------------------------------------------------------------------- */

/* 決済処理をする */
export function reservationPaymentAction(stripeSetupIntent: SetupIntent): ReservationPaymentThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationPaymentActions>,
    getState: () => AppState) => {    

    try {
      const state = getState()
      let payment_id = state.reservationSpace.payload.payment ? state.reservationSpace.payload.payment.payment_id : 0
      if (payment_id === 0) {
        payment_id = state.payment.payload.payment_id
      }
      if (payment_id === 0) {
        payment_id = state.userReservation.payment.payment_id
      }

      dispatch(reservationPaymentStartAction())

      const data = await payment(payment_id, stripeSetupIntent.payment_method, state.authStatus.access_token)
      
      if ((data && data.payload && data.payload.status != "3")
          || (data.validationResult && data.validationResult.length > 0)
      ) {
        /* SSValidation失敗 */
        dispatch(reservationPaymentInvalidAction({
          message: 'エラーが発生したため決済処理が行えません。',
          errors: data.validationResult
        }))   

        dispatch(reservationBeginPaymentAction())

      } else {
        dispatch(
          reservationPaymentSuccessAction(data)
        )               
        // 予約完了画面に遷移
        const nextViewPath = routes.spacePaymentComplete.getPath()
        dispatch(push(nextViewPath))         
      }                   
      
    } catch (err) {
      dispatch<ReservationPaymentActions>(
        await ajaxErrorHandler(err, reservationPaymentFailAction),
      );

      dispatch(reservationBeginPaymentAction())
    }
  }
}

/* 決済処理を実行 */
async function payment(payment_id: number, payment_method: string, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/dropin/payment`, header, 
  { 
    payment_id: payment_id,
    payment_method: payment_method
  });

  let result: SpaceReservationResult = await reservationResultRes.data;
  try {
    if(typeof(result) == 'string') {
      result = JSON.parse(result)
    }
  } catch(exception) {
    console.log(exception)
  }
  
  return result;
}

/* ---------------------------------------------------------------------------------------------------- */

/* キャンセル 処理START Action */
export interface ReservationCancelStartAction
  extends Action<typeof RESERVATION_CANCEL_START> { 
  }

const reservationCancelStartAction = (): ReservationCancelStartAction => {
  return {
    type: RESERVATION_CANCEL_START,
  }
}

/* 処理状況監視 処理成功 Action */
export interface ReservationCancelSuccessAction
  extends Action<typeof RESERVATION_CANCEL_SUCCESS> {
  payload: SpaceReservationResult
}

const reservationCancelSuccessAction = (payload: SpaceReservationResult)
  : ReservationCancelSuccessAction => {
  return {
    type: RESERVATION_CANCEL_SUCCESS,
    payload,
  };
};

/* 処理状況監視 処理失敗 Action */
export interface ReservationCancelFailAction
  extends SnackBarAction<typeof RESERVATION_CANCEL_FAIL>  {}

const reservationCancelFailAction = (
  message: string
): ReservationCancelFailAction => {
  return {
    type: RESERVATION_CANCEL_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* 契約者情報登録Validationエラー発生 Action */
export interface ReservationCancelInvalidAction
  extends Action<typeof RESERVATION_CANCEL_INVALID> {
  payload: ReservationValidationErrorListState
}

const reservationCancelInvalidAction = (
  payload: ReservationValidationErrorListState
): ReservationCancelInvalidAction => {
  return {
    type: RESERVATION_CANCEL_INVALID,
    payload
  }
}

/* キャンセル処理を開始する */
export function reservationCancelAction(condition: SpaceReservation): ReservationCancelThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ReservationCancelActions>,
    getState: () => AppState) => {
    
    dispatch(reservationCancelStartAction())

    try {
      // nonce送信
      const state = getState()
      const data = await cancelReserve(condition, state.authStatus.access_token)
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(reservationCancelInvalidAction({
          message: '予約キャンセル処理が中断しました。',
          errors: data.validationResult
        }))

      } else {
        dispatch(
          reservationCancelSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
        // 予約ステートリセット
        reservationSteteResetAction()
        
      }
    } catch (err) {
      dispatch<ReservationCancelActions>(
        await ajaxErrorHandler(err, reservationCancelFailAction)
      );
    }
  }
}

/* 決済処理を実行 */
async function cancelReserve(conditon: SpaceReservation, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token

  const payload = {
    space_id: conditon.space_id,
    reserve_id: conditon.reserve_id
  }
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/dropin/cancel`, header, payload);

  const result: SpaceReservationResult = await reservationResultRes.data;
  
  return result;
}
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';

export const MENU_VISIBLE_INITIALIZE = 'MENU_VISIBLE_INITIALIZE'
export const MENU_VISIBLE_CHANGE = 'MENU_VISIBLE_CHANGE'

export type menuVisibleChangeActions =
  | MenuVisibleInitializeAction
  | MenuVisibleChangeAction
  | ErrorActions;


export interface MenuVisibleInitializeAction
  extends Action<typeof MENU_VISIBLE_INITIALIZE> { }

export interface MenuVisibleChangeAction
  extends Action<typeof MENU_VISIBLE_CHANGE> { }


export const menuVisibleInitializeAction = (): MenuVisibleInitializeAction => ({
  type: 'MENU_VISIBLE_INITIALIZE'
});

export const menuVisibleChangeAction = (): MenuVisibleChangeAction => ({
  type: 'MENU_VISIBLE_CHANGE'
})
import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { ErrorActions } from './errorAction';
import { UserData, UserAcceptInquiry } from '../dataObjects/user'
import { ReservationHeaderPagenation, SpaceReservation } from 'dataObjects/space';
import { routes } from '../routes/Route';
import { push, CallHistoryMethodAction } from "connected-react-router"
import { SnackBarAction } from './actionTypes';

/**
 * ユーザーデータ取得アクション
 */
export const FETCH_MY_USERDATA_START = 'FETCH_MY_USERDATA_START';
export const FETCH_MY_USERDATA_SUCCESS = 'FETCH_MY_USERDATA_SUCCESS';
export const FETCH_MY_USERDATA_FAIL = 'FETCH_MY_USERDATA_FAIL';

export type FetchMyUserDataActions =
  | FetchMyUserDataStartAction
  | FetchMyUserDataSuccessAction
  | FetchMyUserDataFailAction
  | ErrorActions;
type FetchMyUserDataThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMyUserDataActions>;


/**
 * ユーザーデータ保存アクション
 */
export const STORE_MY_USERDATA_START = 'STORE_MY_USERDATA_START';
export const STORE_MY_USERDATA_LOCAL = 'STORE_MY_USERDATA_LOCAL';
export const STORE_MY_USERDATA_SUCCESS = 'STORE_MY_USERDATA_SUCCESS';
export const STORE_MY_USERDATA_FAIL = 'STORE_MY_USERDATA_FAIL';

export type StoreMyUserDataActions =
  | StoreMyUserDataStartAction
  | StoreMyUserDataSuccessAction
  | StoreMyUserDataFailAction
  | StoreMyUserDataLocalAction
  | ErrorActions;
type StoreMyUserDataThunkResult<R> = ThunkAction<R, AppState, undefined, StoreMyUserDataActions>;


/**
 * ユーザー問い合わせ履歴取得アクション
 */
export const FETCH_MY_INQUIRY_START = 'FETCH_MY_INQUIRY_START';
export const FETCH_MY_INQUIRY_SUCCESS = 'FETCH_MY_INQUIRY_SUCCESS';
export const FETCH_MY_INQUIRY_FAIL = 'FETCH_MY_INQUIRY_FAIL';

export type FetchMyInquiryActions =
  | FetchMyInquiryStartAction
  | FetchMyInquirySuccessAction
  | FetchMyInquiryFailAction
  | ErrorActions;
type FetchMyInquiryThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMyInquiryActions>;


/**
 * ユーザー予約一覧取得アクション
 */
export const FETCH_MY_RESERVATION_LIST_START = 'FETCH_MY_RESERVATION_LIST_START';
export const FETCH_MY_RESERVATION_LIST_SUCCESS = 'FETCH_MY_RESERVATION_LIST_SUCCESS';
export const FETCH_MY_RESERVATION_LIST_FAIL = 'FETCH_MY_RESERVATION_LIST_FAIL';

export type FetchMyReservationListActions =
  | FetchMyReservationListStartAction
  | FetchMyReservationListSuccessAction
  | FetchMyReservationListFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchReservationListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMyReservationListActions>;


/**
 * ユーザー予約 取得アクション
 */
export const FETCH_MY_RESERVATION_START = 'FETCH_MY_RESERVATION_START';
export const FETCH_MY_RESERVATION_SUCCESS = 'FETCH_MY_RESERVATION_SUCCESS';
export const FETCH_MY_RESERVATION_FAIL = 'FETCH_MY_RESERVATION_FAIL';

export type FetchMyReservationActions =
  | FetchMyReservationStartAction
  | FetchMyReservationSuccessAction
  | FetchMyReservationFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchReservationThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMyReservationActions>;



/* ---------------------------------------------------------------------------------------------------- */


export interface FetchMyUserDataStartAction
  extends Action<typeof FETCH_MY_USERDATA_START> {}

const fetchMyUserDataStartAction = (): FetchMyUserDataStartAction => ({
  type: 'FETCH_MY_USERDATA_START',
});

export interface FetchMyUserDataSuccessAction
  extends Action<typeof FETCH_MY_USERDATA_SUCCESS> {
  payload: UserData
}

const fetchMyUserDataSuccessAction = (
  MyUserData: UserData,
): FetchMyUserDataSuccessAction => ({
  type: 'FETCH_MY_USERDATA_SUCCESS',
  payload: MyUserData
})

export interface FetchMyUserDataFailAction
  extends SnackBarAction<typeof FETCH_MY_USERDATA_FAIL> {
  
}

const fetchMyUserDataFailAction = (message: string): FetchMyUserDataFailAction => ({
  type: 'FETCH_MY_USERDATA_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ユーザーデータ取得 ThunkAction */
export function fetchMyUserData(): FetchMyUserDataThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMyUserDataActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMyUserDataStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token
      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/me/info`, header)
      const body: UserData = await response.data;
      dispatch(
        fetchMyUserDataSuccessAction(body),
      )
    } catch(err) {
      dispatch<FetchMyUserDataActions>(
        await ajaxErrorHandler(err, fetchMyUserDataFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface StoreMyUserDataStartAction
  extends Action<typeof STORE_MY_USERDATA_START> {}

const storeMyUserDataStartAction = (): StoreMyUserDataStartAction => ({
  type: 'STORE_MY_USERDATA_START',
});


export interface StoreMyUserDataLocalAction
  extends Action<typeof STORE_MY_USERDATA_LOCAL> {
  payload: UserData
}

const storeMyUserDataLocalAction = (
  MyUserData: UserData,
): StoreMyUserDataLocalAction => ({
  type: 'STORE_MY_USERDATA_LOCAL',
  payload: MyUserData
})


export interface StoreMyUserDataSuccessAction
  extends Action<typeof STORE_MY_USERDATA_SUCCESS> {
  payload: UserData
}

const storeMyUserDataSuccessAction = (
  MyUserData: UserData,
): StoreMyUserDataSuccessAction => ({
  type: 'STORE_MY_USERDATA_SUCCESS',
  payload: MyUserData
})

export interface StoreMyUserDataFailAction
  extends SnackBarAction<typeof STORE_MY_USERDATA_FAIL> {
  
}

const storeMyUserDataFailAction = (message: string): StoreMyUserDataFailAction => ({
  type: 'STORE_MY_USERDATA_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ユーザーデータ保存 ThunkAction */
export function storeMyUserData(data: UserData): StoreMyUserDataThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreMyUserDataActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeMyUserDataStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token
      const response = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/me/info`, header, data)
      const body: UserData = await response.data;
    
      dispatch(
        storeMyUserDataSuccessAction(body),
      )
    } catch(err) {
      dispatch<StoreMyUserDataActions>(
        await ajaxErrorHandler(err, storeMyUserDataFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchMyInquiryStartAction
  extends Action<typeof FETCH_MY_INQUIRY_START> {}

const fetchMyInquiryStartAction = (): FetchMyInquiryStartAction => ({
  type: 'FETCH_MY_INQUIRY_START',
});

export interface FetchMyInquirySuccessAction
  extends Action<typeof FETCH_MY_INQUIRY_SUCCESS> {
  payload: UserAcceptInquiry
}

const fetchMyInquirySuccessAction = (
  inquiry: UserAcceptInquiry,
): FetchMyInquirySuccessAction => ({
  type: 'FETCH_MY_INQUIRY_SUCCESS',
  payload: inquiry
})

export interface FetchMyInquiryFailAction
  extends SnackBarAction<typeof FETCH_MY_INQUIRY_FAIL> {
  
}

const fetchMyInquiryFailAction = (message: string): FetchMyInquiryFailAction => ({
  type: 'FETCH_MY_INQUIRY_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ユーザーデータ取得 ThunkAction */
export function fetchMyInquiry(perpage: number = 20, page: number = 0): FetchMyInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMyInquiryActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMyInquiryStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/me/inquiry/list/${perpage}?page=${page + 1}`
      const response = await AppClient.get(url, header)
      const body: UserAcceptInquiry = await response.data;
      dispatch(
        fetchMyInquirySuccessAction(body),
      )
    } catch(err) {
      dispatch<FetchMyInquiryActions>(
        await ajaxErrorHandler(err, fetchMyInquiryFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchMyReservationListStartAction
  extends Action<typeof FETCH_MY_RESERVATION_LIST_START> {}

const fetchMyReservationListStartAction = (): FetchMyReservationListStartAction => ({
  type: 'FETCH_MY_RESERVATION_LIST_START',
});

export interface FetchMyReservationListSuccessAction
  extends Action<typeof FETCH_MY_RESERVATION_LIST_SUCCESS> {
  payload: ReservationHeaderPagenation
}

const fetchMyReservationListSuccessAction = (
  headerList: ReservationHeaderPagenation,
): FetchMyReservationListSuccessAction => ({
  type: 'FETCH_MY_RESERVATION_LIST_SUCCESS',
  payload: headerList
})

export interface FetchMyReservationListFailAction
  extends SnackBarAction<typeof FETCH_MY_RESERVATION_LIST_FAIL> {
  
}

const fetchMyReservationListFailAction = (message: string): FetchMyReservationListFailAction => ({
  type: 'FETCH_MY_RESERVATION_LIST_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ユーザー予約一覧 取得 ThunkAction */
export function fetchMyReservationList(perpage: number = 20, page: number = 0): FetchReservationListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMyReservationListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMyReservationListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/me/reservation/list/${perpage}?page=${page + 1}`
      const response = await AppClient.get(url, header)
      const body: ReservationHeaderPagenation = await response.data;
      dispatch(
        fetchMyReservationListSuccessAction(body)
      )
    } catch(err) {
      dispatch<FetchMyReservationListActions>(
        await ajaxErrorHandler(err, fetchMyReservationListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchMyReservationStartAction
  extends Action<typeof FETCH_MY_RESERVATION_START> {}

const fetchMyReservationStartAction = (): FetchMyReservationStartAction => ({
  type: 'FETCH_MY_RESERVATION_START',
});

export interface FetchMyReservationSuccessAction
  extends Action<typeof FETCH_MY_RESERVATION_SUCCESS> {
  payload: SpaceReservation
}

const fetchMyReservationSuccessAction = (
  headerList: SpaceReservation,
): FetchMyReservationSuccessAction => ({
  type: 'FETCH_MY_RESERVATION_SUCCESS',
  payload: headerList
})

export interface FetchMyReservationFailAction
  extends SnackBarAction<typeof FETCH_MY_RESERVATION_FAIL> {
  
}

const fetchMyReservationFailAction = (message: string): FetchMyReservationFailAction => ({
  type: 'FETCH_MY_RESERVATION_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ユーザー予約 取得 ThunkAction */
export function fetchMyReservation(reserve_id: number): FetchReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMyReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMyReservationStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/dropin/reservation/${reserve_id}`
      const response = await AppClient.get(url, header)
      const body: SpaceReservation = await response.data;
      dispatch(
        fetchMyReservationSuccessAction(body)
      )
      dispatch(push(routes.userReservationDetail.path))
    } catch(err) {
      dispatch<FetchMyReservationActions>(
        await ajaxErrorHandler(err, fetchMyReservationFailAction),
      )
    }
  }
}
import * as React from 'react';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container, Divider, Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import RegularOwnerCard from '../components/Regular/RegularOwnerCard'
import RegularCalendarContainer from 'containers/RegularCalenadarContainer';
import { RegularEventInfo, RegularEvent } from 'dataObjects/space';
import EventDialogContainer from 'containers/EventDialogContainer';



interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    eventOwnerContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
      display: 'flex',
      justifyContent: 'space-evenly',
      alignContent: 'start',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
    invoice: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },

    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 1,
      marginBottom: 1
    },
    colWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      width: '100%'
    },
    invoice_content: {
      fontSize: '1.7rem'
    },
    invoice_content_value: {
      minWidth: '25%',
      textAlign: 'right'
    },
    invoice_amount: {
      fontSize: '2.0rem'
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
  })
);

interface OwnProps {
  space_id: number,
  space_name: string,
  eventList: RegularEvent[],
  eventInfoList: RegularEventInfo[]
}

export type SpaceRegularCalendarPageProps = OwnProps;

const SpaceRegularCalendarPage: React.FC<SpaceRegularCalendarPageProps> = (props: SpaceRegularCalendarPageProps) => {
  const classes = useStyles({} as StyleProps);
  const { space_id, space_name, eventList, eventInfoList } = props  

  const calenadarProps = {
    space_id: space_id,
    eventList: eventList
  }

  const renderEventInfoList = (eventInfoList) => {
    return eventInfoList.map(ev => {
      const _cardProps = {
        id: ev.id,
        title: ev.title,
        name: ev.name,
        image: ev.image,
        description: ev.description,
        link: ev.link
      }
      return (
        <RegularOwnerCard {..._cardProps} />
      )
    })
  }

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h1" className={classes.title}>
            [定期イベント] {space_name}
          </Typography>

          <Typography variant="h2" className={classes.subtitle}>
            月ごとの定期イベントをご紹介します
          </Typography>
          
          {/* 予約スケジュール表示 */}
          <div className={classes.container}>
            <RegularCalendarContainer {...calenadarProps} />
          </div>
          
          <Typography variant="h3" className={classes.subtitle}>
            イベントオーナーのご紹介
          </Typography>

          {/* 定期利用情報 */}
          <div className={classes.eventOwnerContainer}>
            { renderEventInfoList(eventInfoList) }
          </div>

          <EventDialogContainer />
        </div>
      </Container>
    </BasePageContainer>

  );

}

export default SpaceRegularCalendarPage
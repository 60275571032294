import { ActionTypes } from '../actions/actionTypes'
import { UserAcceptInquiry } from '../dataObjects/user'
import {
  FETCH_MY_INQUIRY_START,
  FETCH_MY_INQUIRY_SUCCESS,
  FETCH_MY_INQUIRY_FAIL
} from '../actions/userAction'


export interface UserAcceptInquiryState extends UserAcceptInquiry {

}

const initialUserAcceptInquiryState: UserAcceptInquiryState = {
  data: [],
  current_page: 1,
  first_page_url: process.env.REACT_APP_API_SERVER_HOST + 'api/me/inquiry/list/20?page=1',
  from: null,
  last_page: 1,
  last_page_url: process.env.REACT_APP_API_SERVER_HOST + 'api/me/inquiry/list/20?page=1',
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + 'api/me/inquiry/list/20',
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
}


/* userData Reducer */
const userInquiry = (
  state: UserAcceptInquiryState = initialUserAcceptInquiryState,
  action: ActionTypes,
): UserAcceptInquiryState => {
  switch (action.type) {
    // ユーザー問い合わせ読み込み
    case FETCH_MY_INQUIRY_START: 
      return { ...state }
    case FETCH_MY_INQUIRY_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_MY_INQUIRY_FAIL:
      return { ...state }   
    default:
      return { ...state };
  }
};

export default userInquiry

import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { fetchMyUserData } from 'actions/userAction';
import UserDataPage, { UserDataPageProps } from 'pages/UserDataPage';
import { closeDialog } from 'actions/dialogAction';

interface OwnProps {
}

export type UserDataPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function UserDataPageContainer(props: UserDataPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // ユーザーデータ読み込み
  useEffect(() => {
    dispatch(fetchMyUserData())
  }, []);

  const _props: UserDataPageProps = {
    dialogOpen: state.page.dialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
    }
  }

  return <UserDataPage {..._props} />

}
import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { renderTextField } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';
import { theme } from 'materialui/theme';
import { ValidationError } from 'errors/RequestValidationError';
import { Cation } from 'components/Cation';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },  
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      color: "#555"
    }
  }),
);


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'name',
    'email',
    'password',
    'password_confirmation'
  ]
  requiredFields.forEach(field => {
    console.log(`${field}: ${values[field]}`)
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })

  if(values['password'] !== values['password_confirmation']){
    errors['password_confirmation'] = '一つ目のパスワードと一致しません'
  }

  return errors
}

interface OwnProps {
  validationResult?: ValidationError,
  ss422Validation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void
}


export type SignUpProps = OwnProps & InjectedFormProps<{}, OwnProps>


const Form: React.FC<SignUpProps> = (props: SignUpProps) => {
  const classes = useStyles({});
  
  const { handleSubmit, pristine, submitting, touch } = props;

  if(props.validationResult && props.validationResult.status != '') {
    if (props.ss422Validation) {
      props.ss422Validation(props.validationResult.errors, touch);
    }
  }

  return (
    <Container component="main" maxWidth="xs">      
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sharealユーザー登録をする
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="ユーザー名"
            name="name"
            autoComplete="name"
            autoFocus
          />
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス (ログインIDになります)"
            name="email"
            autoComplete="email"
          />
          <Field
            component={renderTextField}
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="パスワード (8文字以上)"
            name="password"
            autoComplete="current-password"
          />
          <Field
            component={renderTextField}
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password_confirmation"
            label="パスワード(確認用)"
            name="password_confirmation"
            autoComplete="current-password"
          />

          <div style={{marginTop: theme.spacing(8)}}></div>

          <Button
            type="submit"
            fullWidth
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            ユーザー登録する
          </Button>

          <Cation>
            携帯メールアドレスで登録される方は<br />
            shareal.jp をドメイン受信許可設定に追加してください。
            <br /><br />
            登録後に本登録確認用のメールが送信されますが、<br />
            お客様のメールサーバーによっては迷惑メールとして扱われることがあります。<br />
            30分経過してもメールが届かない場合は迷惑メールフォルダをご確認ください。
          </Cation>          
          
        </form>
      </div>
    </Container>    

  )
}

const SignUp = reduxForm({
  form: 'SignUp', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form)

export default SignUp
import * as React from 'react';

import { Theme, createStyles, Container, StyledProps, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BasePageContainer from '../containers/BasePageContainer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({    
        title: {
            marginTop: theme.spacing(2),
            fontSize: '1.5em',
            paddingBottom: theme.spacing(2)
        },
    })
);

interface OwnProps {

}

export type UserReservationRedirectPageProps = OwnProps;

const UserReservationRedirectPage: React.FC<UserReservationRedirectPageProps> = (props: UserReservationRedirectPageProps) => {
    const classes = useStyles({} as StyledProps);
    return (
        <BasePageContainer>
            <Container fixed >     
                <Typography variant="h2" className={classes.title}>
                    予約内容確認画面へ移動中です
                </Typography>                     
            </Container>
        </BasePageContainer>
    );

}

export default UserReservationRedirectPage
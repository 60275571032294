import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { match, MatchFunction } from 'path-to-regexp';

import TopPageContainer from '../containers/TopPageContainer';
import NewsPageContainer from '../containers/NewsPageContainer'
import SignInPageContainer from '../containers/SignInPageContainer'
import SignUpPageContainer from '../containers/SignUpPageContainer'
import SignUpCompletePageContainer from '../containers/SignUpCompletePageContainer'
import SpacePageContainer from '../containers/SpacePageContainer'
import SpaceSchedulePageContainer from '../containers/SpaceSchedulePageContainer';
import SpaceCustomerInfoPageContainer from '../containers/SpaceCustomerInfoPageContainer'
import SpacePaymentPageContainer from '../containers/SpacePaymentPageContainer'
import SpacePaymentCompletePageContainer from '../containers/SpacePaymentCompletePageContainer'
import SpaceRegularCalendarPageContainer from '../containers/SpaceRegularCalendarPageContainer'
import InquiryPageContainer from '../containers/InquiryPageContainer'
import RegularInquiryPageContainer from '../containers/RegularInquiryPageContainer'

import SctlPageContainer from '../containers/SctlPageContainer'
import OperationPolicyPageContainer from '../containers/OperationPolicyPageContainer'


import AuthRoute from '../containers/AuthContainer';
import NonAuthRoute from '../containers/NonAuthContainer';
import NotFoundContainer from '../containers/NotFoundContainer';

import { fetchSettings } from '../actions/settingsAction';
import { fetchMasterAction } from '../actions/masterAction'
import UserDataPageContainer from '../containers/UserDataPageContainer';
import InquiryCompletePageContainer from '../containers/InquiryCompletePageContainer';
import UserInquiryPageContainer from '../containers/UserInquiryPageContainer';
import UserReservationListPageContainer from '../containers/UserReservationListPageContainer'
import UserReservationPageContainer from '../containers/UserReservationPageContainer'
import SignOutPageContainer from '../containers/SignOutPageContainer';
import ForgotPasswordPageContainer from '../containers/ForgotPasswordPageContainer';
import ForgotPasswordCompletePageContainer from '../containers/ForgotPasswordCompletePageContainer';
import TermsOfServicePageContainer from '../containers/TermsOfServicePageContainer';
import UsagePageContainer from '../containers/UsagePageContainer';
import FaqPageContainer from '../containers/FaqPageContainer';
import UserReservationRedirectPageContainer from '../containers/UserReservationRedirectPageContainer';


interface route {
  [name: string]: {
    path: string;
    title: string;
    getPath: (...params: any) => string; // TODO: optionalにしたい
    matcher?: MatchFunction;
  };
}


export const routes: route = {
  top: {
    path: '/',
    title: '',
    getPath: () => '/',
  },
  news: {
    path: '/news/:news_id',
    title: 'お知らせ',
    getPath: (news_id: string) => `/news/${news_id}`,
  },
  usage: {
    path: '/usage',
    title: 'ご利用の手引き',
    getPath: () => '/usage'
  },
  sctl: {
    path: '/sctl',
    title: '特定商取引に基づく表示',
    getPath: () => '/sctl'
  },
  faq: {
    path: '/faq',
    title: 'よくある質問',
    getPath: () => '/faq'
  },
  operationPolicy: {
    path: '/operationpolicy',
    title: 'サービス運営ポリシー',
    getPath: () => '/operationpolicy'
  },
  termsOfService: {
    path: '/termsofservice',
    title: '利用規約',
    getPath: () => '/termsofservice'
  },
  signIn: {
    path: '/signIn',
    title: 'ログイン',
    getPath: () => '/signIn'
  },
  signOut: {
    path: '/signOut',
    title: 'ログアウト',
    getPath: () => '/signOut'
  },
  signUp: {
    path: '/signup',
    title: '新規会員登録',
    getPath: () => '/signup'
  },
  signUpComplete: {
    path: '/signup/complete',
    title: '会員登録完了',
    getPath: () => '/signup/complete'
  },
  forgotPassword: {
    path: '/forgotpassword',
    title: 'パスワードを忘れた方',
    getPath: () => '/forgotpassword'
  },
  forgotPasswordSend: {
    path: '/forgotpassword/send',
    title: 'パスワードリセット送信',
    getPath: () => '/forgotpassword/send'
  },
  space: {
    path: '/space/:space_id',
    title: 'スペース',
    getPath: (space_id: string) => `/space/${space_id}`,
  },  
  spaceSchedule: {
    path: '/spaceSchedule/:space_id/',
    title: '[スペース予約] 利用時間選択',
    getPath: (space_id: string) => `/spaceSchedule/${space_id}`,
  },
  spaceSchedule_exact: {
    path: '/spaceSchedule/:space_id/:basedate',
    title: '[スペース予約] 利用時間選択',
    getPath: (space_id: string, basedate: string) => `/spaceSchedule/${space_id}/${basedate}`,
  },
  spaceRegularCalendar: {
    path: '/space/regularCalendar/:space_id',
    title: '定期イベント',
    getPath: (space_id: string) => `/space/regularCalendar/${space_id}`,
  },
  spaceCustomerInfo: {
    path: '/spaceCustomerInfo/:space_id',
    title: '[スペース予約] お客様情報入力',
    getPath: (space_id: string) => `/spaceCustomerInfo/${space_id}`
  },
  spacePayment: {
    path: '/spacePayment/:space_id',
    title: '[スペース予約] 利用料金お支払い手続き',
    getPath: (space_id: string) => `/spacePayment/${space_id}`
  },
  spacePaymentComplete: {
    path: '/spacePaymentComplete/',
    title: '[スペース予約] 予約完了',
    getPath: () => '/spacePaymentComplete'
  },  
  inquiry: {
    path: '/inquiry',
    title: 'お問い合わせ',
    getPath: () => '/inquiry'
  },
  regularInquiry: {
    path: '/regularInquiry',
    title: '定期利用のお問い合わせ',
    getPath: () => '/regularInquiry'
  },
  inquiryComplete: {
    path: '/inquiryComplete',
    title: 'お問い合わせ受付完了',
    getPath: () => '/inquiryComplete'
  },
  userInfo: {
    path: '/user/info',
    title: 'ユーザー情報編集',
    getPath: () => `/user/info`,
  },
  userInquiry: {
    path: '/user/inquiry',
    title: '問い合わせ履歴',
    getPath: () => '/user/inquiry',
  },
  userReservationList: {
    path: '/user/reservation/list',
    title: '予約履歴(キャンセル処理)',
    getPath: () => '/user/reservation/list',
  },
  userReservationDetail: {
    path: '/user/reservation/detail',
    title: '予約内容',
    getPath: () => '/user/reservation/detail',
  },
  userReservationRedirect: {
    path: '/user/reservation/redirect/:reserve_id',
    title: '予約内容確認画面へ移動中です',
    getPath: (reserve_id: string) => `/user/reservation/redirect/${reserve_id}`
  }
};

// Initialize matchers for each route
for (const key in routes) {
  routes[key].matcher = match(routes[key].path);
}

export const getTitleByPath = (path: string) : string => {
  const route = Object.values(routes).find((routeItem) => {
    const targetPath = routeItem.path.split('/', 2).join('/')
    if (targetPath == path ) {
      return true
    } else {
      return routeItem.matcher && routeItem.matcher(path);
    }
  });

  if (!route) {
    //throw new Error(`Route not found for path: ${path}`);
    console.log(`Route not found for path: ${path}`)
    return ''
  }

  return route.title;
}


export const AppRoute: React.FC = () => {
  const dispatch = useDispatch<CustomThunkDispatch>()

  // Settings取得 SiteMaster初期化
  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchMasterAction())    
  }, []);
  
  return (
    <Switch>
      <NonAuthRoute exact path={routes.top.path} component={TopPageContainer} />
      <NonAuthRoute exact path={routes.news.path} component={NewsPageContainer} />
      <NonAuthRoute exact path={routes.signIn.path} component={SignInPageContainer} />
      <NonAuthRoute exact path={routes.signUp.path} component={SignUpPageContainer} />
      <NonAuthRoute exact path={routes.signUpComplete.path} component={SignUpCompletePageContainer} />      
      <NonAuthRoute exact path={routes.space.path} component={SpacePageContainer} />
      <NonAuthRoute exact path={routes.spaceRegularCalendar.path} component={SpaceRegularCalendarPageContainer} />
      <NonAuthRoute exact path={routes.inquiry.path} component={InquiryPageContainer} />
      <NonAuthRoute exact path={routes.regularInquiry.path} component={RegularInquiryPageContainer} />
      <NonAuthRoute exact path={routes.inquiryComplete.path} component={InquiryCompletePageContainer} />
      <NonAuthRoute exact path={routes.sctl.path} component={SctlPageContainer} />
      <NonAuthRoute exact path={routes.operationPolicy.path} component={OperationPolicyPageContainer} />
      <NonAuthRoute exact path={routes.termsOfService.path} component={TermsOfServicePageContainer} />
      <NonAuthRoute exact path={routes.usage.path} component={UsagePageContainer} />
      <NonAuthRoute exact path={routes.faq.path} component={FaqPageContainer} />
      
      
      <NonAuthRoute exact path={routes.forgotPassword.path} component={ForgotPasswordPageContainer} />
      <NonAuthRoute exact path={routes.forgotPasswordSend.path} component={ForgotPasswordCompletePageContainer} />
      
      <NonAuthRoute exact path={routes.signOut.path} component={SignOutPageContainer} />

      <AuthRoute exact path={routes.spaceSchedule_exact.path} component={SpaceSchedulePageContainer} />
      <AuthRoute path={routes.spaceSchedule.path} component={SpaceSchedulePageContainer} />
      <AuthRoute exact path={routes.spaceCustomerInfo.path} component={SpaceCustomerInfoPageContainer} />
      <AuthRoute exact path={routes.spacePayment.path} component={SpacePaymentPageContainer} />
      <AuthRoute exact path={routes.spacePaymentComplete.path} component={SpacePaymentCompletePageContainer} />
      <AuthRoute exact path={routes.userInfo.path} component={UserDataPageContainer} />
      <AuthRoute exact path={routes.userInquiry.path} component={UserInquiryPageContainer} />
      <AuthRoute exact path={routes.userReservationList.path} component={UserReservationListPageContainer} />
      <AuthRoute exact path={routes.userReservationDetail.path} component={UserReservationPageContainer} />
      <AuthRoute exact path={routes.userReservationRedirect.path} component={UserReservationRedirectPageContainer} />
      
      
      
      
      <Route component={NotFoundContainer} />
    </Switch>
  );
};

import React, { Component } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';


const mapStyles = {
  position: 'relative',
  width: '100%',
  height: '350px'
};

const GoogleMap: React.FC = (props: any) => {  
	return (
    <div style={{width: '100%', height:'350px'}}>
      <Map
        google = { props.google }
        zoom = {15}
        draggable
        center = {{ lat: props.center.lat, lng: props.center.lng }}
        initialCenter = {{ lat: props.center.lat, lng: props.center.lng  }}
        style={mapStyles}
        containerStyle={{ position: 'relative', width: '100%', height: '350px' }}
      >
        <Marker
          title = { props.space_name }
          position = {{ lat: props.center.lat, lng: props.center.lng }}
        />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyCSOu_4ffVrYr2rIRnSJJjtPEoABWGbrao')
})(GoogleMap);
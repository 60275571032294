import React from 'react';

import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import DirectionsSubwayIcon from "@material-ui/icons/DirectionsSubway";
import PowerIcon from "@material-ui/icons/Power";
import SignalWifi4BarLockIcon from "@material-ui/icons/SignalWifi4BarLock";
import VideocamIcon from "@material-ui/icons/Videocam";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import WcIcon from "@material-ui/icons/Wc";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";
import DeckIcon from "@material-ui/icons/Deck";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AirlineSeatLegroomReducedIcon from '@material-ui/icons/AirlineSeatLegroomReduced';
import AirplayIcon from '@material-ui/icons/Airplay';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import Forward5Icon from '@material-ui/icons/Forward5';
import Forward10Icon from '@material-ui/icons/Forward10';
import Forward30Icon from '@material-ui/icons/Forward30';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import HdIcon from '@material-ui/icons/Hd';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import WeekendIcon from '@material-ui/icons/Weekend';


import { shadows } from '@material-ui/system';

import { classDeclaration } from '@babel/types';
import { SpaceFacility } from 'dataObjects/space';
import { useSelector } from 'react-redux';
import { AppState } from 'types/stateTypes';
import { iconList } from 'utils/MaterialUIIcnos';

interface StyleProps {
  root: BaseCSSProperties,
  description: BaseCSSProperties,
  cardGrid: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(2),
			width: '100%'
    },
    description_valid: {
      fontSize: '0.7em',
      whiteSpace: 'nowrap'
    },
    description_invalid: {
      color: '#cecece',
      fontSize: '0.7em',
      whiteSpace: 'nowrap'
    },
    icon_valid: {
      color: '#000000'
    },
    icon_invalid: {
      color: '#cecece'
    },
    cardGrid: {
      textAlign: 'center'
    },
    card_valid: {      
      borderColor: '#000000'
    },
    card_invalid: {
      borderColor: '#cecece'
    }
  })
)

interface OwnProps {
  space_facilities: SpaceFacility[]
}

type FacilitiesBoardPropsType = OwnProps

export const renderFacility = ({
  key,
  label,   
  cardGridClass,
  cardClass,
  descriptionClass,
  iconClass,
  iconComponent,
}) => {
  const Tag = iconList[iconComponent]

  return (
    <Grid item xs={3} sm={3} md={2} lg={1} xl={1} className={cardGridClass} key={key}>
      <Card className={cardClass}>
        <CardContent>
          <Tag className={iconClass} />        
          <Typography variant="body1" className={descriptionClass}>
            {label}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

const appStateSelector = (state: AppState) => state


const FacilitiesBoard: React.FC<FacilitiesBoardPropsType> = (props: FacilitiesBoardPropsType) => {  
  const classes = useStyles({} as StyleProps)
  const state = useSelector(appStateSelector)
	
	return (
		<Grid container spacing={2} className={classes.root}>
      {state.master.facilities.map((facility) => {
        const exists = props.space_facilities.some(f => f.id === facility.id)
        const cardClass = exists ? classes.card_valid : classes.card_invalid
        const iconClass = exists ? classes.icon_valid : classes.icon_invalid
        const descriptionClass = exists ? classes.description_valid : classes.description_invalid
        return renderFacility(
          {
            key: facility.id,
            label: facility.name, 
            cardGridClass: classes.cardGrid, 
            cardClass: cardClass, 
            descriptionClass: descriptionClass, 
            iconClass: iconClass, 
            iconComponent: facility.icon
          });
      })}
		</Grid>
	)
}

export default FacilitiesBoard
import React, { useEffect, SyntheticEvent } from 'react'
import { AppState } from 'types/stateTypes';
import { useSelector, useDispatch } from 'react-redux';
import { CustomThunkDispatch } from 'types/dispatchTypes';
import NotifyDialog from 'components/BrowserRestriction/NotifyDialog';
import { closeRestrictDialogAction } from 'actions/browserRestrictActions';

interface OwnPops {
}

export type BrowserRestrictionNotifyDialogContainerProps = OwnPops;

const appStateSelector = (state: AppState) => state
export default function BrowserRestrictionNotifyDialogContainer(
  props: BrowserRestrictionNotifyDialogContainerProps
) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    dialogOpen: state.browserRestrict.isOpen,
    handleClose: () => {
      dispatch(closeRestrictDialogAction())
    }
  };

  return <NotifyDialog {..._props} />;
}

import * as React from 'react';

import { Theme, createStyles, Container, Grid, Card, CardContent, Divider, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import { SpaceUsage, Invoice, Period } from '../dataObjects/space'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import ReservationStepperContainer from '../containers/ReservationStepperContainer'
import PaymentFormContainer from 'containers/PaymentFormContainer';


interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
    invoice: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },

    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 1,
      marginBottom: 1
    },
    colWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      width: '100%'
    },
    invoice_content: {
      fontSize: '1.7rem'
    },
    invoice_content_value: {
      minWidth: '25%',
      textAlign: 'right'
    },
    invoice_amount: {
      fontSize: '2.0rem'
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    submitButton: {
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    }, 
    dialogSubmitButton: {     
      color: '#FFF',
      backgroundColor: '#26A78E',
      width: '100px',
      height: '30px',
      marginRight: '10px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    dialogCancelButton: {           
      width: '100px',
      height: '30px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    buttonGreen: {
      color: '#FFF',
      backgroundColor: '#26A78E',
    },
    buttonRed: {
      color: '#FFF',
      backgroundColor: '#fa2120',
    },
    paymentContainer: {
      marginTop: theme.spacing(4) 
    },
    stripeField: {
      backgroundColor: '#f6f9fc',
      borderRadius: '4px',
      padding: '24px'
    }
  })
);

interface OwnProps {
  space_id: number,
  space_name: string,
  selectedDate: Date,
  timerange: string,
  selectedPeriods: Period[],
  users_adult: string | number,
  users_children: string | number,
  usage: SpaceUsage,
  detail: string,
  invoice: Invoice,
  family_name: string,
  given_name: string,
  family_name_kana: string,
  given_name_kana: string,
  email: string,
  postal_code: string,
  address_pref: string,
  address_city: string,
  address_street: string,
  address_room: string,
  tel: string,
  reserve_status: string,
  dialogOpen: boolean,
  handleClose: () => void
  onSubmit: () => void,
  onBack: () => void,
  stripe_public_key: string,
  client_secret: string,
}

export type UserReservationPageProps = OwnProps;

const UserReservationPage: React.FC<UserReservationPageProps> = (props: UserReservationPageProps) => {
  const [isShowConfirmDialog, setConfirmDialogState] = React.useState(false)
  const classes = useStyles({} as StyleProps);
  const formatter = new Intl.NumberFormat('ja-JP')
  
  const { space_id, space_name, selectedDate, timerange, selectedPeriods, users_adult,
    users_children, usage, detail, invoice,
    family_name, given_name, family_name_kana, given_name_kana,
    email, postal_code, address_pref, address_city,
    address_street, address_room, tel, reserve_status, onSubmit, onBack, stripe_public_key, client_secret
  } = props

  const steperProps = { activeStep: parseInt(reserve_status) }

  const stripePromise = loadStripe(stripe_public_key);

  const createSubtitle = (status) => {
    console.log('createSubtitle')
    console.log(status)
    switch(status) {
      case '0':
        return '以下の内容で予約処理中です'
      case '1':
        return '以下の予約内容で時間仮予約中です'
      case '2':
        return '以下の予約内容が決済処理前で中断されています'
      case '3':
        return '以下の予約内容で予約完了しています'
      case '4':
        return '決済処理でエラーが発生しています。カード情報を再入力してください'
      case '9':
        return '予約がキャンセルされています'
      default:
        return '予約システムエラーが発生しています'
    }
  }

  const showConfirmDialog = () => {
    setConfirmDialogState(true)
  }

  const onSubmitInDialog = () => {
    setConfirmDialogState(false)
    if(onSubmit) {
      onSubmit()
    }
  }

  const onCancelInDialog = () => {
    setConfirmDialogState(false)
  }

  const createButton = (status) => {
    console.log('createButton')
    console.log(status)
    const today = new Date()
    let buttonLabel = ''    
    let className = classes.submitButton
    switch(status) {
      case '1':        
        buttonLabel = 'お支払い手続きに進む'
        className += ' ' + classes.buttonGreen
        break;
      case '2':
        buttonLabel = 'お支払い手続きに進む'
        className += ' ' + classes.buttonGreen
        break;
      case '3':
        let reserveDate: Date
        if(typeof(selectedDate) == 'string') {
          reserveDate = new Date(selectedDate)
        } else {
          reserveDate = selectedDate
        }
        if(reserveDate.getDate() > today.getDate()) {
          // 当日か過日の場合はキャンセルできない          
          buttonLabel = 'キャンセル'
          className += ' ' + classes.buttonRed
        }
        
        buttonLabel = 'キャンセル'
        className += ' ' + classes.buttonRed
        break;
      case '4':
        buttonLabel = 'お支払い手続きに進む'        
        className += ' ' + classes.buttonGreen
        break;
      default:
        buttonLabel = ''
    }


    if (buttonLabel === '') {
      return null
    } else if (status === '4') {

      return (
        <Container component="div" maxWidth="sm" className={classes.paymentContainer}>
          <Typography variant="h3" className={classes.subtitle}>
            決済処理でエラーが発生しています。カード情報を再入力してください                   
          </Typography>

          <Container>
            <Elements stripe={stripePromise}>
              <PaymentFormContainer {...{ space_id: space_id, client_secret: client_secret }} />
            </Elements>
          </Container>

        </Container>
             
      )

    } else {
      
      return (
        <Grid container spacing={2} alignItems="center" justify="center" style={{marginBottom: '50px'}}>
          <Grid item xs={6}>
            <Button fullWidth className={className} onClick={showConfirmDialog}>
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>      
      )
    }

  }

  const periods = selectedPeriods.filter((v) => {
    return v.reserved === '2';      
  }).map((value,idx,arr) => {    
    return value.startTimeFromatted + " ～ " + value.endTimeFromatted;    
  });

  const periodsRendere = () => {
    const items = []
    for(let i = 0; i < periods.length; i++) {
      items.push(<span>{periods[i]}<br /></span>)
    }
    return <table style={{width: '60%'}}>
      <tr>
        <th style={{marginRight: '12px', verticalAlign: 'top', textAlign: 'left'}}>{timerange}</th>
        <td style={{textAlign: 'right', lineHeight: '1.5em'}}>{items}</td>
      </tr>
      <tr>
        <td></td>
        <td style={{marginTop: '4px', textAlign: 'right'}}>計 {periods.length} コマ</td>
      </tr>
    </table>
  }

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>
          <CustomizedBreadcrumbs />

          <Typography variant="h2" className={classes.title}>
            [予約内容] {space_name}
          </Typography>

          <ReservationStepperContainer {...steperProps} />

          <Typography variant="h3" className={classes.subtitle}>
            {createSubtitle(reserve_status)}
          </Typography>
          
          {/* 予約スケジュール表示 */}
          <div className={classes.reserveInfoContainer}>
            <dl>
              <dt>利用スペース</dt>
              <dd>{space_name}</dd>
            </dl>
            <dl>
              <dt>利用時間</dt>
              <dd>
                {periodsRendere()}
              </dd>
            </dl>
            <dl>
              <dt>利用人数</dt>
              <dd>大人：{users_adult}人&nbsp;&nbsp;子供：{users_children}人</dd>
            </dl>
            <dl>
              <dt>利用目的</dt>
              <dd>{usage ? usage.usage : ''}</dd>
            </dl>
            <dl>
              <dt>連絡事項</dt>
              <dd>{detail}</dd>
            </dl>

            <Divider className={classes.divider} />

            <dl>
              <dt>氏名かな</dt>
              <dd>{family_name_kana}{given_name_kana}</dd>
            </dl>
            <dl>
              <dt>氏名</dt>
              <dd>{family_name}{given_name}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{email}</dd>
            </dl>
            <dl>
              <dt>郵便番号</dt>
              <dd>{postal_code}</dd>
            </dl>
            <dl>
              <dt>都道府県</dt>
              <dd>{address_pref}</dd>
            </dl>
            <dl>
              <dt>市区町村</dt>
              <dd>{address_city}</dd>
            </dl>
            <dl>
              <dt>番地</dt>
              <dd>{address_street}</dd>
            </dl>
            <dl>
              <dt>建物名・部屋番号</dt>
              <dd>{address_room}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{tel}</dd>
            </dl>

            <Card variant="outlined" className={classes.invoice}>
              <CardContent>
                <Typography variant="h5" component="h2" className={classes.invoice_amount}>
                  ご請求明細
                </Typography>

                <Divider className={classes.divider} />

                {
                  invoice && invoice.details.map(d => {
                    return (
                      <div className={classes.colWrapper}>
                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              利用時間計 金額：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              ¥ {formatter.format(d.subtotal)}
                            </Typography>
                          </div>
                        </div>

                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              税({d.tax_rate * 100}%)：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              ¥ {formatter.format(d.tax)}
                            </Typography>
                          </div>
                        </div>

                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              小計：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              ¥ {formatter.format(d.amount)}
                            </Typography>
                          </div>
                        </div>

                      </div>
                    )
                    
                  })
                }

                <Divider className={classes.divider} />

                <div className={classes.rowWrapper}>
                  <div>
                    <Typography variant="body2" component="p" className={classes.invoice_content}>
                      プロモーションコードによる値引き：
                    </Typography>
                  </div>
                  <div className={classes.invoice_content_value}>
                    <Typography variant="body2" component="p" className={classes.invoice_content}>
                      ¥ {invoice && invoice.discount && formatter.format(invoice.discount)}
                    </Typography>
                  </div>
                </div>

                <Divider className={classes.divider} />

                <div className={classes.rowWrapper}>
                  <div>
                    <Typography variant="body2" component="p" className={classes.invoice_amount}>
                      合計（税込）：
                    </Typography>
                  </div>
                  <div className={classes.invoice_content_value}>
                    <Typography variant="body2" component="p" className={classes.invoice_amount}>
                      ¥ {invoice && invoice.amount && formatter.format(invoice.amount)}
                    </Typography>
                  </div>
                </div>

              </CardContent>
            </Card>
            
            {createButton(reserve_status)}                                              

          </div>

          <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '200px'}}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={onBack}>
                戻る
              </Button>
            </Grid>
          </Grid>   

          <Dialog
            open={isShowConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">実行確認</DialogTitle>
            <DialogContent dividers style={{display: 'flex'}}>
              <div>
                <NewReleasesIcon style={{ width: '30px', height: '30px', marginRight: '4px', color: '#AA0000'}} />
              </div>
              <div>
                <ul>
                    <li>実行します。よろしいですか？</li>
                </ul>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={onSubmitInDialog} className={classes.dialogSubmitButton}>
                実行
              </Button>
              <Button onClick={onCancelInDialog} className={classes.dialogCancelButton} autoFocus>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={props.dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"予約キャンセル処理結果"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                予約がキャンセルされました。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>


        </div>
      </Container>
    </BasePageContainer>

  );

}

export default UserReservationPage
import { ActionTypes } from '../actions/actionTypes';
import { SpaceReservationResult, SquareCardData } from '../dataObjects/space'
import {
  RESERVATION_PERIOD_START,
  RESERVATION_PERIOD_SUCCESS,
  RESERVATION_PERIOD_INVALID,
  RESERVATION_PERIOD_FAIL,
  RESERVATION_CONTRACTOR_START,
  RESERVATION_CONTRACTOR_SUCCESS,
  RESERVATION_CONTRACTOR_INVALID,
  RESERVATION_CONTRACTOR_FAIL,
  RESERVATION_CREATEINVOICE_START,
  RESERVATION_CREATEINVOICE_SUCCESS,
  RESERVATION_CREATEINVOICE_FAIL,
  FETCH_RESERVATION_START,
  FETCH_RESERVATION_FAIL,
  FETCH_RESERVATION_SUCCESS,
  RESERVATION_PAYMENT_START,
  RESERVATION_PAYMENT_SUCCESS,
  RESERVATION_PAYMENT_INVALID,
  RESERVATION_PAYMENT_FAIL,
  RESERVATION_STATE_RESET
} from '../actions/spaceReservationAction';
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';

/* スペース予約情報 */
const initialSpaceReservationResultState: SpaceReservationResult = {  
  payload: {
    space_id: undefined,
    space_name: '',
    status: '0',
    beforeStatus: '0',
    selectedDate: undefined,
    selectedPeriods: [],
    reserveTimeRange: {
      start_time: '',
      end_time: ''
    },
    enquete: {
      users_adult: 0,
      users_children: 0,
      usage: undefined,
      detail: '',
      terms_of_service: false
    },
    invoice: {
      invoice_id: 0,
      reserve_id: 0,
      amount: 0,
      details: [],
      discount: 0
    },
    contractor: {
      reserve_id: 0,
      family_name: '',
      family_name_kana: '',
      given_name: '',
      given_name_kana: '',
      postal_code: '',
      address_pref: '',
      address_city: '',
      address_street: '',
      address_room: '',
      email: '',
      email_confirmation: '',
      tel: '',
      promotion_code: '',
      company_name: ''
    }    
  },
  validationResult: [{
    item: undefined,
    error: undefined
  }]
}

/* スペース予約Reducer */
const reservationSpace = (
  state: SpaceReservationResult = initialSpaceReservationResultState,
  action: ActionTypes,
): SpaceReservationResult => {
  switch (action.type) {
    // 予約処理中データ取得
    case FETCH_RESERVATION_START:
      return { ...state };
    case FETCH_RESERVATION_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,          
          ...action.payload
        },        
      }
    case FETCH_RESERVATION_FAIL:
      return { ...state };
    // コマ予約
    case RESERVATION_PERIOD_START:
      return { ...state };
    case RESERVATION_PERIOD_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,          
          ...action.payload.payload
        },        
      }
    case RESERVATION_PERIOD_INVALID:
      return { ...state }
    case RESERVATION_PERIOD_FAIL:
      return { ...state };
    // 契約者情報
    case RESERVATION_CONTRACTOR_START:
      return { ...state };
    case RESERVATION_CONTRACTOR_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,          
          ...action.payload.payload
        },        
      }
    case RESERVATION_CONTRACTOR_INVALID:
      return { ...state }
    case RESERVATION_CONTRACTOR_FAIL:
      return { ...state };
    /* 請求情報 */
    case RESERVATION_CREATEINVOICE_START:
      return { ...state };
    case RESERVATION_CREATEINVOICE_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,
          invoice: {
            ...state.payload.invoice,
            ...action.payload
          }
        }
      }
    case RESERVATION_CREATEINVOICE_FAIL:
      return { ...state };
    
    // 決済情報
    case RESERVATION_PAYMENT_START:
      return { ...state };
    case RESERVATION_PAYMENT_SUCCESS:
      return {
        ...state,
        payload: {
          ...state.payload,          
          ...action.payload.payload
        },        
      }
    case RESERVATION_PAYMENT_INVALID:
      return { ...state }
    case RESERVATION_PAYMENT_FAIL:
      return { ...state };

    // 予約完了後のリセット
    case RESERVATION_STATE_RESET:
      return { ...state, ...initialSpaceReservationResultState}

    // ユーザー予約情報から遷移    
    case LOGOUT_SUCCESS:
      return { ...state, ...initialSpaceReservationResultState}
    default:
      return { ...state };
  }
};

export default reservationSpace

import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import moment from 'moment'
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { SpaceInfo,　ViewingSpace, Period, DayStatus } from '../dataObjects/space'
import { AxiosResponse } from 'axios';
import { SnackBarAction } from './actionTypes';
import { dateToString } from 'utils/convertor';

/* スペース選択アクションType */
export const FETCH_SPACE_INFO_START = 'FETCH_SPACE_INFO_START'
export const FETCH_SPACE_INFO_SUCCESS = 'FETCH_SPACE_INFO_SUCCESS'
export const FETCH_SPACE_INFO_FAIL = 'FETCH_SPACE_INFO_FAIL'

/* スペーススケジュール取得アクションType */
export const FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START = 'FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START'
export const FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS = 'FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS'
export const FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL = 'FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL'

export const FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START = 'FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START'
export const FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS = 'FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS'
export const FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL = 'FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL'

export const RESET_SELECTED_DATE = 'RESET_SELECTED_DATE'

/* コマ情報取得アクションType */
export const FETCH_PERIOD_AT_DAY_START = 'FETCH_PERIOD_AT_DAY_START'
export const FETCH_PERIOD_AT_DAY_SUCCESS = 'FETCH_PERIOD_AT_DAY_SUCCESS'
export const FETCH_PERIOD_AT_DAY_FAIL = 'FETCH_PERIOD_AT_DAY_FAIL'

/* コマ選択アクションType */
export const SELECT_PERIOD = 'SELECT_PERIOD'

/* スペースデータ取得アクション */
export type FetchSpaceInfoActions = 
  | FetchSpaceInfoStartAction
  | FetchSpaceInfoSuccessAction
  | FetchSpaceInfoFailAction
  | ErrorActions

type SpaceThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchSpaceInfoActions
  >


/* スペースWeeklyスケジュール取得アクション */
export type FetchSpaceWeeklyScheduleActions = 
  | FetchSpaceWeeklyScheduleStartAction
  | FetchSpaceWeeklyScheduleSuccessAction
  | FetchSpaceWeeklyScheduleFailAction
  | ErrorActions

type SpaceWeeklyScheduleThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchSpaceWeeklyScheduleActions
  >  

/* スペースMonthlyスケジュール取得アクション */
export type FetchSpaceMonthlyScheduleActions = 
  | FetchSpaceMonthlyScheduleStartAction
  | FetchSpaceMonthlyScheduleSuccessAction
  | FetchSpaceMonthlyScheduleFailAction
  | ErrorActions

type SpaceMonthlyScheduleThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchSpaceWeeklyScheduleActions
  >  


/* Periodデータ取得アクション */
export type FetchPeriodAtDayActions =
  | FetchPeriodAtDayStartAction
  | FetchPeriodAtDaySuccessAction
  | FetchPeriodAtDayFailAction
  | ErrorActions

type PeriodThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchPeriodAtDayActions
  >


/* スペースデータ取得START Action */
export interface FetchSpaceInfoStartAction
  extends Action<typeof FETCH_SPACE_INFO_START> { }

const fetchSpaceInfoStartAction = (): FetchSpaceInfoStartAction => {
  return {
    type: FETCH_SPACE_INFO_START,
  }
}

/* スペースデータ取得成功 Action */
export interface FetchSpaceInfoSuccessAction
  extends Action<typeof FETCH_SPACE_INFO_SUCCESS> {
  payload: Pick<ViewingSpace, 'space_info'>
}

const fetchSpaceInfoSuccessAction = (payload: Pick<ViewingSpace, 'space_info'>)
  : FetchSpaceInfoSuccessAction => {
  return {
    type: FETCH_SPACE_INFO_SUCCESS,
    payload,
  }
}

/* スペースデータ取得失敗 Action */
export interface FetchSpaceInfoFailAction
  extends SnackBarAction<typeof FETCH_SPACE_INFO_FAIL> {
  
}

const fetchSpaceInfoFailAction = (
  message: string
  ): FetchSpaceInfoFailAction => {
  return {
    type: FETCH_SPACE_INFO_FAIL, snackBarMessage: message,
    variant: 'error'};
};


/* --------------------------------------------------------------------------------- */

/* スペースWeeklyスケジュール取得 START Action */
export interface FetchSpaceWeeklyScheduleStartAction
  extends Action<typeof FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START> { }

const fetchSpaceWeeklyScheduleStartAction = (): FetchSpaceWeeklyScheduleStartAction => {
  return {
    type: FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START,
  }
}

/* スペースWeeklyスケジュール取得 成功 Action */
export interface FetchSpaceWeeklyScheduleSuccessAction
  extends Action<typeof FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS> {
  payload: Pick<ViewingSpace, 'weeklyScheduleStatus'>
}

const fetchSpaceWeeklyScheduleSuccessAction = (payload: Pick<ViewingSpace, 'weeklyScheduleStatus'>)
  : FetchSpaceWeeklyScheduleSuccessAction => {
  return {
    type: FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS,
    payload,
  }
}

/* スペースWeeklyスケジュール取得 失敗 Action */
export interface FetchSpaceWeeklyScheduleFailAction
  extends SnackBarAction<typeof FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL> {
  
}

const fetchSpaceWeeklyScheduleFailAction = (
  message: string
  ): FetchSpaceWeeklyScheduleFailAction => {
  return {
    type: FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL, snackBarMessage: message,
    variant: 'error'};
};

/* --------------------------------------------------------------------------------- */

/* スペースMonthlyスケジュール取得 START Action */
export interface FetchSpaceMonthlyScheduleStartAction
  extends Action<typeof FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START> { }

const fetchSpaceMonthlyScheduleStartAction = (): FetchSpaceMonthlyScheduleStartAction => {
  return {
    type: FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START,
  }
}

/* スペースMonthlyスケジュール取得 成功 Action */
export interface FetchSpaceMonthlyScheduleSuccessAction
  extends Action<typeof FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS> {
  payload: Pick<ViewingSpace, 'monthlyScheduleStatus'>
}

const fetchSpaceMonthlyScheduleSuccessAction = (payload: Pick<ViewingSpace, 'monthlyScheduleStatus'>)
  : FetchSpaceMonthlyScheduleSuccessAction => {
  return {
    type: FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS,
    payload,
  }
}

/* スペースMonthlyスケジュール取得 失敗 Action */
export interface FetchSpaceMonthlyScheduleFailAction
  extends SnackBarAction<typeof FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL> {
  
}

const fetchSpaceMonthlyScheduleFailAction = (
  message: string
  ): FetchSpaceMonthlyScheduleFailAction => {
  return {
    type: FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL, snackBarMessage: message,
    variant: 'error'};
};

/* --------------------------------------------------------------------------------- */

/**
 * 選択日クリア Action
 */
export interface ResetSelectedDateAction extends Action<typeof RESET_SELECTED_DATE> {
}

export const resetSelectedDateAction = () : ResetSelectedDateAction => {
  return { type: RESET_SELECTED_DATE }
}

/* --------------------------------------------------------------------------------- */

/* コマデータ取得START Action */
export interface FetchPeriodAtDayStartAction
  extends Action<typeof FETCH_PERIOD_AT_DAY_START> { }

const fetchPeriodAtDayStartAction = (): FetchPeriodAtDayStartAction => {
  return {
    type: FETCH_PERIOD_AT_DAY_START,
  }
}

/* コマデータ取得成功 Action */
export interface FetchPeriodAtDaySuccessAction
  extends Action<typeof FETCH_PERIOD_AT_DAY_SUCCESS> {
  payload: Pick<ViewingSpace, 'selectedDate' | 'periodsAtDate'>
}

const fetchPeriodAtDaySuccessAction = (payload: Pick<ViewingSpace, 'selectedDate' | 'periodsAtDate'>)
  : FetchPeriodAtDaySuccessAction => {
  return {
    type: FETCH_PERIOD_AT_DAY_SUCCESS,
    payload,
  };
};

/* コマデータ取得失敗 Action */
export interface FetchPeriodAtDayFailAction
  extends SnackBarAction<typeof FETCH_PERIOD_AT_DAY_FAIL> {
  
}

const fetchPeriodAtDayFailAction = (
  message: string
): FetchPeriodAtDayFailAction => {
  return {
    type: FETCH_PERIOD_AT_DAY_FAIL, snackBarMessage: message,
    variant: 'error'};
};


/* コマ選択アクション */
export interface SelectPeriodAtDayAction
  extends Action<typeof SELECT_PERIOD> {
    payload: Pick<ViewingSpace, 'periodsAtDate'>
  }

export const selectPeriodAtDayAction = (payload: Pick<ViewingSpace, 'periodsAtDate'>)
  : SelectPeriodAtDayAction => {
  return {
    type: SELECT_PERIOD,
    payload,
  }
}


/* --------------------------------------------------------------------------------- */

/* サーバーからコマ情報を取得 */
async function fetchPeriodData(space_id: number, date: Date, reserve_id: number, access_token: string): Promise<Period[]> {
  
  const strSpace_id = space_id.toString();
  const dateStr = (moment(date)).format('YYYY-MM-DD')
  const strReserve_id = reserve_id ? reserve_id.toString() : '';
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token
  const periodDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/space/periods/${strSpace_id}/${dateStr}/${strReserve_id}`, header);  
  const periodData: Period[] = await periodDataRes.data;

  return periodData;
}

/* 指定したスペースと日付のコマ情報を取得する */
export function fetchPeriodAtDayAction(space_id: number, date: Date): PeriodThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchPeriodAtDayActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchPeriodAtDayStartAction())

      const today = new Date()
      const state = getState()
      const data: Period[] = await fetchPeriodData(space_id, date, state.reservationSpace.payload.reserve_id, state.authStatus.access_token)
    
      // 予約処理中データがあればコマ選択状態を反映させる
      let refrectedPeriod: Period[] = data
      if(state.reservationSpace.payload) {
        const { space_id, selectedDate, selectedPeriods } = state.reservationSpace.payload
        if (space_id && selectedDate && selectedPeriods) {
          if (state.viewingSpace.space_info.space_id === space_id
              && state.viewingSpace.selectedDate === selectedDate 
              && selectedPeriods) {
            refrectedPeriod = data.map(p => {
              p.selected = selectedPeriods.some(selp => selp.id === p.id)
              return p
            })
          }
        }
      }
      if(state.viewingSpace.selectedDate == date
        && state.viewingSpace.periodsAtDate && state.viewingSpace.periodsAtDate.length > 0) {
        refrectedPeriod = state.viewingSpace.periodsAtDate.map(p => {
          p.selected = refrectedPeriod.some(selp => (selp.id === p.id) && (p.selected || selp.selected) )
          return p
        })
      }

      const toString = Object.prototype.toString
      refrectedPeriod = refrectedPeriod.map(p => {
        const taxratetypes = state.master.taxratetypes
        console.log({taxratetypes})
        const tax = state.master.taxrates.find(tax => {
          const rate_type = state.master.taxratetypes.find(rateType => rateType.id === tax.tax_rate_type_id)
          const from = toString.call(tax.enable_from) == '[object Date]' ? tax.enable_from : dateToString(tax.enable_from).replace(/-/g,"/")
          const to = toString.call(tax.enable_to) == '[object Date]' ? tax.enable_to : dateToString(tax.enable_to).replace(/-/g,"/")
          return today >= (new Date(from)) && today <= (new Date(to)) && parseInt(p.tax_kind) == rate_type.id
        })
        // 消費税計算して総額を算出
        console.log({tax})
        const rate = tax ? tax.rate : 0
        const t = Math.floor(p.price * rate)        
        p.tax_included_price = p.price + t     
        return p   
      })

      dispatch(
        fetchPeriodAtDaySuccessAction({
          selectedDate: date,
          periodsAtDate: refrectedPeriod
        }),
      )
    
    } catch (err) {
      dispatch<FetchPeriodAtDayActions>(
        await ajaxErrorHandler(err, fetchPeriodAtDayFailAction)
      )
    }
  }
}

/* --------------------------------------------------------------------------------- */


/* サーバーからスペース情報を取得 */
async function fetchSpaceData(space_id: number): Promise<SpaceInfo> {
  
  const spaceDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/space/info/${space_id}`);
  const spaceData: SpaceInfo = await spaceDataRes.data;
  
  return spaceData;
}

/* 指定したスペースの取得する */
export function fetchSpaceInfoAction(space_id: number | string): SpaceThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchSpaceInfoActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchSpaceInfoStartAction())
    
      const data: SpaceInfo = await fetchSpaceData('string' === typeof space_id ? parseInt(space_id) : space_id)
              
      dispatch(
        fetchSpaceInfoSuccessAction({
          space_info: data
        }),
      )
    
    } catch (err) {
      dispatch<FetchSpaceInfoActions>(
        await ajaxErrorHandler(err, fetchSpaceInfoFailAction)
      )
    }
  }
}


/* --------------------------------------------------------------------------------- */


/* サーバーからスケジュール情報を取得 */
async function fetchScheduleStatus(space_id: number, startDate: Date, endDate: Date): Promise<DayStatus[]> {
  
  console.log('fetchScheduleStatus START')
  const sDateStr = (moment(startDate)).format('YYYY-MM-DD')
  const eDateStr = (moment(endDate)).format('YYYY-MM-DD')

  console.log(sDateStr)
  console.log(eDateStr)
  const spaceDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/space/schedule/${space_id}/${sDateStr}/${eDateStr}`);
  const scheduleData: DayStatus[] = await spaceDataRes.data;
      
  return scheduleData;
}

/* 指定したスペースの週単位スケジュールを取得する */
export function fetchSpaceWeeklyScheduleStatusAction(space_id: number | string, startDate: Date, endDate: Date): SpaceWeeklyScheduleThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchSpaceWeeklyScheduleActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchSpaceWeeklyScheduleStartAction())
    
      const data: DayStatus[] = await fetchScheduleStatus('string' === typeof space_id ? parseInt(space_id) : space_id, 
                                                            startDate, endDate)
              
      dispatch(
        fetchSpaceWeeklyScheduleSuccessAction({
          weeklyScheduleStatus: data
        }),
      )
    
    } catch (err) {
      dispatch<FetchSpaceWeeklyScheduleActions>(
        await ajaxErrorHandler(err, fetchSpaceWeeklyScheduleFailAction)
      )
    }
  }
}


/* 指定したスペースの月単位スケジュールを取得する */
export function fetchSpaceMonthlyScheduleStatusAction(space_id: number | string, startDate: Date, endDate: Date): SpaceMonthlyScheduleThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchSpaceMonthlyScheduleActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchSpaceMonthlyScheduleStartAction())
    
      const data: DayStatus[] = await fetchScheduleStatus('string' === typeof space_id ? parseInt(space_id) : space_id, 
                                                            startDate, endDate)
              
      dispatch(
        fetchSpaceMonthlyScheduleSuccessAction({
          monthlyScheduleStatus: data
        }),
      )
    
    } catch (err) {
      dispatch<FetchSpaceMonthlyScheduleActions>(
        await ajaxErrorHandler(err, fetchSpaceMonthlyScheduleFailAction)
      )
    }
  }
}
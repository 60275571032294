import {
  SS_VALIDATION_ERROR,
  SS_VALIDATION_CLEAR
} from '../actions/errorAction';

import { ActionTypes } from '../actions/actionTypes';
import { RequestValidationResult } from 'errors/RequestValidationError';

export interface RequestValidationResultState extends RequestValidationResult {
}

const initialRequestValidationResultState: RequestValidationResultState = {
  status: '',
  summary: '',
  errors: undefined
};

/**
 * Laravel 422 Request Validation Reducer
 * @param state 
 * @param action 
 */
const ss422ValidationErrorResult = (
  state: RequestValidationResultState = initialRequestValidationResultState,
  action: ActionTypes,
): RequestValidationResultState => {
  switch (action.type) {
    // サーバーサイドバリデーションエラー発生
    case SS_VALIDATION_ERROR:
      return { ...state, ...action.payload };
    case SS_VALIDATION_CLEAR:
      return { ...state, ...initialRequestValidationResultState };
    default:
      return { ...state };
  }
};

export default ss422ValidationErrorResult;

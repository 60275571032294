import React, { useEffect, SyntheticEvent } from 'react'
import RegularCalendar from "components/Regular/RegularCalendar"
import { RegularEvent } from "dataObjects/space"
import { getStartAndEndDateOfWeek } from 'utils/DateExtention'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'types/stateTypes'
import { CustomThunkDispatch } from 'types/dispatchTypes'
import { fetchRegularScheduleList, fetchRegularInfoList, showScheduleEvent } from 'actions/regularAction'



interface OwnPops {
  space_id: number,
  eventList: RegularEvent[]
}

export type RegularCalendarContainerProps = OwnPops

const appStateSelector = (state: AppState) => state

export default function RegularCalendarContainer(props: RegularCalendarContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()
  const { space_id, eventList } = props

  const _props = {
    timeZone: "Asia/Tokyo",
    events: props.eventList,
    view: state.regularEventList.view,    
    defDate: state.regularEventList.defdate,
    onNavigate: (focusDate: Date, flipUnit: any, prevOrNext: String) => {
      
      let fromDate2 = state.regularEventList.schedule_from
      let toDate2 = state.regularEventList.schedule_to
      let defDate = state.regularEventList.defdate

      let chosei = 0
      if (prevOrNext.toLowerCase() == 'prev') {
        chosei = -1
      } else if (prevOrNext.toLowerCase() == 'next') {
        chosei = 1
      }

      if(prevOrNext.toLowerCase() == 'today') {
        if (flipUnit == 'month') {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); // 翌月の 0日は当月の最終日
          defDate = focusDate
        } else if (flipUnit == 'week') {
          const seDate = getStartAndEndDateOfWeek(focusDate)
          fromDate2 = seDate.startDate
          toDate2 = seDate.endDate
          defDate = focusDate
        } else if (flipUnit == 'day') {          
          fromDate2.setDate(focusDate.getDate())
          toDate2.setDate(focusDate.getDate())
          defDate = focusDate
        } else if (flipUnit == 'agenda') {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); 
          defDate = focusDate
        } else {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); 
          defDate = focusDate
        }
      } else {
        if (flipUnit == 'month') {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1 // prev or next 後の月の翌月を算出
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0); // 翌月の 0日は当月の最終日
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        } else if (flipUnit == 'week') {
          defDate.setDate(defDate.getDate() + (chosei * 7))
          const seDate = getStartAndEndDateOfWeek(defDate)
          fromDate2 = seDate.startDate
          toDate2 = seDate.endDate
        } else if (flipUnit == 'day') {
          defDate.setDate(defDate.getDate() + chosei)
          fromDate2.setDate(defDate.getDate())
          toDate2.setDate(defDate.getDate())
        } else if (flipUnit == 'agenda') {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0)
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        } else {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1 
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0)
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        }
      }

      dispatch(fetchRegularScheduleList(space_id, fromDate2, toDate2, flipUnit, defDate))
      dispatch(fetchRegularInfoList(space_id, fromDate2, toDate2))
      
    },
    onView: (view: string, date: Date) => {
      let fromDate2 = state.regularEventList.schedule_from
      let toDate2 = state.regularEventList.schedule_to
      let defDate = state.regularEventList.defdate

      if(date) {
        defDate = date
      }

      if (view == 'month') {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0); // 翌月の 0日は当月の最終日        
      } else if (view == 'week') {
        const seDate = getStartAndEndDateOfWeek(defDate)
        fromDate2 = seDate.startDate
        toDate2 = seDate.endDate
      } else if (view == 'day') {          
        fromDate2.setDate(defDate.getDate())
        toDate2.setDate(defDate.getDate())        
      } else if (view == 'agenda') {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0);         
      } else {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0);         
      }

      dispatch(fetchRegularScheduleList(space_id, fromDate2, toDate2, view, defDate))
      dispatch(fetchRegularInfoList(space_id, fromDate2, toDate2))
    },
    onSelectEvent: (event: any, e: SyntheticEvent) => {
      dispatch(showScheduleEvent(event))
    }
  }

  return <RegularCalendar {..._props} />
}

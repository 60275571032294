import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import SpaceRegularCalendarPage from 'pages/SpaceRegularCalendarPage'
import { observeShownAction } from 'actions/pagingAction'
import { RouteComponentProps } from 'react-router'
import { CustomThunkDispatch } from 'types/dispatchTypes'
import { fetchRegularScheduleList, fetchRegularInfoList } from 'actions/regularAction'

interface OwnProps {
  space_id: number
}

const appStateSelector = (state: AppState) => state

export type SpaceRegularCalendarPageContainerProps = OwnProps & RouteComponentProps<{ space_id: string }>

export default function SpaceRegularCalendarPageContainer(props: SpaceRegularCalendarPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const fromDate = new Date()
  fromDate.setDate(1)
  const toDate = new Date()
  toDate.setDate(1);
  toDate.setMonth(toDate.getMonth() + 1);
  toDate.setDate(0);

  useEffect(() => {
    if (props.match.params.space_id) {
      const space_id = parseInt(props.match.params.space_id)
      dispatch(fetchRegularScheduleList(space_id, fromDate, toDate))
      dispatch(fetchRegularInfoList(space_id, fromDate, toDate))
    } else {
      dispatch(observeShownAction());
    }
  }, []);

  const _props = {
    space_id: props.match.params.space_id ? parseInt(props.match.params.space_id) : props.space_id,
    space_name: state.viewingSpace.space_info.space_name,
    eventList: state.regularEventList.body,
    eventInfoList: state.regularEventInfoList.list
  }


  return <SpaceRegularCalendarPage {..._props} />

}

import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { observeShownAction } from '../actions/pagingAction'
import TopPage, { TopPageProps } from '../pages/TopPage'
import { fetchNewsList } from 'actions/newsAction';
import { shouldShowRestrictDialogAction } from 'actions/browserRestrictActions';

interface OwnProps {
}

export type TopPageContainerProps = OwnProps & RouteComponentProps<{ space_id?: string }>

const appStateSelector = (state: AppState) => state

export default function TopPageContainer(props: TopPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(shouldShowRestrictDialogAction())
    dispatch(fetchNewsList());
    dispatch(observeShownAction());
  }, []);

  const _props: TopPageProps = {
    newsList: state.newsList
  }

  return <TopPage {..._props} />

}
import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { closeSnackBarAction } from '../actions/snackBarAction';
import { SnackBar } from '../components/SnackBar';

interface Props {
}

const appStateSelector = (state: AppState) => state

export default function SnackBarContainer(props: Props) {
  const state = useSelector(appStateSelector)

  const mapStateToProps = {
    open: state.snackBar.available,
    message: state.snackBar.reason,
    variant: state.snackBar.variant,
  };

  const dispatch = useDispatch()
  const actions = {
    handleClose: (event: React.MouseEvent) => dispatch(closeSnackBarAction())    
  }
  
  const _props = { ...mapStateToProps, ...actions };

  return <SnackBar {..._props} />;
}
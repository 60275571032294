
const getCookieByName = (key: string) => {
  let ret = ''
  const cookies = document.cookie;
  const cookiesArray = cookies.split(';');

  for(var c of cookiesArray){
      var cArray = c.split('=');
      if( cArray[0].trim() == key){ 
        ret = decodeURIComponent(cArray[1].trim())
        break
      }
  }

  return ret
}

export default getCookieByName
import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { Settings, OAuth } from '../dataObjects/settings';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { ErrorActions } from './errorAction';
import { SnackBarAction } from './actionTypes';

export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL';

export type SettingsActions =
  | FetchSettingsStartAction
  | FetchSettingsSuccessAction
  | FetchSettingsFailAction
  | ErrorActions;
type ThunkResult<R> = ThunkAction<R, AppState, undefined, SettingsActions>;

/* システム設定取得 ThunkAction */
export function fetchSettings(): ThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, SettingsActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      if (state.settings.fetchErrorCount > 5) {
        console.error('state.settings.fetchErrorCount over limit');
        dispatch(
          fetchSettingsSuccessAction({
            oauth: undefined,
            stripe: undefined,
            google_map: undefined
          }),
        )
      } else {

        dispatch(fetchSettingStartAction());
    
        const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/settings`)
        const body: Settings = await response.data;
      
        dispatch(
          fetchSettingsSuccessAction(body),
        )
      }

    } catch(err) {
      dispatch<SettingsActions>(
        await ajaxErrorHandler(err, fetchSettingsFailAction),
      )
    }
  }
}

export interface FetchSettingsStartAction
  extends Action<typeof FETCH_SETTINGS_START> {}

const fetchSettingStartAction = (): FetchSettingsStartAction => ({
  type: 'FETCH_SETTINGS_START',
});

export interface FetchSettingsSuccessAction
  extends Action<typeof FETCH_SETTINGS_SUCCESS> {
  payload: Settings
}

const fetchSettingsSuccessAction = (
  settings: Settings,
): FetchSettingsSuccessAction => ({
  type: 'FETCH_SETTINGS_SUCCESS',
  payload: settings
})

export interface FetchSettingsFailAction
  extends SnackBarAction<typeof FETCH_SETTINGS_FAIL> {

}

const fetchSettingsFailAction = (message: string): FetchSettingsFailAction => ({
  type: 'FETCH_SETTINGS_FAIL',
  snackBarMessage: message,
  variant: 'error',
})

import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation, formValueSelector, change } from 'redux-form'
import CustomerInfoForm, { validate } from '../components/Space/CustomerInfoForm'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import {
  reservationContractorAction  
} from '../actions/spaceReservationAction'
import { SpaceReservation, Contractor } from '../dataObjects/space';
import { ValidationError } from 'errors/RequestValidationError'
import { addressSearchByPostalCode, PostalCodeSearchResult } from 'utils/postalCodeSearch'
import { serverValidationClear } from 'actions/errorAction'

interface OwnProps {
  space_id: number
}

type Props = OwnProps

const CN_FORM_NAME = 'CustomerInfoForm'

const appStateSelector = (state: AppState) => state

const fvSelector = formValueSelector(CN_FORM_NAME)

export default function CustomerInfoFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()
  
  const autoAddressLoad = (fnChange) => {
    const postal_code = fvSelector(state, 'postal_code')
    // 住所検索
    addressSearchByPostalCode(postal_code, (result) => {
      let addressType: PostalCodeSearchResult = null
      if (Array.isArray(result.results)) {
        addressType = result.results[0]
      } else {
        addressType = result.results
      }
      fnChange("address_pref", addressType.address1);
      fnChange("address_city", addressType.address2);
      fnChange("address_street", addressType.address3);
    })
  }

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      const serverContractor = state.reservationSpace.payload.contractor;
      const contractor: Contractor = {
        reserve_id: state.reservationSpace.payload.reserve_id,
        family_name: values['family_name'], 
        family_name_kana: values['family_name_kana'],
        given_name: values['given_name'],
        given_name_kana: values['given_name_kana'],
        email: values['email'],
        email_confirmation: values['email_confirmation'],
        postal_code: values['postal_code'],
        address_pref: values['address_pref'],
        address_city: values['address_city'],
        address_street: values['address_street'],
        address_room: values['adress_room'],
        tel: values['tel'],
        promotion_code: values['promotion_code'],
      }
      const payload: SpaceReservation = { ...state.reservationSpace.payload, ...{
        contractor: contractor
      }}
      ownDispatch(serverValidationClear())
      setIsDisplay(false)
      ownDispatch(reservationContractorAction(payload))
    }
  }  

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})      

      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation('CustomerInfoForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    initialValues: convertFormInitialValues<Contractor>(
      state.reservationSpace.payload.contractor
    ),
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    autoAddressLoad: autoAddressLoad
  };

  return <CustomerInfoForm {..._props} />

}
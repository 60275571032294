import { INTERNAL_SERVER_ERROR, NOT_FOUND_ERROR, AUTHENTICATION_ERROR } from '../actions/errorAction';
import { ActionTypes } from '../actions/actionTypes';

export const NoError = 'NO_ERROR';
export const NotFoundError = 'NOT_FOUND';
export const InternalServerError = 'INTERNAL_SERVER_ERROR';
export const AuthenticationError = 'AUTHENTICATION_ERROR';

export type ErrorType =
  | typeof NoError
  | typeof NotFoundError
  | typeof InternalServerError
  | typeof AuthenticationError;

export interface ErrorState {
  errorType: ErrorType;
  errorCode?: number;
  errorMessage?: string;
}

const initialState: ErrorState = {
  errorType: NoError,
};

const error = (
  state: ErrorState = initialState,
  action: ActionTypes,
): ErrorState => {
  switch (action.type) {
    case NOT_FOUND_ERROR:
      return {
        errorType: NotFoundError,
        errorCode: 404,
        errorMessage: action.message,
      };
    case INTERNAL_SERVER_ERROR:    
      return {
        errorType: InternalServerError,
        errorCode: 500,
        errorMessage: action.message,
      };
    case AUTHENTICATION_ERROR:
      return {
        errorType: AuthenticationError,
        errorCode: 401,
        errorMessage: action.message
      }
    default:
      return { errorType: NoError };
  }
};

export default error;

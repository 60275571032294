import React from 'react';
import {
  MuiThemeProvider,
  Theme,
  makeStyles,
} from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { theme } from '../materialui/theme';

export interface LoadingStyleProps {
  progress: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: 'auto',
      position: 'absolute',
    },
  })
);

interface OwnProps {
  size: number,
  style: LoadingStyleProps
}

export type LoadingComponentProps = OwnProps

const LoadingComponent: React.FC<LoadingComponentProps> = (props: LoadingComponentProps) => {
  const classes = useStyles(props.style);

  // MEMO: Wrap component by MuiThemeProvider again to ignore this bug. https://github.com/mui-org/material-ui/issues/14044
  return (
    <MuiThemeProvider theme={theme}>
      <CircularProgress
        color="primary"
        size={props.size}
        className={classes.progress}
      />
    </MuiThemeProvider>
  );
};

export default LoadingComponent;

import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { fetchMyInquiry, fetchMyReservation, fetchMyReservationList } from 'actions/userAction'
import { ListTableColumn } from '../components/ListTable/ListTable'
import UserReservationListPage from '../pages/UserReservationListPage'
import { ReservationHeader, SpaceReservation } from 'dataObjects/space'

interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

const columns: ListTableColumn[] = [
  { id: 'reserve_id', label: 'ID', minWidth: 50 },
  { 
    id: 'space_name', 
    label: 'スペース', 
    minWidth: 100,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 30) : value
  },
  {
    id: 'day',
    label: '利用日',
    minWidth: 80,
  },
  {
    id: 'start_time',
    label: '開始時間',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'end_time',
    label: '終了時間',
    minWidth: 50,
    align: 'center'
  },
  {
    id: 'updated_at',
    label: '予約受付日時',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'status',
    label: '予約状況',
    minWidth: 100,
    align: 'center',
    format: (value: string) => {
      switch(value) {
        case '0':
          return '仮予約'
        case '1':
          return 'お客様情報入力済み'
        case '2':
          return 'お支払い前'
        case '3':
          return '予約完了'
        case '4':
          return '決済エラー'
        case '9':
          return 'キャンセル済み'
        default:
          return ''
      }
    }
  }
];

export default function UserReservationPageContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(fetchMyReservationList())
  }, [])

  const _props = {
    columns: columns,
    data: state.userReservationList.data,
    total: state.userReservationList.total,
    page: state.userReservationList.current_page - 1,
    rowsPerPage: Object.keys(state.userReservationList.data).length,
    rowsPerPageOptions: [20, 50, 100, Object.keys(state.userReservationList.data).length],
    setPage: (page: number) => {
      dispatch(fetchMyReservationList(state.userReservationList.per_page, page))
    },
    setRowsPerPage: (perPage: number) => {
      dispatch(fetchMyReservationList(perPage, 0))
    },
    selectedRow: (data: any) => {
      const reserveData = data as SpaceReservation
      console.log({data})
      dispatch(fetchMyReservation(reserveData.reserve_id))
    }
  }

  return <UserReservationListPage {..._props} />

}
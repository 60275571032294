/* yyyy/mm/dd から Dateオブジェクトへ変換 */
export const toDate: (value: string, delimiter: string) => Date = (value, delimiter) => {
  if(value) {
    try{
      var arr = value.split(delimiter)
      return new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]))
    } catch (err) {
      console.log(err)
    }
  }
  return undefined  
}

export const toDateNonDelimiter: (value: string) => Date = (value) => {  
  if(value) {
    try{
      var arr = (value.substr(0, 4) + '/' + value.substr(4, 2) + '/' + value.substr(6, 2)).split('/');
      return new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]))
    } catch (err) {
      console.log(err)
    }
  }
  return undefined  
};

export const dateToString: (dt: Date) => string = (dt) => {
  let result = '';
  try {
    if(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth()+1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      result = y + "/" + m + "/" + d;
    }
  } catch (err) {
    if (typeof(dt) == 'string') {
      result = dt
    }
  }
  return result;
}
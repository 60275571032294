import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, InjectedFormProps, change } from 'redux-form'

import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';


import { renderTextField, renderRadio, renderFormHelper } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';
import { Typography } from '@material-ui/core';
import { AppState } from 'types/stateTypes';
import { Contractor } from 'dataObjects/space';
import { ValidationError } from 'errors/RequestValidationError';

interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  clearButton: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'
    },    
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    detail: {
      width: '100%'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    }, 
    clearButton: {
      color: '#222',
      backgroundColor: '#fcfcfc',
      border: '3px solid #555555',
      height: '50px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
      marginBottom: '50px'
    },
    addressSearchButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontSize: '1.4rem',
      height: '52px',
      marginTop: '16px'
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'family_name',
    'given_name',
    'family_name_kana',
    'given_name_kana',
    'email',
    'email_confirmation',
    'postal_code',
    'address_pref',
    'address_city',
    'address_street',    
    'tel',
  ]
  requiredFields.forEach(field => {
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })

  if(values['email'] !== values['email_confirmation']){
    errors['email_confirmation'] = '一つ目のメールアドレスと一致しません'
  }

  return errors
}

interface OwnProps {
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  autoAddressLoad: (change) => void
}

export type CustomerInfoFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>


const Form: React.FC<CustomerInfoFormPropsType> = ({ handleSubmit, pristine, submitting, reset, validationResult, ssValidation, autoAddressLoad, change, touch }) => {
  const classes = useStyles({} as StyleProps)

  if(validationResult && validationResult.status != '') {
    if(ssValidation) {
      ssValidation(validationResult.errors, touch)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <dl>
          <dt>氏名(カナ)</dt>
          <dd>
            <div>
              <Field
                name="family_name_kana"
                component={renderTextField}
                label="姓(カナ)"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Field
                name="given_name_kana"
                component={renderTextField}
                label="名(カナ)"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
          </dd>
        </dl>
        <dl>
          <dt>氏名</dt>
          <dd>
            <div>
              <Field
                name="family_name"
                autoComplete="family_name"
                component={renderTextField}
                label="姓"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Field
                name="given_name"
                autoComplete="given_name"
                component={renderTextField}
                label="名"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
          </dd>
        </dl>
        <dl style={{ height: '220px'}}>
          <dt>メールアドレス</dt>
          <dd>
            <div>
              <Field
                name="email"
                autoComplete="email"
                component={renderTextField}
                label="メールアドレス"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
              <Field
                name="email_confirmation"
                autoComplete="email_confirmation"
                component={renderTextField}
                label="メールアドレス(確認)"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
        <dl style={{height: '500px'}}>
          <dt>住所</dt>
          <dd>
            <div>
              <Field
                name="postal_code"
                autoComplete="postal-cod"
                component={renderTextField}
                label="郵便番号"
                rows="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Button onClick={(event) => autoAddressLoad(change)} className={classes.addressSearchButton} >
                住所自動入力
              </Button>
            </div>
            <div>
              <Field
                name="address_pref"
                autoComplete="address-level1"
                component={renderTextField}
                label="都道府県"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
            <div>
              <Field
                name="address_city"
                autoComplete="address-level2"
                component={renderTextField}
                label="市区町村"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
            <div>
              <Field
                name="address_street"
                autoComplete="street-address"
                component={renderTextField}
                label="番地"
                multiline
                rowsMax="2"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField80}
              />
            </div>
            <div>
              <Field
                name="address_room"
                autoComplete="address-level4"
                component={renderTextField}
                label="建物・部屋番号"
                multiline
                rowsMax="2"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField80}
              />
            </div>
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <div>
              <Field
                name="tel"
                component={renderTextField}
                label="電話番号"
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
      </div>

      <Typography variant="h3" className={classes.subtitle}>
        プロモーションコードをお持ちの方は入力してください。
      </Typography>

      <div className={classes.formContainer}>
        <dl>
          <dt>プロモーションコード</dt>
          <dd>
            <div style={{ textAlign: 'center' }}>
              <Field
                name="promotion_code"
                component={renderTextField}
                label="プロモーションコード"
                rows="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
      </div>

      <Grid container spacing={2} justify="center">
        <Grid item xs={6}>
          <Button type="submit" disabled={submitting} fullWidth className={classes.submitButton}>
            確認
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} justify="center">
        <Grid item xs={6}>
          <Button type="button" fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={reset}>
            クリア
          </Button>
        </Grid>
      </Grid>

    </form>    
  )
}

const CustomerInfoForm = reduxForm({
  form: 'CustomerInfoForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)


export default CustomerInfoForm
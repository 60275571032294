import {createMuiTheme} from '@material-ui/core/styles'
import { jaJP } from '@material-ui/core/locale';

        
export const theme = createMuiTheme({  // #1
  palette: {
    primary: {
      light: '#f6ffb1',
      main: '#27A78E',
      dark: '#90a553',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#C7FBD3',
      main: '#29B2A9',
      dark: '#2F7965',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontWeight: 600
      },
      body1: {
        fontSize: '1.3rem',
        fontFamily: "'Noto Sans JP', '游ゴシック体', YuGothic, 'Yu Gothic Medium', sans-serif",
        fontWeight: 400,
        lineHeight: 1.3,
        letterSpacing: '0.00938em'
      },
      body2: {
        fontSize: '1.2rem',
        fontFamily: "'Noto Sans JP', '游ゴシック体', YuGothic, 'Yu Gothic Medium', sans-serif",
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em'
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.3rem',
      }
    },    
    MuiStepIcon: {
      text: {
        fontSize: '1.7rem'
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: '2.0rem'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '1.6rem'
      }
    }
  }
}, jaJP)


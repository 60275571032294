import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { push, CallHistoryMethodAction } from "connected-react-router"
import { routes } from '../routes/Route'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import SpaceCustomerInfoPage, { SpaceCustomerInfoPageProps } from '../pages/SpaceCustomerInfoPage'
import { Values } from '../types/formTypes'
import { observeShownAction } from 'actions/pagingAction';
import { dateToString } from 'utils/convertor';
import { fetchSettings } from 'actions/settingsAction';
import { fetchMasterAction } from 'actions/masterAction';

interface OwnProps {
}

export type SpaceCustomerInfoPageContainerProps = OwnProps & RouteComponentProps<{ space_id?: string }>

const appStateSelector = (state: AppState) => state


export default function SpaceCustomerInfoPageContainer(props: SpaceCustomerInfoPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch()
      
  // トランザクションIDがなければ時間予約まで画面を戻す
  useEffect(() => {    
    dispatch(fetchSettings())
    dispatch(fetchMasterAction()) 

    if(!state.reservationSpace.payload.reserve_id) {
      dispatch(push(routes.spaceSchedule.getPath(props.match.params.space_id)))
    }
    dispatch(observeShownAction());
  }, []);


  const { enquete } = state.reservationSpace.payload

  let usage_name;
  if(state.viewingSpace.space_info.usage && enquete.usage) {
    const selUsage = state.viewingSpace.space_info.usage.find(u => u.id === enquete.usage.id)
    if(selUsage) {
      usage_name = selUsage.usage
    }
  }
  let timeRange;
  if(state.reservationSpace.payload.reserveTimeRange) {
    timeRange = state.reservationSpace.payload.selectedDate.toString();
  }

  const _props: SpaceCustomerInfoPageProps = {
    space_id: parseInt(props.match.params.space_id),
    space_name: state.viewingSpace.space_info.space_name,
    users_adult: enquete.users_adult ? enquete.users_adult : null,
    users_children: enquete.users_children ? enquete.users_children: null,
    usage: usage_name,
    detail: enquete.detail,
    timerange: timeRange,
    selectedPeriods: state.reservationSpace.payload.selectedPeriods,
    invoice: state.reservationSpace.payload.invoice,
    onBack: () => {
      dispatch(push(routes.spaceSchedule_exact.getPath(state.reservationSpace.payload.space_id, state.reservationSpace.payload.selectedDate)))
    }
  }

  return <SpaceCustomerInfoPage {..._props} />

}
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { observeShownAction } from '../actions/pagingAction'
import SignUpCompletePage from '../pages/SignUpCompletePage'

interface OwnProps {
}

export type SignUpCompletePageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function SignUpCompletePageContainer(props: SignUpCompletePageContainerProps) {
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);

  return <SignUpCompletePage />

}
import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { routes } from '../routes/Route';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { SnackBarAction } from './actionTypes';
import { ErrorActions } from './errorAction';

import { AuthenticatedUser, AuthRes, AfterSignInRedirectRoute } from 'dataObjects/auth';
import { AxiosResponse } from 'axios';
import { Errors } from 'types/formTypes';
import { ReservationValidationErrorListState } from 'reducers/validationErrorReducer';
import { CookieSetOptions } from 'universal-cookie';
import getCookieByName from 'utils/cookieUtil';
import { ajaxErrorHandler } from 'actionHelper/ajaxErrorHandler';

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_INVALID = "LOGIN_INVALID";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const AFTER_SIGNIN_REDIRECT_ROUTE_SET = 'AFTER_SIGNIN_REDIRECT_ROUTE_SET';
export const AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR = 'AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR';

export const USER_REGISTER_START = 'USER_REGISTER_START';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';


/* --------------------------------------------------------------------------------- */

/* ユーザー登録 開始 Action */
export interface UserRegisterStartAction
  extends Action<typeof USER_REGISTER_START> { }

const userRegisterStartAction = (): UserRegisterStartAction => {
  return {
    type: USER_REGISTER_START,
  }
}

/* ユーザー登録 成功 Action */
export interface UserRegisterSuccessAction
  extends Action<typeof USER_REGISTER_SUCCESS> {
  payload: AuthenticatedUser
}

const userRegisterSuccessAction = (payload: AuthenticatedUser)
  : UserRegisterSuccessAction => {
  return {
    type: USER_REGISTER_SUCCESS,
    payload,
  }
}

/* ユーザー登録 失敗 Action */
export interface UserRegisterFailAction
  extends SnackBarAction<typeof USER_REGISTER_FAIL> {
}

const userRegisterFailAction = (
  message: string
  ): UserRegisterFailAction => {
  return {
    type: USER_REGISTER_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
};


export type UserRegisterActions =
  | UserRegisterStartAction
  | UserRegisterSuccessAction
  | UserRegisterFailAction
  | ErrorActions
  | CallHistoryMethodAction;

type UserRegisterThunkResult<R> = ThunkAction<R, AppState, undefined, UserRegisterActions>;


export function userRegisterAction(
  name: string,
  email: string,
  password: string,
  password_confirmation: string
): UserRegisterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, UserRegisterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      const response: AxiosResponse<any> = await AppClient.post(
        process.env.REACT_APP_API_SERVER_HOST + 'api/auth/signup',
        null,
        {
          name: name,
          email: email,
          password: password,
          password_confirmation: password_confirmation
        }
      )
  
      const body: AuthRes = await response.data;      

      dispatch(
        userRegisterSuccessAction({
          grantType: 'password',
          id: body.user.id,
          email: body.user.email,
          role: body.user.role,
          access_token: body.access_token,
          expires_in: body.expires_in,
          name: body.user.name
        }),
      );
      
      dispatch(push(routes.signUpComplete.path));

    } catch(err) {
      dispatch<UserRegisterActions>(
        await ajaxErrorHandler(err, userRegisterFailAction),
      )      
    }
  }
}


/* --------------------------------------------------------------------------------- */

export type AuthActions =
  | LoginStartAction
  | LoginSuccessAction
  | LoginInvalidAction
  | LoginFailAction
  | ErrorActions
  | CallHistoryMethodAction;

type AuthThunkResult<R> = ThunkAction<R, AppState, undefined, AuthActions>;


export function sharealLoginAction(
  email: string,
  password: string,
  validationCallback?: (errors: Errors) => void
): AuthThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, AuthActions>,
    getState: () => AppState) => {
    try {
      dispatch(loginStartAction())
      
      const state = getState();
      const response: AxiosResponse<any> = await AppClient.post(
        process.env.REACT_APP_API_SERVER_HOST + 'api/auth/login',
        null,
        {
          email: email,
          password: password
        }
      )
         
      const body: AuthRes = await response.data;      
      dispatch(
        loginSuccessAction({
          grantType: 'password',
          id: body.user.id,
          email: body.user.email,
          role: body.user.role,
          access_token: body.access_token,
          expires_in: body.expires_in,
          name: body.user.name
        }),
      )
      
      const nextRoute = state.authRedirectRoute.route
      dispatch(push(nextRoute));
    } catch(err) {
        dispatch(loginFailAction(err.message));
    }
  }
}

const getRndStr = () => {
  var N = 16;
  return btoa(
    String.fromCharCode(...crypto.getRandomValues(new Uint8Array(N)))
  ).substring(0, N);
}

export function socialLoginAction(provider: string, 
  cookies: {[name: string]: any}, setCookie: (name: string, value: any, options?: CookieSetOptions) => void): AuthThunkResult<void> {
  return (dispatch: ThunkDispatch<AppState, any, AuthActions>,
    getState: () => AppState) => {
    const state = getState();
    
    dispatch(loginStartAction())

    let state_token = getRndStr();
    setCookie("social_login_state", state_token);

    // アプリケーションIDの動的セット
    const settings = {
      facebook: {
        client_id: state.settings.oauth.facebook.client_id,
        url: () => {
          let url = "https://www.facebook.com/v5.0/dialog/oauth?";
          url += "client_id=" + state.settings.oauth.facebook.client_id + "&";
          url += "redirect_uri=" + state.settings.oauth.facebook.redirect + "&"
          url += "response_type=code&";
          url += "scope=email&";
          url += "state=" + state_token
          return url
        }
      },
      twitter: {
        client_id: state.settings.oauth.twitter.client_id,
        url: () => {
          let url = "https://www.facebook.com/v5.0/dialog/oauth?"
          url += "client_id=" + state.settings.oauth.twitter.client_id + "&"
          url += "redirect_uri=" + state.settings.oauth.twitter.redirect + "&"
          url += "response_type=code&";
          url += "scope=email&"
          url += "state=" + state_token
          return url
        }
      },
      google: {
        client_id: state.settings.oauth.google.client_id,
        url: () => {
           let url = "https://accounts.google.com/o/oauth2/auth?"
           url += "client_id=" + state.settings.oauth.google.client_id + "&";
           url += "response_type=code&"
           url += "scope=email%20profile&"
           url += "redirect_uri=" + state.settings.oauth.google.redirect + "&"
           url += "state=" + state_token
           return url
        }
      },
      redirect_uri: state.settings.oauth.google.redirect
    };

    // 別windowsで認証
    const loginWindow = window.open(settings[provider].url());
    setTimeout(function() {
      checkSocialLoginStatus(loginWindow, cookies, provider, state, dispatch);
    }, 1000);
    loginWindow.focus();

    
  }
}

const checkSocialLoginStatus = (loginWindow, cookies, provider, state, dispatch) => {
  if (loginWindow.closed) {
    try{
      const api_token_string = getCookieByName('api_token')
      if(api_token_string != '') {
        const api_token = JSON.parse(getCookieByName('api_token'))

        if (api_token) {
          // 遷移
          dispatch(
            loginSuccessAction({
              grantType: "social",
              id: api_token.user.id,
              email: api_token.user.email,
              role: api_token.user.role,
              access_token: api_token.access_token,
              expires_in: api_token.expires_in,
              name: api_token.user.name
            })
          );
          const nextRoute = state.authRedirectRoute.route;
          dispatch(push(nextRoute));
        } else {
          // 認証エラー
          dispatch(
            loginInvalidAction({
              message: "ソーシャルログイン失敗",
              errors: [
                {
                  item: provider + ".login",
                  error: "ユーザー自身が認証を拒否したか、ソーシャルプロバイダーから認証拒否されました"
                }
              ]
            })
          );
        }
      } else {
        if(provider === 'facebook'){
          // 認証エラー
          dispatch(
            loginInvalidAction({
              message: "ソーシャルログイン失敗",
              errors: [
                {
                  item: "facebook.login",
                  error: "新規ユーザー登録を停止しています"
                }
              ]
            })
          )
        } else {
          // 認証エラー
          dispatch(
            loginInvalidAction({
              message: "ソーシャルログイン失敗",
              errors: [
                {
                  item: provider + ".login",
                  error: "ユーザー自身が認証を拒否したか、ソーシャルプロバイダーから認証拒否されました"
                }
              ]
            })
          )
        }
      }
    } catch(e) {
      // 認証エラー
      dispatch(
        loginInvalidAction({
          message: "ソーシャルログイン失敗",
          errors: [
            {
              item: provider + ".login",
              error: "認証処理でエラーが発生しました"
            }
          ]
        })
      );
    }
  } else {
    // チェック継続
    setTimeout(function() {
      checkSocialLoginStatus(loginWindow, cookies, provider, state, dispatch);
    }, 1000);
  }
};


export interface LoginStartAction {
  type: typeof LOGIN_START;
}

export function loginStartAction(): LoginStartAction {
  return {
    type: LOGIN_START
  };
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: AuthenticatedUser;
}

export function loginSuccessAction(newAuthState: AuthenticatedUser): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    payload: newAuthState,
  };
}

export interface LoginFailAction extends SnackBarAction<typeof LOGIN_FAIL> {}

export function loginFailAction(error: string): LoginFailAction {
  return {
    type: LOGIN_FAIL,
    snackBarMessage: error,
    variant: 'error',
  };
}


export interface LoginInvalidAction
  extends Action<typeof LOGIN_INVALID> {
  payload: ReservationValidationErrorListState;
}

const loginInvalidAction = (
  payload: ReservationValidationErrorListState
): LoginInvalidAction => {
  return {
    type: LOGIN_INVALID,
    payload
  };
};

/* --------------------------------------------------------------------------------- */

export type AuthRedirectActions =
  | AfterSignInRedirectRouteSetAction
  | AfterSignInRedirectRouteClearAction
  | CallHistoryMethodAction

export interface AfterSignInRedirectRouteSetAction {
  type: typeof AFTER_SIGNIN_REDIRECT_ROUTE_SET
  payload: AfterSignInRedirectRoute
}

export function afterSignInRedirectRouteSetAction(newState: AfterSignInRedirectRoute): AfterSignInRedirectRouteSetAction {
  return {
    type: AFTER_SIGNIN_REDIRECT_ROUTE_SET,
    payload: newState,
  };
}



export interface AfterSignInRedirectRouteClearAction {
  type: typeof AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR
}

export function afterSignInRedirectRouteClearAction(): AfterSignInRedirectRouteClearAction {
  return {
    type: AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR
  };
}


/* --------------------------------------------------------------------------------- */

export type AuthLogOutActions = 
  | LogOutSuccessAction
  | CallHistoryMethodAction

type AuthLogOutThunkResult<R> = ThunkAction<R, AppState, undefined, AuthLogOutActions>;


export interface LogOutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export function logOutAction(): LogOutSuccessAction {
  return {
    type: LOGOUT_SUCCESS
  }
}

export function logout() : AuthLogOutThunkResult<void> {
  return (dispatch: ThunkDispatch<AppState, any, AuthLogOutActions>) => {

    dispatch(logOutAction())
    const nextRoute = routes.signOut.path
    dispatch(push(nextRoute))
  }
}


/* --------------------------------------------------------------------------------- */

/* ユーザー登録 開始 Action */
export interface ResetPasswordStartAction
  extends Action<typeof RESET_PASSWORD_START> { }

const resetPasswordStartAction = (): ResetPasswordStartAction => {
  return {
    type: RESET_PASSWORD_START,
  }
}

/* ユーザー登録 成功 Action */
export interface ResetPasswordSuccessAction
  extends Action<typeof RESET_PASSWORD_SUCCESS> {
  payload: string
}

const resetPasswordSuccessAction = (payload: string)
  : ResetPasswordSuccessAction => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  }
}

/* ユーザー登録 失敗 Action */
export interface ResetPasswordFailAction
  extends SnackBarAction<typeof RESET_PASSWORD_FAIL> {
}

const resetPasswordFailAction = (
  message: string
  ): ResetPasswordFailAction => {
  return {
    type: RESET_PASSWORD_FAIL, snackBarMessage: message,
    variant: 'error'};
};


export type ResetPasswordActions =
  | ResetPasswordStartAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailAction
  | CallHistoryMethodAction;

type ResetPasswordThunkResult<R> = ThunkAction<R, AppState, undefined, ResetPasswordActions>;


export function beginResetPassword(
  email: string
): ResetPasswordThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ResetPasswordActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      const response: AxiosResponse<any> = await AppClient.post(
        process.env.REACT_APP_API_SERVER_HOST + 'api/auth/password/email',
        null,
        {
          email: email
        }
      )

      const body: string = await response.data;      

      dispatch(
        resetPasswordSuccessAction(body),
      );
      
      dispatch(push(routes.forgotPasswordSend.path));

    } catch(err) {
      dispatch(resetPasswordFailAction(err.message));
    }
  }
}
import * as React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { ReservationValidationErrorListState } from '../../reducers/validationErrorReducer'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    container: {
      fontSize: '1.3rem',
      lineHeight: 1.4,
    },    
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '30px',
      marginBottom: '5px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    
  })
)


interface OwnProps {
  validationErrorDialogOpen: boolean,
  validationResult?: ReservationValidationErrorListState,
  handleClose: () => void
}

type ValidationErrorDialogProps = OwnProps

/**
 * エラーメッセージ項目対比表
 */
const itemDictionary = {
  'google.login': 'Googleログイン',
  'facebook.login': 'facebookログイン',
  'sociallogin': 'ソーシャルログイン処理',
  'login': 'ログイン処理',
  'exists auth user': 'ログインユーザー',
  'parameter': 'システム',
  'existsPeriod': '予約時間',
  'doesnotdata': '内部データ',  
  'period.continuous': '予約時間',
  'period.at_least_reserve_unit': '予約時間',
  'period.before_5minutes': '予約時間',
  'canReserve': '予約可否',
  'canEveryDayReserve': '日次スケジュール予約可否',
  'canBiWeeklyReserve': '週次スケジュール予約可否',
  'canMonthlyReserve': '月次スケジュール予約可否',
  'canSpecificReserve': '指定日スケジュール予約可否',  
  'payment.status': '決済処理状況',
  'payment.exists': '決済情報',
  'reserve.exists': '予約情報',
  'reserve.status': '予約状況',
  'reserve.promotioncode': 'プロモーションコード'
}

const ValidationErrorDialog: React.FC<ValidationErrorDialogProps> = (props: ValidationErrorDialogProps) => {
  const classes = useStyles({})

  const convertErrorItemName = (item: string) => {
    try {
      return itemDictionary[item]
    } catch (ex) {
      return ''
    }
  }

  return (
    <Dialog
      open={props.validationErrorDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <DialogTitle id="alert-dialog-title">処理が中断しました</DialogTitle>
      <DialogContent dividers style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>
          <ErrorIcon style={{ width: '30px', height: '30px', color: '#AA0000'}} />
        </div>
        <div>
          <ul>
            {
              props.validationResult ? props.validationResult.errors.map(err => {
                return (
                  <li>{convertErrorItemName(err.item)}<br />{err.error}</li>
                )
              }) : null
            }
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus className={classes.submitButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default ValidationErrorDialog
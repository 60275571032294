import * as React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    container: {
      fontSize: '1.3rem',
      lineHeight: 1.4,
    },    
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '30px',
      marginBottom: '5px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    
  })
)


interface OwnProps {
  internalErrorDialogOpen: boolean,  
  handleClose: () => void
}

type InternalErrorDialogProps = OwnProps


const InternalErrorDialog: React.FC<InternalErrorDialogProps> = (props: InternalErrorDialogProps) => {
  const classes = useStyles({})

  return (
    <Dialog
      open={props.internalErrorDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.container}
    >
      <DialogTitle id="alert-dialog-title">処理が中断しました</DialogTitle>
      <DialogContent dividers style={{display: 'flex'}}>
        <div style={{marginRight: '5px'}}>
          <ErrorIcon style={{ width: '30px', height: '30px', color: '#AA0000'}} />
        </div>
        <div>
          <span>システムエラーが発生しました</span><br />
          <span>処理を中断します。</span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} autoFocus className={classes.submitButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default InternalErrorDialog
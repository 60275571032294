import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_REGULAR_SCHEDULE_LIST_START,
  FETCH_REGULAR_SCHEDULE_LIST_SUCCESS,
  FETCH_REGULAR_SCHEDULE_LIST_FAIL,
  FETCH_REGULAR_INFO_LIST_START,
  FETCH_REGULAR_INFO_LIST_SUCCESS,
  FETCH_REGULAR_INFO_LIST_FAIL,
  SHOW_SCHEDULE_EVENT
} from '../actions/regularAction'
import { RegularEvent, RegularEventInfo } from 'dataObjects/space';


export interface RegularEventListState {
  body: RegularEvent[],
  schedule_from: Date,
  schedule_to: Date,
  view: string,
  defdate: Date
}

const initialRegularEventListState: RegularEventListState = {
  body: [],
  schedule_from: undefined,
  schedule_to: undefined,
  view: 'agenda',
  defdate: new Date((new Date()).setDate(1))
};

/* 定期利用イベントスケジュールリスト Reducer */
export const regularEventList = (
  state: RegularEventListState = initialRegularEventListState,
  action: ActionTypes,
): RegularEventListState => {
  switch (action.type) {
    case FETCH_REGULAR_SCHEDULE_LIST_START:
      return { ...state };
    case FETCH_REGULAR_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,        
        ...action.payload        
      }
    case FETCH_REGULAR_SCHEDULE_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};


export interface RegularEventInfoListState {
  list: RegularEventInfo[]
}

const initialRegularEventInfoListState: RegularEventInfoListState = {
  list: []
};

/* 定期利用イベント情報リスト Reducer */
export const regularEventInfoList = (
  state: RegularEventInfoListState = initialRegularEventInfoListState,
  action: ActionTypes,
): RegularEventInfoListState => {
  switch (action.type) {
    case FETCH_REGULAR_INFO_LIST_START:
      return { ...state };
    case FETCH_REGULAR_INFO_LIST_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ]
      }
    case FETCH_REGULAR_INFO_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};


const initialCalendarEvent: RegularEvent = {
  reserve_id: undefined,
  use_type: '1',
  name: '',
  start: undefined,
  end: undefined,
  title: ''  
} 

export const showEvent = (
  state: RegularEvent = initialCalendarEvent,
  action: ActionTypes,
): RegularEvent => {
  switch (action.type) {
    case SHOW_SCHEDULE_EVENT:
      return {
        ...state,      
        ...action.payload        
      }
    default:
      return { ...state };
  }
};


import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import SignUpContainer from '../containers/SignUpContainer'

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(10),
      height: '100%'
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    }
  })
);

interface OwnProps {

}

export type SignUpPageProps = OwnProps;

const SignUpPage: React.FC<SignUpPageProps> = (props: SignUpPageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <BasePageContainer>
      <Container fixed className={classes.topcontainer}>
      
        <SignUpContainer />

      </Container>
    </BasePageContainer>

  );

}

export default SignUpPage
import * as React from 'react';
import { Snackbar, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { amber, green } from '@material-ui/core/colors';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { theme } from '../../materialui/theme'

interface StyleProps {
  text: BaseCSSProperties,
  close: BaseCSSProperties,
  icon: BaseCSSProperties,
  success: BaseCSSProperties,
  error: BaseCSSProperties
}


const useStyles = makeStyles((theme: Theme) => ({
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    padding: theme.spacing(0.5),
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
}));

export type SnackBarVariant = 'success' | 'error' | 'warning' | 'info';

type Props = {
  open: boolean;
  variant: SnackBarVariant;
  message?: string;
  handleClose: (event: React.MouseEvent) => void;
};

const getIcon = (
  variant: SnackBarVariant,
): typeof CheckCircleIcon | typeof ErrorIcon | typeof WarningIcon | typeof InfoIcon => {
  switch (variant) {
    case 'success':
      return CheckCircleIcon;
    case 'error':
      return ErrorIcon;
    case 'warning':
      return WarningIcon;
    case 'info':
      return InfoIcon;
    default:
      return CheckCircleIcon;
  }
};

const SnackBar: React.FC<Props> = ({ open, variant, message, handleClose }) => {
  const classes = useStyles({} as StyleProps);

  const handleOnClose = (event: React.SyntheticEvent, reason: string) => {
    return handleClose(event as React.MouseEvent);
  };
  const Icon = getIcon(variant);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleOnClose}
    >
      <SnackbarContent
        className={classes[variant]}
        message={
          <span className={classes.text}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export { SnackBar };

import React from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )

export const renderDateCalendarField = ({
         id,
         label,
         input: { value, onChange },
         meta: { touched, invalid, error },
         onFieldChange,
         ...custom
       }) => (
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <KeyboardDatePicker
             disableToolbar
             format="yyyy/MM/dd"
             margin="normal"
             id={id}
             label={label}
             clearable
             autoOk
             variant="inline"
             inputVariant="outlined"
             InputAdornmentProps={{ position: "start" }}
             value={value}
             error={touched && invalid}
             helperText={touched && error}
             onChange={date => {
               onChange(date);
               onFieldChange && onFieldChange(date);
             }}
             {...custom}
           />
         </MuiPickersUtilsProvider>
       );

export const renderTimeField = ({
  id,
  label,
  input: { value, onChange },
  meta: { touched, invalid, error },
  onFieldChange,
  ...custom
}) => (    
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        margin="normal"        
        id={id}
        label={label}
        clearable
        autoOk
        ampm={false}
        value={value}
        error={touched && invalid}
        helperText={touched && error}
        onChange={(date) => {
          onChange(date)
          onFieldChange && onFieldChange(date)
        }}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  )


export const renderRadio = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, error },
  onFieldChange,
  row = true,
  required = false,
  rootClass = '',
}) => (
    <FormControl classes={{ root: rootClass }} required={required} component='fieldset' error={!!(touched && error)}>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup
        row={row}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
          onFieldChange && onFieldChange(e.target.value)
        }}
      >
        {children}
      </RadioGroup>
      {touched && error && <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText>}
    </FormControl>
  )

export const renderCheckbox = ({ input, label, meta: { touched, invalid, error }, required = false, }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          required={required}
        />
      }
      label={label}
    />
    {touched && error && <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText>}
  </div>
)

// CheckBoxGroup
export const renderCheckBoxGroup = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, error },
  onFieldChange,
  row = true,
  required = false,
  rootClass = '',
}) => (
  <FormControl classes={{root: rootClass}} required={required} component='fieldset' error={!!(touched && error)}>
    <FormLabel component='legend'>{label}</FormLabel>
    <FormGroup
      row={row}
      onChange={(e) => {
        onChange((e.target as HTMLInputElement).value)
        onFieldChange && onFieldChange((e.target as HTMLInputElement).value)
      }}
    >
      {children}
    </FormGroup>
    {touched && error && <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText>}
  </FormControl>
  )

// Switch
export const renderSwitch = ({
  input: { value, onChange },
  label,
  onFieldChange,
  rootClass = '',
}) => (
  <FormControlLabel
    classes={{root: rootClass}}
    control={
      <Switch
        checked={value}
        onChange={(e, bool) => {
          onChange(bool)
          onFieldChange && onFieldChange(bool)
        }}
      />
    }
    label={label}
  />
)

// File
export const renderFile = ({
  input: { value, name, onChange },
  label,
  meta: { touched, error },
  classes,
  onFieldChange,
  buttonLabel,
  accept = '*',
  required = false,
  rootClass = '',
}) => (
  <FormControl classes={{root: rootClass}} required={required} component='fieldset' error={!!(touched && error)}>
    <FormLabel component='legend'>{label}</FormLabel>
    <input
      accept={accept}
      className={classes.input}
      id={name}
      type='file'
      onChange={e => {
        e.preventDefault()
        onChange(e.target.files[0])
        onFieldChange && onFieldChange(e.target.files[0])
      }}
      onBlur={() => {}}
    />
    <label htmlFor={name}>
      <Button classes={{root: classes.button}} variant='outlined' component='span'>
        {buttonLabel || 'ファイルを選択'}
      </Button>
    </label>
    <label>{value && value.name}</label>
    {touched && error && <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText>}
  </FormControl>
)

export const renderSelect = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, error },
  onFieldChange,
  required = false,
  rootClass = '',
}) => (
    <TextField
      required={required}
      classes={{ root: rootClass }}
      select
      label={label}
      variant='outlined'
      value={value}
      onChange={e => {
        onChange(e.target.value)
        onFieldChange && onFieldChange(e.target.value)
      }}
      helperText={touched && error}
      style={{ marginTop: '16px', marginBottom: '8px' }}
    >
      {children}
    </TextField>
  )


export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return <></>
  } else {
    return <FormHelperText style={{color: '#f44336'}}>{touched && error}</FormHelperText>
  }
}

import { ActionTypes } from '../actions/actionTypes'
import {
  SHOULD_SHOW_RESTRICT_DIALOG,
  DISABLE_RESTRICT_DIALOG,
  SHOW_RESTRICT_DIALOG,
  CLOSE_RESTRICT_DIALOG
} from '../actions/browserRestrictActions'


export interface BrowserRestrictDialogState {
  shouldShowRestrictDialog: boolean,
  isOpen: boolean
}

const initialBrowserRestrictDialogState: BrowserRestrictDialogState = {
  shouldShowRestrictDialog: true,
  isOpen: false
};

/* browserRestrict Reducer */
const browserRestrict = (
  state: BrowserRestrictDialogState = initialBrowserRestrictDialogState,
  action: ActionTypes,
): BrowserRestrictDialogState => {
  switch (action.type) {
    case SHOULD_SHOW_RESTRICT_DIALOG: 
      return { ...state, shouldShowRestrictDialog: true };
    case DISABLE_RESTRICT_DIALOG:
      return { ...state, shouldShowRestrictDialog: false };   
    case SHOW_RESTRICT_DIALOG:
      return { ...state, isOpen: true };
    case CLOSE_RESTRICT_DIALOG:
      return { ...state, isOpen: false };
    default:
      return { ...state };
  }
};

export default browserRestrict
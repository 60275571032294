import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from '../routes/Route';
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { observeShownAction } from '../actions/pagingAction'
import { fetchNews } from '../actions/newsAction';
import NewsPage, { NewsPageProps } from '../pages/NewsPage'

interface OwnProps {
}

export type NewsPageContainerProps = OwnProps & RouteComponentProps<{ news_id?: string }>

const appStateSelector = (state: AppState) => state

export default function NewsPageContainer(props: NewsPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(fetchNews(props.match.params.news_id))
    dispatch(observeShownAction());
  }, []);

  const _props: NewsPageProps = {
    news: state.news,
    onBack: () => {
      dispatch(push(routes.top.path))
    }
  }

  return <NewsPage {..._props} />

}

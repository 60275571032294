import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import TermsOfServicePage from '../pages/TermsOfServicePage'
import { observeShownAction } from '../actions/pagingAction'


const appStateSelector = (state: AppState) => state

export default function TermsOfServicePageContainer() {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(observeShownAction());
  }, [])

  return <TermsOfServicePage />

}

import * as React from 'react';
import useReactRouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import CustomizedTables from '../components/Space/CustomizedTable'
import TwoWeeksCalendar, { TwoWeeksCalendarProps } from '../components/Space/TwoWeeksCalendar'
import FacilitiesBoard from '../components/Space/FacilitiesBoard'
import { SpaceInfo } from 'dataObjects/space';
import GoogleApiWrapper from '../components/Space/GoogleMap'
import Slider from "react-slick";
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../SlickSlider.css';
import BrowserRestrictionNotifyDialogContainer from 'containers/BrowserRestrictionNotifyDialogContainer';

interface StyleProps {
  container: BaseCSSProperties,
  imagegallery_parent: BaseCSSProperties,
  imagegallery_container: BaseCSSProperties,
  paper: BaseCSSProperties,
  img: BaseCSSProperties,
  button: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  sentense: BaseCSSProperties,
  description: BaseCSSProperties,
  buttonGrid: BaseCSSProperties,
  scheduleButton: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    imagegallery_parent: {
      width: '100%',      
      marginTop: theme.spacing(2),  
      backgroundSize: 'cover'    
    },
    imagegallery_container: {
      width: '80%', 
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    img: {
      width: '70%',
    },
    button: {
      margin: theme.spacing(1),
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    sentense: {
      marginTop: theme.spacing(1),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
    },
    prohibited_desc: {
      marginTop: theme.spacing(1),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(1),
    },
    buttonGrid: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    mapGrid: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: '100%',
      height: '350px'
    },
    submitButton: {
      color: '#FFF',
      backgroundColor: '#26A78E',
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    }, 
    access_info_container: {
      display: 'flex',
      flexDirection: 'row',
      '& dl': {
        display: 'flex',
        flexDirection: 'column',
        '& dt': {
          marginRight: theme.spacing(2),
        },
        '& dd': {
          marginRight: theme.spacing(2),
        }
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        '& dl': {
          display: 'flex',
          flexDirection: 'column',
          '& dt': {
            marginRight: theme.spacing(2),
          },
          '& dd': {
            marginBottom: theme.spacing(2),
          }
        }
      }
    },
    faq_container :{
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      '& dl': {
        display: 'flex',
        flexDirection: 'column',
        '& dt': {
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(1),
        },
        '& dd': {
          marginBottom: theme.spacing(8),
        }
      }
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'center',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },

  })
);

interface OwnProps {
  space_id: number,
  spaceInfo: SpaceInfo
}

interface SpacePageActions {
  linkTo: (route: string) => void
  onClickDate: (day: string) => void
  initSlider: (slider: Slider) => void
}


export type SpacePageProps = OwnProps & TwoWeeksCalendarProps & SpacePageActions;



const SpacePage: React.FC<SpacePageProps> = (props: SpacePageProps) => {
  const classes = useStyles({} as StyleProps);
  const { history, location, match } = useReactRouter();

  // Set TwoWeeksCalendarProps
  const twoweeksprops: TwoWeeksCalendarProps = {
    days: props.days,
    space_id: props.space_id,
    onClickDate: props.onClickDate
  }

  const prohibited_matter = props.spaceInfo.prohibited_matter.map(pro => {
    return <li><Typography variant="body1" className={classes.prohibited_desc}>{pro}</Typography></li>
  })

  const faq = props.spaceInfo.faq.map(qa => {
    return <dl><dt>Q. {qa.question}</dt><dd>A. {qa.answer}</dd></dl>
  })

  const images = props.spaceInfo.image_info.map(img => {       
    return {
        original_url: `${process.env.REACT_APP_API_SERVER_HOST}storage/images/space/${props.space_id}/${img.original_url}`,
        thumbnail_url: `${process.env.REACT_APP_API_SERVER_HOST}storage/images/space/${props.space_id}/${img.thumbnail_url}`,
        order: img.order
    }
  })

  const slick_settings = {
    customPaging: function(i) {
      const active = images[i].order == 1 ? 'slick-active' : ''
      return (
        <a>
          <img src={images[i].thumbnail_url} className={active} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    
  };
  const slick_settings_smartphone = {
    customPaging: function(i) {
      const active = images[i].order == 1 ? 'slick-active' : ''
      return (
        <a>
          <img src={images[i].thumbnail_url} className={active} />
        </a>
      );
    },
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const SomeText = ({ text }) => <div>{ text }</div>;
  const [size, setSize] = React.useState({ 'width': window.innerWidth, 'height': window.innerHeight });
  window.addEventListener('resize', function () {
    setSize({ 'width': window.innerWidth, 'height': window.innerHeight });
  }, true);
  const result = size.width > 375 * 2 ? true : false
    
  return (
    /* スペースタイトル 
      メイン写真 
      紹介文 
      広さ 
      住所 
      アクセス方法 
      地図 
      利用用途 
      設備 
      入退室方法 
      禁止事項 */
    <BasePageContainer>
      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>
          <CustomizedBreadcrumbs />
          <Typography variant="h1" className={classes.title}>
            {props.spaceInfo.space_name}
          </Typography>

          <>
            {result && (
              <div className={classes.imagegallery_parent}>
                <div className={classes.imagegallery_container}>
                  <Slider ref={slider => (props.initSlider(slider))} {...slick_settings}>
                    {
                      images.map(img => {
                        return (
                          <div>
                            <img src={img.original_url} />
                          </div>
                        )
                      })
                    }
                  </Slider>  
                </div>
              </div>
            )} 
            {!result && (
              <div className={classes.imagegallery_parent} style= {{height: '250px',width: '100%'}}>
                <div className={classes.imagegallery_container} style= {{height: '240px',width: '100%'}}>
                  <Slider ref={slider => (props.initSlider(slider))} {...slick_settings_smartphone}>
                    {
                      images.map(img => {
                        return (
                          <div>
                            <img src={img.original_url} style= {{height: '235px',width: '100%'}}/>
                          </div>
                        )
                      })
                    }
                  </Slider> 
                </div>
              </div> 
            )}
          </>            
          
          <Typography variant="h3" className={classes.subtitle}>
            スペースについて
          </Typography>

          <Typography variant="body1" className={classes.description} style={{ whiteSpace: 'pre-line' }}>
            {props.spaceInfo.description}
          </Typography>


          <CustomizedTables {...props.spaceInfo} />

          <Typography variant="h3" className={classes.subtitle}>
            2週間 空き状況スケジュール
          </Typography>

          <TwoWeeksCalendar { ...twoweeksprops } />

          <Grid container spacing={2} className={classes.buttonGrid}>
            <Grid item xs={12} md={6}>
              <Button fullWidth className={classes.submitButton} 
                onClick={(event) => {props.linkTo(routes.spaceSchedule.getPath(props.space_id))}} >
                  予約スケジュールを確認する
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button fullWidth className={classes.submitButton} 
                onClick={(event) => {props.linkTo(routes.spaceRegularCalendar.getPath(props.space_id))}} >
                  定期利用イベントを確認する
              </Button>
            </Grid>
          </Grid>

          <Typography variant="h3" className={classes.subtitle}>
            設備・サービス
          </Typography>

          <FacilitiesBoard {...{space_facilities: props.spaceInfo.facility_info}} />

          <Typography variant="h3" className={classes.subtitle}>
            アクセス方法
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className={classes.mapGrid}>
              <GoogleApiWrapper {...{ center: {
                    lat: props.spaceInfo.map_info.lat, 
                    lng: props.spaceInfo.map_info.lng
                  }, space_name: props.spaceInfo.space_name
                }}
              />                    
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className={classes.access_info_container}>              
              <dl>
                <dt><DirectionsTransitIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: '#26A78E'}}/>電車でお越しの場合</dt>
                <dd>{props.spaceInfo.access_info.train}</dd>
              </dl>
              <dl>
                <dt><DirectionsCarIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: '#26A78E'}} />お車でお越しの場合</dt>
                <dd>{props.spaceInfo.access_info.car}</dd>
              </dl>
            </Grid>
          </Grid>

          <Typography variant="h3" className={classes.subtitle}>
            禁止事項
          </Typography>

          <ul>
            {prohibited_matter}
          </ul>

          <Typography variant="h3" className={classes.subtitle}>
            入退室方法
          </Typography>

          <Typography variant="body1" className={classes.description}>
            {props.spaceInfo.entry_exit_method}
          </Typography>
{/*
          <Typography variant="h3" className={classes.subtitle}>
            よくある質問
          </Typography>
          
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} className={classes.faq_container}>
              {faq}    
            </Grid>
          </Grid>
*/}
          <Typography variant="h3" className={classes.subtitle}>
            設備・備品一覧表
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>

              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>設備名</th>
                    <th>メーカー</th>
                    <th>個数</th>
                    <th>型番</th>
                    <th>備考</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>【設備】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>キッチン</th>
                    <td>LIXIL</td>
                    <td>1</td>
                    <td></td>
                    <td>グリルはIH3口タイプ</td>
                  </tr>
                  <tr>
                    <th>空調</th>
                    <td>DAIKIN</td>
                    <td>1</td>
                    <td>スカイエア</td>
                    <td>黒</td>
                  </tr>
                  <tr>
                    <th>トイレ(ウォシュレット付)</th>
                    <td>LIXIL</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>電動ブラインド</th>
                    <td>タチカワ</td>
                    <td>2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>インターネット(Wi-Fi付)</th>
                    <td>フレッツ光ネクスト</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>【電化製品】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>空気清浄機</th>
                    <td>Panasonic</td>
                    <td>1</td>
                    <td>F-PXS55-W</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>プロジェクター</th>
                    <td>EPSON</td>
                    <td>1</td>
                    <td>EF-100B</td>
                    <td>リモコン付</td>
                  </tr>
                  <tr>
                    <th>冷蔵庫</th>
                    <td>シャープ</td>
                    <td>1</td>
                    <td>SJ-DG14F-B</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>オーブンレンジ</th>
                    <td>Panasonic</td>
                    <td>1</td>
                    <td>NE-MS266</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>炊飯器</th>
                    <td>象印</td>
                    <td>1</td>
                    <td>NW-SA10</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>電気ポット</th>
                    <td>象印</td>
                    <td>1</td>
                    <td>CP-CA11</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>掃除機</th>
                    <td>Dyson</td>
                    <td>1</td>
                    <td>SV11SLM</td>
                    <td>替ヘッド2つ付</td>
                  </tr>
                  <tr>
                    <th>自動掃除機</th>
                    <td>iRobot</td>
                    <td>1</td>
                    <td>i7+</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Chromecast</th>
                    <td>google</td>
                    <td>1</td>
                    <td>GA00439-JP</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>BDレコーダー</th>
                    <td>Panasonic</td>
                    <td>1</td>
                    <td>DMR-BRW1060</td>
                    <td>リモコン付</td>
                  </tr>
                  <tr>
                    <th>HDMIスイッチング</th>
                    <td>サンワサプライ</td>
                    <td>1</td>
                    <td>SW-HDR31L</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>HDMIケーブル(ゲスト用)</th>
                    <td></td>
                    <td>1</td>
                    <td></td>
                    <td>5m</td>
                  </tr>
                  <tr>
                    <th>Lightning-HDMIアダプター</th>
                    <td>Apple</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>【什器】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>机</th>
                    <td>アイリスチトセ</td>
                    <td>6</td>
                    <td>CFT-89ZT1845T</td>
                    <td>18900</td>
                  </tr>
                  <tr>
                    <th>椅子</th>
                    <td>アイリスチトセ</td>
                    <td>18</td>
                    <td>VSTR-B</td>
                    <td>15290</td>
                  </tr>
                  <tr>
                    <th>スリッパ</th>
                    <td>富士工業</td>
                    <td>20</td>
                    <td>AK76</td>
                    <td>グレーとブラウン</td>
                  </tr>
                  <tr>
                    <th>ホワイトボード</th>
                    <td>アスクル</td>
                    <td>1</td>
                    <td>Matti</td>
                    <td>ペンホルダー付</td>
                  </tr>
                  <tr>
                    <th>ホワイトボードマーカー</th>
                    <td>アスクル</td>
                    <td>2</td>
                    <td></td>
                    <td>赤と黒</td>
                  </tr>
                  <tr>
                    <th>ホワイトボードイレーザー</th>
                    <td>アスクル</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>服かけ</th>
                    <td>umbra</td>
                    <td>2</td>
                    <td>8318850-660</td>
                    <td>10着分</td>
                  </tr>
                  <tr>
                    <th>Yogibo</th>
                    <td>Yobigo</td>
                    <td>2</td>
                    <td>MAX</td>
                    <td>グリーンとオレンジ</td>
                  </tr>
                  <tr>
                    <th>Yogibo</th>
                    <td>Yobigo</td>
                    <td>4</td>
                    <td>Lounger</td>
                    <td>グリーンとオレンジ</td>
                  </tr>
                  <tr>
                    <th>Yogibo</th>
                    <td>Yobigo</td>
                    <td>2</td>
                    <td>Support</td>
                    <td>グリーンとオレンジ</td>
                  </tr>
                  <tr>
                    <th>傘立て</th>
                    <td>山崎実業</td>
                    <td>1</td>
                    <td>6722</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>【調理器具等】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>鍋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>ソースパン16cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>鍋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>ソースパン20cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>フライパン</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>フライパン22cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>フライパン</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>フライパン26cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>T-fal専用取手</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>専用取手</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>バタフライガラス蓋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>蓋16cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>バタフライガラス蓋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>蓋20cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ソースパン専用蓋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>シールリッド16cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ソースパン専用蓋</th>
                    <td>T-fal</td>
                    <td>1</td>
                    <td>シールリッド20cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>メジャーカップ</th>
                    <td>本間冬治工業</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ホームスケール(はかり)</th>
                    <td>エー・アンド・デイ</td>
                    <td>1</td>
                    <td>UH3303-W</td>
                    <td>2kgまで</td>
                  </tr>
                  <tr>
                    <th>計量スプーン</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>チタンスライサー</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ステンレスボウル</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td>24cm</td>
                    <td></td>
                  </tr>
                  <tr>
                  <th>ステンレスボウル</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td>22cm</td>
                    <td></td>
                  </tr>
                  <tr>
                  <th>ステンレスボウル</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td>16cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ステンレス片手パンチングザル</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td>21cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ステンレス片手パンチングザル</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td>18cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>角バット</th>
                    <td>赤川器物製作所</td>
                    <td>1</td>
                    <td>33024</td>
                    <td>210x170x31</td>
                  </tr>
                  <tr>
                    <th>角バット</th>
                    <td>赤川器物製作所</td>
                    <td>1</td>
                    <td>33018</td>
                    <td>266x205x43</td>
                  </tr>
                  <tr>
                    <th>角バット</th>
                    <td>赤川器物製作所</td>
                    <td>1</td>
                    <td>33015</td>
                    <td>296x231x49</td>
                  </tr>
                  <tr>
                    <th>角バット</th>
                    <td>赤川器物製作所</td>
                    <td>1</td>
                    <td>33012</td>
                    <td>322x252x53</td>
                  </tr>
                  <tr>
                    <th>ミトン</th>
                    <td>NITORI</td>
                    <td>2</td>
                    <td>ミトンインフィGR</td>
                    <td>グリーンとオレンジ</td>
                  </tr>
                  <tr>
                    <th>水切りラックと布巾ハンガー</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>おろし金</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ホイッパー(大)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ホイッパー(小)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>おたま(大)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>おたま(中)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>おたま(小)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>水切りおたま</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ヘラ(大)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ヘラ(小)</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>フライ返し</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>パスタレードル</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>しゃもじ</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ピーラー</th>
                    <td>IKEA</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>鍋敷き</th>
                    <td>IKEA</td>
                    <td>3</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>三徳包丁</th>
                    <td>和平フレイズ</td>
                    <td>2</td>
                    <td>GR-6000</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>キッチンバサミ</th>
                    <td>下村工業</td>
                    <td>2</td>
                    <td>KB-301</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>トング</th>
                    <td>江部松商事</td>
                    <td>3</td>
                    <td>3429700</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>まな板</th>
                    <td>リス</td>
                    <td>2</td>
                    <td>GITA009</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>【食器類】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>マグカップ</th>
                    <td></td>
                    <td>20</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>コーヒーカップ&amp;ソーサー</th>
                    <td></td>
                    <td>10</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ガラスコップ</th>
                    <td></td>
                    <td>2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ティーポット</th>
                    <td></td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ミルクポット</th>
                    <td></td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>シュガーポット</th>
                    <td></td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>プレート26cm</th>
                    <td></td>
                    <td>10</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>プレート19cm</th>
                    <td></td>
                    <td>20</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>プレート11cm</th>
                    <td></td>
                    <td>20</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ボウルフープ15cm</th>
                    <td></td>
                    <td>20</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>お茶碗</th>
                    <td></td>
                    <td>20</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>子供用コップ</th>
                    <td></td>
                    <td>12</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>子供用平皿</th>
                    <td></td>
                    <td>12</td>
                    <td>16cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>子供用小鉢</th>
                    <td></td>
                    <td>12</td>
                    <td>12cm</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>菜箸</th>
                    <td></td>
                    <td>2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>樹脂箸</th>
                    <td>ストリックデザイン</td>
                    <td>30</td>
                    <td>SPS五角樹脂箸</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>コーヒースプーン</th>
                    <td>Pintinox</td>
                    <td>24</td>
                    <td>GIORNALE</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>テーブルスプーン</th>
                    <td>Pintinox</td>
                    <td>24</td>
                    <td>GIORNALE</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>フルーツフォーク</th>
                    <td>Pintinox</td>
                    <td>24</td>
                    <td>GIORNALE</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>テーブルフォーク</th>
                    <td>Pintinox</td>
                    <td>24</td>
                    <td>GIORNALE</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>テーブルナイフ</th>
                    <td>Pintinox</td>
                    <td>24</td>
                    <td>GIORNALE</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ピザカッター</th>
                    <td>NITORI</td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>茶こし</th>
                    <td></td>
                    <td>2</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>ケーキサーバー</th>
                    <td></td>
                    <td>1</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>【その他の備品】</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>Wi-Fiサインプレート</th>
                    <td>トヨダプロダクツ</td>
                    <td>1</td>
                    <td>SPT-W</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>トイレブラシ</th>
                    <td>マーナ</td>
                    <td>1</td>
                    <td>743216</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th>禁煙プレート</th>
                    <td>スマイル</td>
                    <td>1</td>
                    <td>743216</td>
                    <td></td>
                  </tr>

                </tbody>
              </table>
            </Grid>
          </Grid>

        </div>
        <BrowserRestrictionNotifyDialogContainer />
      </Container>
    </BasePageContainer>
  );
};

export default SpacePage;
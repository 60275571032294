import React from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector } from 'react-redux'
import { getTitleByPath, routes } from '../routes/Route';
import BasePageComponent from '../components/BasePageComponent';

interface Props {

}

const appStateSelector = (state: AppState) => state

export default function BasePageContainer(props: Props) {
  const state = useSelector(appStateSelector)

  const title = getTitleByPath(state.router.location.pathname)

  const _props = { ...props, loading: state.page.loading, title: title}

  return <BasePageComponent {..._props} />

}

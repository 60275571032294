import * as React from 'react';
import useReacdlouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container, Card, CardContent, Divider, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import { SpaceUsage, Invoice, Period } from '../dataObjects/space'

import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import ReservationStepperContainer from '../containers/ReservationStepperContainer'
import PaymentFormContainer from '../containers/PaymentFormContainer'
import { isIE } from 'react-device-detect';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      widdt: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    notice: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: 1.8,
      marginTop: theme.spacing(2)
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
    invoice: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },

    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 1,
      marginBottom: 1
    },
    colWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      width: '100%'
    },
    invoice_content: {
      fontSize: '1.7rem'
    },
    invoice_content_value: {
      minWidth: '25%',
      textAlign: 'right'
    },
    invoice_amount: {
      fontSize: '2.0rem'
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    }, 
  })
);

interface OwnProps {
  space_id: number,
  space_name: string,
  timerange: string,
  selectedPeriods: Period[],
  users_adult: string | number,
  users_children: string | number,
  usage: SpaceUsage,
  detail: string,
  invoice: Invoice,
  family_name: string,
  given_name: string,
  family_name_kana: string,
  given_name_kana: string,
  email: string,  
  postal_code: string,
  address_pref: string,
  address_city: string,
  address_street: string,
  address_room: string,
  tel: string,
  stripe_public_key: string,
  client_secret: string,
  onBack: () => void
}

export type SpacePaymentPageProps = OwnProps;

const SpacePaymentPage: React.FC<SpacePaymentPageProps> = (props: SpacePaymentPageProps) => {
  const classes = useStyles({} as StyleProps);
  const steperProps = { activeStep: 2 }
  const { space_id, space_name, timerange, selectedPeriods, users_adult,
    users_children, usage, detail, invoice,
    family_name, given_name, family_name_kana, given_name_kana,
    email, postal_code, address_pref, address_city,
    address_street, address_room, tel, stripe_public_key, client_secret, onBack
  } = props

  const stripePromise = loadStripe(props.stripe_public_key);

  const formatter = new Intl.NumberFormat('ja-JP')

  const periods = selectedPeriods.filter((v) => {
    return v.reserved === '2';      
  }).map((value,idx,arr) => {    
    return value.startTimeFromatted + " ～ " + value.endTimeFromatted;    
  });

  const periodsRendere = () => {
    const items = []
    for(let i = 0; i < periods.length; i++) {
      items.push(<span>{periods[i]}<br /></span>)
    }
    return <table style={{width: '60%'}}>
      <tr>
        <th style={{marginRight: '12px', verticalAlign: 'top', textAlign: 'left'}}>{timerange}</th>
        <td style={{textAlign: 'right', lineHeight: '1.5em'}}>{items}</td>
      </tr>
      <tr>
        <td></td>
        <td style={{marginTop: '4px', textAlign: 'right'}}>計 {periods.length} コマ</td>
      </tr>
    </table>
  }

  if (stripe_public_key == undefined || stripe_public_key == '') {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />
            
            <Typography variant="h3" className={classes.subtitle}>
              システムエラー：決済システム設定不正のため予約処理を継続できません。
            </Typography>            

          </div>                    
        </Container>
      </BasePageContainer>

    );
  }
  if (client_secret == undefined || client_secret == '') {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />
            
            <Typography variant="h3" className={classes.subtitle}>
              決済処理準備中...
            </Typography>            

          </div>                    
        </Container>
      </BasePageContainer>

    );
  }

  if (isIE) {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />
            
            <Typography variant="h3" className={classes.subtitle}>
              お客様のブラウザでは表示できません。
            </Typography>            

          </div>                    
        </Container>
      </BasePageContainer>

    );
  } else {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          
          <div className={classes.container}>
            <CustomizedBreadcrumbs />

            <Typography variant="h2" className={classes.title}>
              [利用料金お支払い手続き] {space_name}
            </Typography>

            <ReservationStepperContainer {...steperProps} />

            {/* 予約スケジュール表示 */}
            <div className={classes.reserveInfoContainer}>
              <dl>
                <dt>利用スペース</dt>
                <dd>{space_name}</dd>
              </dl>
              <dl>
                <dt>利用時間</dt>
                <dd>
                  {periodsRendere()}
                </dd>
              </dl>
              <dl>
                <dt>利用人数</dt>
                <dd>大人：{users_adult}人&nbsp;&nbsp;子供：{users_children}人</dd>
              </dl>
              <dl>
                <dt>利用目的</dt>
                <dd>{usage ? usage.usage : ''}</dd>
              </dl>
              <dl>
                <dt>連絡事項</dt>
                <dd>{detail}</dd>
              </dl>

              <Divider className={classes.divider} />

              <dl>
                <dt>氏名かな</dt>
                <dd>{family_name_kana}{given_name_kana}</dd>
              </dl>
              <dl>
                <dt>氏名</dt>
                <dd>{family_name}{given_name}</dd>
              </dl>
              <dl>
                <dt>メールアドレス</dt>
                <dd>{email}</dd>
              </dl>
              <dl>
                <dt>郵便番号</dt>
                <dd>{postal_code}</dd>
              </dl>
              <dl>
                <dt>都道府県</dt>
                <dd>{address_pref}</dd>
              </dl>
              <dl>
                <dt>市区町村</dt>
                <dd>{address_city}</dd>
              </dl>
              <dl>
                <dt>番地</dt>
                <dd>{address_street}</dd>
              </dl>
              <dl>
                <dt>建物名・部屋番号</dt>
                <dd>{address_room}</dd>
              </dl>
              <dl>
                <dt>電話番号</dt>
                <dd>{tel}</dd>
              </dl>
              
              <Card variant="outlined" className={classes.invoice}>
                <CardContent>
                  <Typography variant="h5" component="h2" className={classes.invoice_amount}>
                    ご請求明細
                  </Typography>

                  <Divider className={classes.divider} />

                  {
                    invoice.details.map(d => {
                      return (
                        <div className={classes.colWrapper}>
                          <div className={classes.rowWrapper}>
                            <div>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                利用時間計 金額：
                              </Typography>
                            </div>
                            <div className={classes.invoice_content_value}>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                ¥ {formatter.format(d.subtotal)}
                              </Typography>
                            </div>
                          </div>

                          <div className={classes.rowWrapper}>
                            <div>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                税({d.tax_rate * 100}%)：
                              </Typography>
                            </div>
                            <div className={classes.invoice_content_value}>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                ¥ {formatter.format(d.tax)}
                              </Typography>
                            </div>
                          </div>

                          <div className={classes.rowWrapper}>
                            <div>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                小計：
                              </Typography>
                            </div>
                            <div className={classes.invoice_content_value}>
                              <Typography variant="body2" component="p" className={classes.invoice_content}>
                                ¥ {formatter.format(d.amount)}
                              </Typography>
                            </div>
                          </div>

                        </div>
                      )
                      
                    })
                  }

                  <Divider className={classes.divider} />

                  <div className={classes.rowWrapper}>
                    <div>
                      <Typography variant="body2" component="p" className={classes.invoice_content}>
                        プロモーションコードによる値引き：
                      </Typography>
                    </div>
                    <div className={classes.invoice_content_value}>
                      <Typography variant="body2" component="p" className={classes.invoice_content}>
                        ¥ {formatter.format(invoice.discount)}
                      </Typography>
                    </div>
                  </div>

                  <Divider className={classes.divider} />

                  <div className={classes.rowWrapper}>
                    <div>
                      <Typography variant="body2" component="p" className={classes.invoice_amount}>
                        合計（税込）：
                      </Typography>
                    </div>
                    <div className={classes.invoice_content_value}>
                      <Typography variant="body2" component="p" className={classes.invoice_amount}>
                        ¥ {formatter.format(invoice.amount)}
                      </Typography>
                    </div>
                  </div>

                </CardContent>
              </Card>

            </div>
            
            <Typography variant="h3" className={classes.subtitle}>            
              <span>この情報で予約確定します。よろしければクレジットカード情報を入力して決済してください</span>
              { invoice.amount === 0 ? (
                  <span><br />※ 合計金額 0円の場合でもクレジットカード情報の入力が必要です</span>
                ) : null }
            </Typography>
            
            <Container>
              <Elements stripe={stripePromise}>
                <PaymentFormContainer {...{ space_id: space_id, client_secret: client_secret }} />
              </Elements>
            </Container>

            <Typography variant="h3" className={classes.subtitle}>
            （予約後のキャンセル）
            </Typography>

            <p className={classes.notice}>
              利用申込後、やむをえない理由によりご予約をキャンセルされる場合は、すみやかにお客様予約履歴ページ(https://shareal.jp/user/reservation/list)よりキャンセル手続きをお願い致します。キャンセル手数料は以下の通りです。<br />
              <br />
              <table className={classes.table}>
                <thead></thead>
                <tbody>
                  <tr>
                    <th>ご利用日の前日まで</th>
                    <td>キャンセル料なし<br />(但し、キャンセルの頻度があまりに高いお客様は利用資格を剥奪することがあります)</td>
                  </tr>
                  <tr>
                    <th>ご利用日当日</th>
                    <td>ご利用料金の１００％</td>
                  </tr>
                </tbody>
              </table>
              <br />
              ただし、天災や事故及びレンタルスペース利用に伴う人身事故などの事件、事故等、お客様の責に帰さない事由においてレンタルスペースを利用していただけない場合はキャンセル料は発生致しません。その場合は「ご利用の手引き」に記載の緊急連絡先までご連絡ください。
            </p>

            <Typography variant="h3" className={classes.subtitle}>
            （利用料金のお支払い）
            </Typography>

            <p className={classes.notice}>
            ご利用料金のお支払いはクレジットカードでの一括決済のみとさせて頂きます。<br />
            決済日は利用日当日となります。予約された日から利用日当日までの間は、定期的に与信確保処理を自動で行っております。<br />
            その為、利用日までの間に何らかの事情で与信確保ができなかった場合（限度額オーバーやカード有効期限切れ等）はご登録のメールアドレスにご連絡させていただきます。<br />
            その場合は、メールに記載のリンクから利用可能な他のクレジットカードにて決済手続きをお願いしております。利用日当日までに再手続きが行われなかった場合は、予約は自動的にキャンセルされます。

            </p>

            <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '100px'}}>
              <Grid item xs={6}>
                <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={onBack}>
                  戻る
                </Button>
              </Grid>
            </Grid>  

          </div>
        </Container>
      </BasePageContainer>

    );
  }
}

export default SpacePaymentPage
import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';

import { Link } from 'react-router-dom';
import { routes } from '../routes/Route';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    container: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        fontSize: '1.7rem',
        color: '#27A78E'
      }
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(4),
      textAlign: 'center'
    },
    notice: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margintTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
      width: '100%',
      height: '45vh',
      textAlign: 'center',
    },
    notice_left: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(8),
      paddingTop: theme.spacing(8),
      fontSize: '2.0rem'
    },
    notice_right: {
      padding: theme.spacing(8),
      fontSize: '1.5rem',
      lineHeight: 1.5,
      flex: 1
    },
    large_icon: {
      width: 50,
      height: 50,
      color: '#27A78E'
    }
  })
);

interface OwnProps {

}

export type SignUpCompletePageProps = OwnProps;

const SignOutPage: React.FC<SignUpCompletePageProps> = (props: SignUpCompletePageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        <div className={classes.container}>

          <div className={classes.notice}>
            <div>
              ログアウトしました
            </div>
          </div>
                
          <Link to={routes.top.path}>トップページへ戻る</Link>
        </div>   
      </Container>
    </BasePageContainer>

  );

}

export default SignOutPage
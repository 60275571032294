import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BasePageContainer from 'containers/BasePageContainer';
import { Container, useTheme, useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: 1.8,
      marginTop: theme.spacing(1),
      whiteSpace: 'pre-line'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    heading: {
      fontSize: '1.4rem',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: '1.4rem',
      color: theme.palette.text.secondary,
    },
    faqCategoryMenuContainer: {
      marginTop: theme.spacing(5),
    },
    faqMenuContainer: {
      marginTop: theme.spacing(5),
    },
    faqMenuSubContainer: {
      marginTop: theme.spacing(3),
    },
    link: {
      color: '#26A78E'
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },   
    browserNotice: {
      marginTop: theme.spacing(3),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px'
    }, 
  })
)



const FaqPage: React.FC = () => {
  const classes = useStyles({})
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const theme = useTheme()
  const xsMatches = useMediaQuery(theme.breakpoints.down('xs'))

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            よくある質問
          </Typography>

          <div className={classes.faqCategoryMenuContainer}>

          </div>

          <div className={classes.faqMenuContainer}>
            <Typography variant="h3" className={classes.subtitle}>
              1.Sharealユーザー登録・設定変更について
            </Typography>

            <div className={classes.faqMenuSubContainer}>
              <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 新規ユーザー登録しましたが<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ログインできません</>) 
                                : (<>Q. 新規ユーザー登録しましたがログインできません</>)
                    } 
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  「ユーザー登録する」ボタンを押下した段階では仮ユーザー状態であるため、Sharealユーザーとしてログインすることはできません。<br />
ユーザー登録時に指定したメールアドレス宛に届く「Shareal ユーザー登録確認メール」内のリンクをクリックして認証処理を完了してからログイン可能となります。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>Q. ユーザー登録確認メールが届きません</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  ユーザー登録をして頂くと「Shareal ユーザー登録確認メール」が指定したメールアドレス宛に送信されますが、以下の理由によってメールが届かないケースがございます。<br />
それぞれの対処方法を試し再度ユーザー登録をお願いします。<br />
なお、同じメールアドレスを使って最初のユーザー登録から40分以内に再ユーザー登録を行うと「このメールアドレスは登録できません」というエラーが発生します。<br />
その場合は40分以上経過してから再ユーザー登録をしてください。<br /><br />
<ul>
<li>
  1.メールアドレスが存在しない<br />
入力ミスが起こる可能性がございますので、もう一度メールアドレスに間違いがないか気をつけて頂いてユーザー登録をお願いします。<br /><br />
</li>
<li>
2.受信メールボックスの容量がオーバーしている<br />
一般的に、メールボックスやサーバーには容量の制限があります。<br />
受信側のメールボックス容量が特定の上限数に達すると、メールは全て受信拒否され届かなくなってしまいます。<br />
まずメールボックスの容量が上限を超えていないかご確認いただき、不要なメールを削除するなどの対応をお願いします。<br /><br />
</li>
<li>
3.迷惑メールフォルダへ振り分けされている<br />
弊社から送信するメールには不適切な内容は含まれておりませんが、<br />
お客様がご利用のメールソフトによっては迷惑メールと判定され、迷惑メールフォルダに振り分け受信されていることがあります。<br />
迷惑メールフォルダに入っていないかご確認をお願いします。<br /><br />
</li>
<li>
4.受信拒否されるケース<br />
お客様がご利用の通信会社/およびメールサービスによっては、標準でパソコンからのメールを受信拒否する設定となっていることがあります。<br />
またはお客様ご自身によって、「なりすましメールの拒否」設定を行っている、指定受信をオンにしていることもあります。<br />
こういった場合は、メールフィルタ設定にて shareal.jp をドメイン受信許可設定に追加して頂く必要がございます。<br />
<br />
詳しくは各通信会社のメール受信設定ヘルプページ等をご確認ください。<br />
</li>

</ul>

万が一、メール受信設定を変更して再度ユーザー登録を行ったにも関わらず認証メールが受信できない場合は<br />
ユーザー登録に使用するメールアドレスをフリーメール(G-mailやYahooメール)等に変更するといった対応をお願いします。<br />
<br />
<br />
上記いずれかの対応をして頂いてもなおユーザー登録ができない場合は、お問い合わせフォームからご連絡ください。<br />
その際には、shareal.jp のメールアドレスが受信できないことを考慮して、必ずユーザー登録に使用したメールアドレス以外のアドレスを使って頂くか、<br />
問い合わせ本文中にご連絡がつく電話番号をご記入ください。よろしくお願いいたします。

                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>              

              <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. Googleでログインとは<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;どういうものですか</>) 
                                : (<>Q. Googleでログインとはどういうものですか</>)
                    } 
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  お客様がお持ちのGoogleアカウントを使ってSharealのユーザーとしてログインできる機能です。<br />
それぞれのボタン押下後に、Googleの画面が表示されます。<br />
Sharealに対してログインすることができる許可を与える画面が表示されますので、許可するを選んでください。<br />
Sharealの画面に戻りログインすることができます。<br />
Googleの画面でログインしない、または許可を与えないことを選択した場合はShareal画面に戻りますがログインできていません。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography className={classes.heading}>Q. ログインパスワードを忘れてしまいました</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  ログイン画面の「パスワードを忘れてしまった」リンクからパスワード再設定画面に移ります。<br />
                    ログインIDとして指定したメールアドレスを入力して「リセットを実行する」ボタンを押しますと、
                    指定のメールアドレス宛にパスワードリセット確認メールが届きます。メール本文中のパスワードリセットリンクを押してパスワードリセット画面に移り、
                    ご希望のパスワードに再設定を行ってください。<br />
                    再設定後に再度ログイン画面からログインを試みてください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

            </div>


          </div>

          <div className={classes.faqMenuContainer}>
            <Typography variant="h3" className={classes.subtitle}>
              2.スペース予約について
            </Typography>

            <div className={classes.faqMenuSubContainer}>

              <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography className={classes.heading}>Q. どのように予約をすれば良いですか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  予約空き状況スケジュール画面から日付と時間を選択することで予約を行うことができます。<br />
                    予約空き状況スケジュール画面を閲覧するにはSharealユーザー登録を行ってログインする必要があります。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel23bh-content"
                  id="panel23bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. InternetExplorerに対応していないという<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;画面が表示されます</>) 
                                : (<>Q. InternetExplorerに対応していないという画面が表示されます</>)
                    }                    
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <div className={classes.description}>
                        当サイトはInternetExplorerに対応しておりません。<br />
                        予約空き状況スケジュール画面から予約処理に進むにはInternetExplorer以外のブラウザを使ってください。<br />
                        対応ブラウザは以下の通りです。<br />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>

                      <Typography variant="body1" className={classes.browserNotice}>
                        デスクトップ・ノートPC
                      </Typography>

                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Windows</th>
                            <th>mac</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Chrome 68以上</td>
                            <td>Chrome 68以上</td>
                          </tr>
                          <tr>
                            <td>Edge 42以上</td>
                            <td>Safari 11以上</td>
                          </tr>
                          <tr>
                            <td>Firefox 61以上</td>
                            <td>Firefox 61以上</td>
                          </tr>
                        </tbody>
                      </table>

                      <Typography variant="body1" className={classes.browserNotice}>
                        スマートフォン
                      </Typography>

                      <table className={classes.table}>
                        <thead>
                          <tr>
                            <th>Android</th>
                            <th>iOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Chrome 61, 68以上</td>
                            <td>Chrome 68以上</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Safari 11以上</td>
                          </tr>
                        </tbody>
                      </table>

                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              

              <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography className={classes.heading}>Q. Sharealの内覧をすることはできますか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  <Link className={classes.link} to={routes.inquiry.path}>お問合わせページ</Link>より内覧についてご相談ください。<br />
                    担当者が折り返しご連絡差し上げます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8bh-content"
                  id="panel8bh-header"
                >
                  <Typography className={classes.heading}>Q. 当日予約も可能ですか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  ご利用の10分前まで予約が可能です。ただしご利用日当日のキャンセルは出来ないルールとなっておりますのでお気をつけください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel9bh-content"
                  id="panel9bh-header"
                >
                  <Typography className={classes.heading}>Q. 利用当日の流れについて教えてください</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  予約完了時にSharealから送られるメールに「ご利用の手引」が添付されておりますので、そちらをご確認ください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel10bh-content"
                  id="panel10bh-header"
                >
                  <Typography className={classes.heading}>Q. 予約完了メールが届かない</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  通常であれば予約処理完了から数分でお客様のメールアドレス宛に予約完了メールが届きます。<br/>
                    しばらく待っても届かない場合は、お手数ですが<Link className={classes.link} to={routes.inquiry.path}>お問合わせページ</Link>からご連絡ください。
                    担当者が対応させて頂きます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel11bh-content"
                  id="panel11bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 決済処理時にカードが使えないという<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;エラーが発生する</>) 
                                : (<>Q. 決済処理時にカードが使えないというエラーが発生する</>)
                    }
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  お客様の事由によりクレジットカード決済ができない場合がございます。カード会社へお問い合わせください。<br/>
                    なお、Sharealで使用できるクレジットカードは以下のブランドに限ります。ご了承ください。<br/><br/>
                    <ul>
                      <li>VISA</li>
                      <li>MASTER</li>
                      <li>AMERICAN EXPRESS</li>
                      <li>JCB</li>
                      <li>Diners Club</li>
                      <li>Discover</li>
                    </ul>
                    <br/>
                    <img src="/images/creditcard.svg" alt="VISA MasterCard Amex JCB DinersClub Discover" style={{width: '100%'}} />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 支払い決済日は予約した日でしょうか？</>) 
                                : (<>Q. 支払い決済日は予約した日でしょうか？</>)
                    }
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                    Sharealでは予約された日から利用日当日までの間、クレジットカード決済は行われず、定期的に与信確保処理を自動で行っております。<br/>
                    その為、お支払い決済日は、Sharealご利用日当日となります。<br/>
                    利用日までの間に何らかの事情で与信確保ができない場合（限度額オーバーや有効期限切れ等）は<br/>
                    ご登録のメールアドレスにご連絡させていただきます。その場合は、利用可能な他のクレジットカードにて手続きをお願いします。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 決済処理エラーのメールが届きました。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;どうしたらいいでしょうか？</>) 
                                : (<>Q. 決済処理エラーのメールが届きました。どうしたらいいでしょうか？</>)
                    }
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                    Sharealでは予約された日から利用日当日までの間、クレジットカード決済は行われず、定期的に与信確保処理を自動で行っております。<br/>
                    その為、利用日までの間に何らかの事情で与信確保ができない場合（限度額オーバーや有効期限切れ等）は<br/>
                    ご登録のメールアドレスにご連絡させていただいております。<br/>
                    メールが届いた場合、メールのリンク先から利用可能な他のクレジットカードにて手続きをお願いします。<br/>
                    利用日当日までに再手続きが行われない場合、予約はキャンセルとなります。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel12bh-content"
                  id="panel12bh-header"
                >
                  <Typography className={classes.heading}>Q. キャンセルについて教えて下さい</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  キャンセルについては<Link className={classes.link} to={routes.operationPolicy.path}>運営ポリシーページ内のキャンセルポリシー</Link>をご確認ください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel122'} onChange={handleChange('panel122')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel122bh-content"
                  id="panel122bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 利用中に外から見られると恥ずかしいので、<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;なんとかなりませんか？</>) 
                                : (<>Q. 利用中に外から見られると恥ずかしいので、なんとかなりませんか？</>)
                    }                    
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  電動ブラインドを閉めていただければ、外から見えないようにご利用いただけます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel13bh-content"
                  id="panel13bh-header"
                >
                  <Typography className={classes.heading}>Q. 利用時間の延長はできますか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  利用時間延長の場合、延長開始時間10分前までに追加の時間枠をウェブサイトより予約していただくことで延長利用可能です。<br />
                  ただし、次の時間枠が空いている時に限ります。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel14bh-content"
                  id="panel14bh-header"
                >
                  <Typography className={classes.heading}>Q. 施設内の設備利用に料金はかかりますか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  当施設利用料に全ての料金が含まれていますので、追加での料金は発生しません。<br />
                  ただし、お使いになられた設備や備品の片付けは利用時間内にお願いいたします。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel15bh-content"
                  id="panel15bh-header"
                >
                  <Typography className={classes.heading}>Q. 施設内に忘れ物をしてしまいました。</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  <Link className={classes.link} to={routes.inquiry.path}>お問合わせページ</Link>より忘れ物についてご相談ください。<br />
                  担当者が折り返しご連絡差し上げます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

            </div>

          </div>

          <div className={classes.faqMenuContainer}>
            <Typography variant="h3" className={classes.subtitle}>
              3.スペース利用方法について
            </Typography>

            <div className={classes.faqMenuSubContainer}>

              <ExpansionPanel expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel19bh-content"
                  id="panel19bh-header"
                >
                  <Typography className={classes.heading}>Q. ゴミは処分していただけますか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                    利用していただいた上で出た残材やゴミは必ずお持ち帰りください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel20bh-content"
                  id="panel20bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 調理器具はどのようなものが<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用意されていますか？</>) 
                                : (<>Q. 調理器具はどのようなものが用意されていますか？</>)
                    }                     
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  一般的な調理に必要な器具は揃っています。詳細はスペース紹介ページの設備・備品一覧をご覧ください。<br />
                  なお、キッチン用洗剤、スポンジ、たわし等の清掃・洗浄用具、その他の消耗品(オーブンペ ーパー、ラップ、キッチンペーパー、ふきん、アルミホイル、調味料等)は全てご利用者様がご用意ください。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel21bh-content"
                  id="panel21bh-header"
                >
                  <Typography className={classes.heading}>
                    Q. 調理した飲食物を販売しても良いですか？
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  飲食物の販売目的でキッチンを利用される事は固くお断りいたします。また、当スペースのキッチンで調理されたものを当スペース外へ持ち出すことはできません。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel22bh-content"
                  id="panel22bh-header"
                >
                  <Typography className={classes.heading}>
                    Q. 子供だけで利用できますか？
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  未成年のみのご利用はできません。保護者、責任者を同伴してください
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

            </div>

          </div>

          <div className={classes.faqMenuContainer}>
            <Typography variant="h3" className={classes.subtitle}>
              4.定期利用について
            </Typography>

            <div className={classes.faqMenuSubContainer}>

              <ExpansionPanel expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel16bh-content"
                  id="panel16bh-header"
                >
                  <Typography className={classes.heading}>Q. 定期契約者の特典はありますか？</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                    定期利用契約特典は以下の通りです。<br />
                    <br />
                    <ul>
                      <li>a.月間利用契約時間に応じて利用料金の割引</li>
                      <li>b.当ウェブサイト内でのイベント告知</li>
                      <li>c.当社SNSでの告知</li>
                      <li>d.Shareal店舗前面にフライヤー貼付(当社規定サイズ)</li>
                    </ul>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel17bh-content"
                  id="panel17bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. 定期利用契約について詳しい説明を<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;聞くことはできますか？</>) 
                                : (<>Q. 定期利用契約について詳しい説明を聞くことはできますか？</>)
                    }                     
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  <Link className={classes.link} to={routes.regularInquiry.path}>定期利用の申し込み</Link>より定期利用契約についてご相談ください。<br />
                  担当者が折り返しご連絡差し上げます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel18bh-content"
                  id="panel18bh-header"
                >
                  <Typography className={classes.heading}>
                    {
                      xsMatches ? (<>Q. ワークショップと合わせて<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物販も行いたいのですが。</>) 
                                : (<>Q. ワークショップと合わせて物販も行いたいのですが。</>)
                    } 
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className={classes.description}>
                  <Link className={classes.link} to={routes.regularInquiry.path}>定期利用の申し込み</Link>より利用用途についてご相談ください。<br />
                  担当者が折り返しご連絡差し上げます。
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>

            </div>

          </div>
          

        </div>

      </Container>

    </BasePageContainer>
  )
}

export default FaqPage
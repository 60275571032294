import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { fetchMyReservation } from 'actions/userAction'
import UserReservationRedirectPage from 'pages/UserReservationRedirectPage'
import { RouteComponentProps } from 'react-router-dom'

interface OwnProps {
}

type Props = OwnProps & RouteComponentProps<{ reserve_id?: string }>

const appStateSelector = (state: AppState) => state

export default function UserReservationRedirectPageContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()


  useEffect(() => {
    console.log(props.match.params.reserve_id)
    dispatch(fetchMyReservation(parseInt(props.match.params.reserve_id)))
  }, [])
  

  return <UserReservationRedirectPage />

}
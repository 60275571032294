import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import PaymentForm, { PaymentFormProps } from '../components/Space/PaymentForm'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { reservationPaymentAction } from '../actions/spaceReservationAction'
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js'
import * as Sentry from '@sentry/browser'

interface OwnProps {
  space_id: number,
  client_secret: string
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

const translateMessage = (error: StripeError) => {
  try {    
    return error.message.trim()
  } catch (ex) {
    return ''
  }
}

export default function PaymentFormContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()
  const stripe = useStripe();
  const elements = useElements();
  const [errorState, setErrorMessages] = useState({errorMessages: ''})

  let submitting = false

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    submitting = true

    // Use your card Element with other Stripe.js APIs
    const {error, setupIntent} = await stripe.confirmCardSetup(props.client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: "user name",
        },
      }
    })

    if (error) {      
      setErrorMessages({ errorMessages: translateMessage(error) })      
      Sentry.captureMessage(JSON.stringify(error))
    } else {      
      setErrorMessages({ errorMessages: '' });
      dispatch(reservationPaymentAction(setupIntent))      
    }

    submitting = false
  }

  const _props: PaymentFormProps = {
    invoice: state.reservationSpace.payload.invoice,
    errorState: errorState,
    handleSubmit,
    submitting
  };

  return <PaymentForm {..._props} />

}
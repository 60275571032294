import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import ListTable, { ListTableProps } from 'components/ListTable/ListTable';

interface StyleProps {
  topcontainer: BaseCSSProperties,
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    list: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8)
    }
  })
);

interface OwnProps {

}

export type UserInquiryPageProps = OwnProps & ListTableProps;

const UserInquiryPage: React.FC<UserInquiryPageProps> = (props: UserInquiryPageProps) => {
  const classes = useStyles({} as StyleProps);  
  const { columns, data, total, page, rowsPerPage, rowsPerPageOptions, setPage, setRowsPerPage, selectedRow } = props;
  
  const listTableProps = { columns, data, total, page, rowsPerPage, rowsPerPageOptions, setPage, setRowsPerPage, selectedRow }
  
  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <Typography variant="h3" className={classes.subtitle}>
          お客様の問い合わせ履歴
        </Typography>

        <div className={classes.list}>
          <ListTable {...listTableProps} />
        </div>

      </Container>

    </BasePageContainer>

  );

}

export default UserInquiryPage
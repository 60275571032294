import * as React from 'react';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({    
    container: {
      fontSize: '1.3rem',
      lineHeight: 1.4,
    },    
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '30px',
      marginBottom: '5px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    
  })
)


interface OwnProps {
  loginSuccessDialogOpen: boolean,  
  handleClose: () => void
}

type SignInSuccessDialogProps = OwnProps


const SignInSuccessDialog: React.FC<SignInSuccessDialogProps> = (props: SignInSuccessDialogProps) => {
  const classes = useStyles({})

  return (
    <Dialog
      open={props.loginSuccessDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"ログイン成功"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Sharealへログインしました。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default SignInSuccessDialog
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { push, CallHistoryMethodAction } from "connected-react-router"
import { routes } from '../routes/Route'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import UserReservationPage, { UserReservationPageProps } from '../pages/UserReservationPage'
import { observeShownAction } from 'actions/pagingAction';
import { reservationCancelAction, fetchReservation, reservationBeginPaymentAction } from 'actions/spaceReservationAction';
import { closeDialog } from 'actions/dialogAction';


interface OwnProps {
  client_secret: string
}

export type UserReservationPageContainerProps = OwnProps & RouteComponentProps<{space_id?: string, basedate?: string}>

const appStateSelector = (state: AppState) => state

export default function UserReservationPageContainer(props: UserReservationPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()  

  const reservation = state.userReservation  
  const timeRange = reservation.reserveTimeRange;

  useEffect(() => {    
    if(reservation.status == '4') {
      // 決済開始
      dispatch(reservationBeginPaymentAction());
    }
    dispatch(observeShownAction());
  }, []);
      
  
  const _props: UserReservationPageProps = {
    space_id: reservation.space_id,    
    space_name: reservation.space_name,
    reserve_status: reservation.status,
    selectedDate: reservation.selectedDate as Date,
    timerange: reservation.selectedDate.toString(),
    selectedPeriods: reservation.selectedPeriods,
    users_adult: reservation.enquete.users_adult,
    users_children: reservation.enquete.users_children,
    usage: reservation.enquete.usage,
    detail: reservation.enquete.detail,
    invoice: reservation.invoice,
    family_name: reservation.contractor ? reservation.contractor.family_name : '',
    given_name: reservation.contractor ? reservation.contractor.given_name : '',
    family_name_kana: reservation.contractor ? reservation.contractor.family_name_kana : '',
    given_name_kana: reservation.contractor ? reservation.contractor.given_name_kana : '',
    email: reservation.contractor ? reservation.contractor.email : '',
    postal_code: reservation.contractor ? reservation.contractor.postal_code : '',
    address_pref: reservation.contractor ? reservation.contractor.address_pref : '',
    address_city: reservation.contractor ? reservation.contractor.address_city : '',
    address_street: reservation.contractor ? reservation.contractor.address_street : '',
    address_room: reservation.contractor ? reservation.contractor.address_room : '',
    tel: reservation.contractor ? reservation.contractor.tel : '',
    onSubmit: () => {

      switch(reservation.status) {
        case '0':
          // お客様情報へ
          dispatch(
            fetchReservation(
              reservation.reserve_id,
              () => push(routes.spaceCustomerInfo.getPath(reservation.space_id))
            )
          )
          break;
        case '1':
        case '2':
          // 支払いへ
          dispatch(
            fetchReservation(
              reservation.reserve_id,
              () => push(routes.spacePayment.path)
            )
          )
          break;
        case '3':
          // キャンセル処理
          dispatch(reservationCancelAction(reservation))
          break;
        default:
          break;
      }
    },
    onBack: () => {
      dispatch(push(routes.userReservationList.path))
    },
    dialogOpen: state.page.dialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
      // ユーザー予約履歴一覧画面に遷移
      const nextViewPath = routes.userReservationList.getPath()
      dispatch(push(nextViewPath))
    },
    stripe_public_key: state.settings.stripe.public_key,
    client_secret: state.payment.payload.client_secret,
  }

  return <UserReservationPage {..._props} />

}
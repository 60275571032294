import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { renderTextField } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';
import { theme } from 'materialui/theme';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },  
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    }
  }),
);


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'email'
  ]
  requiredFields.forEach(field => {
    console.log(`${field}: ${values[field]}`)
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })
  
  return errors
}

interface OwnProps {

}


export type ForgotPasswordFormProps = OwnProps & InjectedFormProps<{}, OwnProps>


const Form: React.FC<ForgotPasswordFormProps> = (props: ForgotPasswordFormProps) => {
  const classes = useStyles({});
  
  const { handleSubmit, pristine, submitting } = props;

  return (
    <Container component="main" maxWidth="xs">      
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          パスワードリセットを案内するメールが届きます
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="ログインIDメールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />          

          <div style={{marginTop: theme.spacing(8)}}></div>

          <Button
            type="submit"
            fullWidth
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            リセットを実行する
          </Button>
          
        </form>
      </div>
    </Container>    

  )
}

const ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form)

export default ForgotPasswordForm
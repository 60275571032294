import React from 'react';
import moment from 'moment';
import { routes } from '../../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import RemoveIcon from '@material-ui/icons/Remove';
import { Link } from 'react-router-dom'


interface StyleProps {
	root: BaseCSSProperties,
	container: BaseCSSProperties,
  prev: BaseCSSProperties,
  prevContents: BaseCSSProperties,
	calendar_container: BaseCSSProperties,
	weeks: BaseCSSProperties,
	daycolumn: BaseCSSProperties,
	dayofweek: BaseCSSProperties,
	day: BaseCSSProperties,
	day_text: BaseCSSProperties,
	status_container: BaseCSSProperties,
	status_img: BaseCSSProperties,
  next: BaseCSSProperties,
  nextContents: BaseCSSProperties,
}

interface TwoWeeksCalendarDay {
	day: string,
	status: number
}

export interface OwnProps {
	space_id: number
	days: TwoWeeksCalendarDay[]
}

export interface TwoWeeksCalendarAction {	
	onClickDate: (day: string) => void
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginTop: theme.spacing(2),
			width: '100%'
		},
		container: {
			display: 'flex',			
			height: '80px',
			[theme.breakpoints.down('sm')]: {
				height: '160px', 
			},
		},
		calendar_container: {
			height: '100%',
			width: '100%',
			flex: '1 1 0%',
			overflow: 'hidden',
			borderBottom: '1px solid rgb(234, 234, 234)',
			animation: '0.4s ease 0s 1 normal none running eMLfYp',
		},
		weeks: {
			display: 'flex',
			flexWrap: 'nowrap',
			width: '100%',
			height: '80px',
			//overflowX: 'scroll',
			//paddingBottom: '17px',
			boxSizing: 'content-box',
		},
		daycolumn: {
			width: 'calc(14.2856%)',
			height: '80px',
			borderTop: '1px solid rgb(234, 234, 234)',
			borderLeft: '1px solid rgb(234, 234, 234)',
			cursor: 'pointer'
		},
		[theme.breakpoints.down('sm')]: {
			weeks: {
				display: 'flex',
				flexWrap: 'wrap',
				width: '100%',
				height: '80px',
				//overflowX: 'scroll',
				//paddingBottom: '17px',
				boxSizing: 'content-box',
			},
			daycolumn: {
				width: 'calc(14%)',
				height: '80px',
				borderTop: '1px solid rgb(234, 234, 234)',
				borderLeft: '1px solid rgb(234, 234, 234)',
				cursor: 'pointer'
			},
		},
		dayofweek: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '20px',
			fontSize: '0.8em',
			lineHeight: '1',
			borderBottom: '1px solid rgb(234, 234, 234)',
		},
		dayofweekColor_def: {
			color: '#0c0c0c'
		},
		dayofweekColor_sun: {
			color: '#C7243A'
		},
		dayofweekColor_sat: {
			color: '#1C3954'
		},
		day: {
			display: 'flex',
			flexDirection: 'column',
			height: '60px',
		},
		day_text: {
			display: 'block',
			marginTop: '8px',
			textAlign: 'center',
			//color: 'rgb(51, 51, 51)',
			fontSize: '0.9em',
			lineHeight: '1',
		}, 
		status_container: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',    
			justifyContent: 'center',
			height: '100%',
      minHeight: '20px',
		},
		status_img: {
			maxWidth: '100%',
    	height: 'auto',
		},
		notVacancy: {
			color: '#050505'
		},
		alittle: {
			color: '#f5ad42'
		},
		allowReserve: {
			color: '#42b6f5'
		}
	}),
);


moment.locale("ja", {
	weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
	weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
	});
	
const createDayStatus = (date: string, status: number) => {
	moment.locale('ja')
	const datete = moment(date, 'YYYYMMDD')
	
	return { day: datete.format('MM/DD'), dayofweek: datete.format('ddd'), linkDate: datete.format('YYYYMMDD'), status: status };
}


export type TwoWeeksCalendarProps = OwnProps & TwoWeeksCalendarAction

const TwoWeeksCalendar: React.FC<TwoWeeksCalendarProps> = (props: TwoWeeksCalendarProps) => {
	const classes = useStyles({} as StyleProps);

	const days = props.days.map(day => 
		createDayStatus(day.day, day.status)	
	)

	const createStatusIcon = (status: number) => {
		if(status === 0) {
			return (
				<RemoveIcon className={classes.status_img + " " + classes.notVacancy} />
			)
		} else if (status <= 0.3) {
			return (
				<ChangeHistoryIcon className={classes.status_img+ " " + classes.alittle} />
			)
		} else {
			return (
				<RadioButtonUncheckedIcon className={classes.status_img+ " " + classes.allowReserve} />
			)
		}	
	}

	return (
		<div className={classes.root} >
			<div className={classes.container} >

				<div className={classes.calendar_container} >

					<div className={classes.weeks} >

						{
							days.map(day => {
								let dayofweekClass = ''
								if (day.dayofweek == '土') {
									dayofweekClass = classes.dayofweekColor_sat
								} else if (day.dayofweek == '日') {
									dayofweekClass = classes.dayofweekColor_sun
								} else {
									dayofweekClass = classes.dayofweekColor_def
								}
								return (
									<div onClick={(event) => { props.onClickDate(day.linkDate) }} className={classes.daycolumn} key={day.day} >
										<div className={classes.dayofweek} >
											<div><span className={dayofweekClass}>{day.dayofweek}</span></div>
										</div>
										<div className={classes.day} >
											<span className={classes.day_text + " " + dayofweekClass} >{day.day}</span>
											<div className={classes.status_container}>
												{
													createStatusIcon(day.status)
												}
											</div>
										</div>
									</div>
								)
							})
						}
					</div>

				</div>
		
			</div>
		</div>
	)
}

export default TwoWeeksCalendar
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import InquiryPage from '../pages/InquiryPage'
import { observeShownAction } from 'actions/pagingAction';
import { CustomThunkDispatch } from 'types/dispatchTypes';

interface OwnProps {
}

export type InquiryPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function InquiryPageContainer(props: InquiryPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // スペースページで読み込まれていなければ初期化
  useEffect(() => {
    dispatch(observeShownAction())
  }, []);

  return <InquiryPage />

}
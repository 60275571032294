import AppClient from '../httpClients/appClient';

export interface PostalCodeSeacrhResultEnvelope {
  status: number,
  message: string,
  results: PostalCodeSearchResult[]
}

export interface PostalCodeSearchResult {
  zipcode: string,
  /* 都道府県コード */
  prefcode: string,
  /* 都道府県名 */
  address1: string,
  /* 市区町村名 */
  address2: string,
  /* 町域名 */
  address3: string,
  kana1: string,
  kana2: string,
  kana3: string
}

export const addressSearchByPostalCode = (postal_code: string,
  callback: (result: PostalCodeSeacrhResultEnvelope) => void) => {

  AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/addressseach/${postal_code}`)
  .then(res => {
    const result: PostalCodeSeacrhResultEnvelope = JSON.parse(res.data);
    if (callback) {
      callback(result)
    }
  })
  .catch(reason => {
    console.log(reason)
  })    
  
}
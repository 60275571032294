import {
  FETCH_SPACE_INFO_START,
  FETCH_SPACE_INFO_SUCCESS,
  FETCH_SPACE_INFO_FAIL,
  FETCH_PERIOD_AT_DAY_START,
  FETCH_PERIOD_AT_DAY_SUCCESS,
  FETCH_PERIOD_AT_DAY_FAIL,
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START,
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS,
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START
} from '../actions/spacePageDataAction';

import { PATH_NAME_CHANGE } from '../actions/locationChangeAction';
import { ActionTypes } from '../actions/actionTypes';
import { ViewState } from 'dataObjects/behavior';
import { PAGE_SHOWN } from 'actions/pagingAction';
import { FETCH_SETTINGS_START, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAIL } from 'actions/settingsAction';
import { 
  RESERVATION_BEGIN_PAYMENT_FAIL, 
  RESERVATION_BEGIN_PAYMENT_START, 
  RESERVATION_PERIOD_START, 
  RESERVATION_PERIOD_SUCCESS, 
  RESERVATION_PERIOD_INVALID, 
  RESERVATION_PERIOD_FAIL, 
  RESERVATION_CONTRACTOR_START, 
  RESERVATION_CONTRACTOR_SUCCESS, 
  RESERVATION_CONTRACTOR_INVALID, 
  RESERVATION_CONTRACTOR_FAIL, 
  RESERVATION_CREATEINVOICE_START, 
  RESERVATION_CREATEINVOICE_SUCCESS, 
  RESERVATION_CREATEINVOICE_FAIL, 
  RESERVATION_BEGIN_PAYMENT_SUCCESS, 
  RESERVATION_BEGIN_PAYMENT_INVALID, 
  RESERVATION_CANCEL_SUCCESS,
  RESERVATION_CANCEL_FAIL,
  RESERVATION_CANCEL_START,
  RESERVATION_CANCEL_INVALID,
  RESERVATION_PAYMENT_START,
  RESERVATION_PAYMENT_SUCCESS,
  RESERVATION_PAYMENT_INVALID,
  RESERVATION_PAYMENT_FAIL
} from 'actions/spaceReservationAction';
import { INTERNAL_SERVER_ERROR, NOT_FOUND_ERROR, SS_VALIDATION_ERROR } from 'actions/errorAction';
import { LOGIN_SUCCESS, LOGIN_START, LOGIN_INVALID, LOGIN_FAIL } from 'actions/authenticationActions';
import { 
  FETCH_MY_USERDATA_START, 
  FETCH_MY_USERDATA_SUCCESS, 
  FETCH_MY_USERDATA_FAIL, 
  STORE_MY_USERDATA_START, 
  STORE_MY_USERDATA_SUCCESS, 
  STORE_MY_USERDATA_FAIL, 
  FETCH_MY_INQUIRY_START, 
  FETCH_MY_INQUIRY_SUCCESS, 
  FETCH_MY_INQUIRY_FAIL,
  FETCH_MY_RESERVATION_LIST_START,
  FETCH_MY_RESERVATION_LIST_SUCCESS,
  FETCH_MY_RESERVATION_LIST_FAIL,
  FETCH_MY_RESERVATION_START,
  FETCH_MY_RESERVATION_SUCCESS,
  FETCH_MY_RESERVATION_FAIL
 } from 'actions/userAction';
import { DIALOG_CLOSE } from 'actions/dialogAction';
import { 
  COMMON_INQUIRY_POST_START, 
  COMMON_INQUIRY_POST_SUCCESS, 
  COMMON_INQUIRY_POST_FAIL, 
  REGULAR_INQUIRY_POST_START, 
  REGULAR_INQUIRY_POST_SUCCESS, 
  REGULAR_INQUIRY_POST_FAIL 
} from 'actions/inquieryAction';
import { FETCH_NEWS_SUCCESS, FETCH_NEWS_START, FETCH_NEWS_FAIL } from 'actions/newsAction';
import { FETCH_REGULAR_SCHEDULE_LIST_SUCCESS, FETCH_REGULAR_SCHEDULE_LIST_FAIL, FETCH_REGULAR_SCHEDULE_LIST_START, SHOW_SCHEDULE_EVENT } from 'actions/regularAction';



const initialState: ViewState = {
  loading: true,
  dialogOpen: false,
  internalErrorDialogOpen: false,
  loginSuccessDialogOpen: false,
  validationErrorDialogOpen: false
};

const pathChangeState: ViewState = {
  loading: true,
  dialogOpen: false,
  internalErrorDialogOpen: false,
  loginSuccessDialogOpen: false,
  validationErrorDialogOpen: false
};

const page = (
  state: ViewState = initialState,
  action: ActionTypes,
): ViewState => {
  switch (action.type) {
    // 設定取得
    case FETCH_SETTINGS_START:
      return { ...state, loading: true };
    case FETCH_SETTINGS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SETTINGS_FAIL:
      return { ...state, loading: false };
    // ログイン
    case LOGIN_START:    
      return { ...state, loading: true, loginSuccessDialogOpen: false };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, loginSuccessDialogOpen: true };
    case LOGIN_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case LOGIN_FAIL:
      return { ...state, loading: false };
    // スペース選択
    case FETCH_SPACE_INFO_START: 
      return { ...state, loading: true };
    case FETCH_SPACE_INFO_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SPACE_INFO_FAIL:
      return { ...state, loading: false };
    // スペーススケジュール取得
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START:
      return { ...state, loading: true };
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL:
      return { ...state, loading: false };
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START:
      return { ...state, loading: true };
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL:
      return { ...state, loading: false };
    // コマ情報取得    
    case FETCH_PERIOD_AT_DAY_START:
      return { ...state, loading: true };
    case FETCH_PERIOD_AT_DAY_SUCCESS:
      return { ...state, loading: false };
    case FETCH_PERIOD_AT_DAY_FAIL:
      return { ...state, loading: false };
    // 
    // コマ予約
    case RESERVATION_PERIOD_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case RESERVATION_PERIOD_SUCCESS:
      return { ...state, loading: false };
    case RESERVATION_PERIOD_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case RESERVATION_PERIOD_FAIL:
      return { ...state, loading: false };
    // 契約者情報
    case RESERVATION_CONTRACTOR_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case RESERVATION_CONTRACTOR_SUCCESS:
      return { ...state, loading: false };
    case RESERVATION_CONTRACTOR_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case RESERVATION_CONTRACTOR_FAIL:
      return { ...state, loading: false };
    /* 請求情報 */
    case RESERVATION_CREATEINVOICE_START:
      return { ...state, loading: true };
    case RESERVATION_CREATEINVOICE_SUCCESS:
      return { ...state, loading: false };
    case RESERVATION_CREATEINVOICE_FAIL:
      return { ...state, loading: false };
    // 決済処理
    case RESERVATION_BEGIN_PAYMENT_START:
      return { ...state, loading: true };
    case RESERVATION_BEGIN_PAYMENT_SUCCESS:
      return { ...state, loading: false };
    case RESERVATION_BEGIN_PAYMENT_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case RESERVATION_BEGIN_PAYMENT_FAIL:
      return { ...state, loading: false };
    case RESERVATION_PAYMENT_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case RESERVATION_PAYMENT_SUCCESS:
      return { ...state, loading: false };
    case RESERVATION_PAYMENT_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case RESERVATION_PAYMENT_FAIL:
      return { ...state, loading: false };
    // ユーザーデータ読み込み
    case FETCH_MY_USERDATA_START: 
      return { ...state, loading: true };
    case FETCH_MY_USERDATA_SUCCESS:
      return { ...state, loading: false };
    case FETCH_MY_USERDATA_FAIL:
      return { ...state, loading: false };
    // ユーザーデータ保存
    case STORE_MY_USERDATA_START: 
      return { ...state, loading: true };
    case STORE_MY_USERDATA_SUCCESS:
      return { ...state, loading: false, dialogOpen: true };
    case STORE_MY_USERDATA_FAIL:
      return { ...state, loading: false };
    // ユーザー問い合わせ読み込み
    case FETCH_MY_INQUIRY_START: 
      return { ...state, loading: false }
    case FETCH_MY_INQUIRY_SUCCESS:
      return { ...state, loading: false };
    case FETCH_MY_INQUIRY_FAIL:
      return { ...state, loading: false }   
    // ユーザー予約一覧読み込み
    case FETCH_MY_RESERVATION_LIST_START: 
      return { ...state, loading: false }
    case FETCH_MY_RESERVATION_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_MY_RESERVATION_LIST_FAIL:
      return { ...state, loading: false }
    // ユーザー予約読み込み
    case FETCH_MY_RESERVATION_START: 
      return { ...state, loading: true }
    case FETCH_MY_RESERVATION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_MY_RESERVATION_FAIL:
      return { ...state, loading: false }
    // 問い合わせ
    case COMMON_INQUIRY_POST_START:
      return { ...state, loading: true }
    case COMMON_INQUIRY_POST_SUCCESS:
      return { ...state, loading: false }
    case COMMON_INQUIRY_POST_FAIL:
      return { ...state, loading: false }
    // 定期問い合わせ
    case REGULAR_INQUIRY_POST_START:
      return { ...state, loading: true }
    case REGULAR_INQUIRY_POST_SUCCESS:
      return { ...state, loading: false }
    case REGULAR_INQUIRY_POST_FAIL:
      return { ...state, loading: false }
    // キャンセル
    case RESERVATION_CANCEL_START:
      return { ...state, loading: true }
    case RESERVATION_CANCEL_SUCCESS:
      return { ...state, loading: false, dialogOpen: true }
    case RESERVATION_CANCEL_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true }
    case RESERVATION_CANCEL_FAIL:
      return { ...state, loading: false }
    // News
    case FETCH_NEWS_START:
      return { ...state, loading: true }
    case FETCH_NEWS_SUCCESS:
      return { ...state, loading: false }
    case FETCH_NEWS_FAIL:
      return { ...state, loading: false }
    // イベントを探す
    case FETCH_REGULAR_SCHEDULE_LIST_START:
      return { ...state, loading: true }
    case FETCH_REGULAR_SCHEDULE_LIST_SUCCESS:
      return { ...state, loading: false }
    case FETCH_REGULAR_SCHEDULE_LIST_FAIL:
      return { ...state, loading: false }
    // スケジュール表イベント表示
    case SHOW_SCHEDULE_EVENT: 
      return { ...state, loading: false, dialogOpen: true };
    // Location change
    case PATH_NAME_CHANGE:
      return { ...state, loading: true,
        dialogOpen: false,
        validationErrorDialogOpen: false }
    // ERROR
    case NOT_FOUND_ERROR: 
      return { ...state, loading: false };
    case INTERNAL_SERVER_ERROR:
      return { ...state, loading: false, internalErrorDialogOpen: true };
    case SS_VALIDATION_ERROR:
      return { ...state, loading: false };
    // PAGE_SHOWN
    case PAGE_SHOWN:
      return { ...state, loading: false };
    // ダイアログ閉じた
    case DIALOG_CLOSE:
      return { ...state, dialogOpen: false, internalErrorDialogOpen: false, validationErrorDialogOpen: false, loginSuccessDialogOpen: false}
    default:
      return { ...state };
  }
};

export default page;

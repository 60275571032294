import * as React from 'react';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import CustomizedBreadcrumbs from '../components/CustomizedBreadcrumbs/CustomizedBreadcrumbs'
import ReservationStepperContainer from '../containers/ReservationStepperContainer'
import ScheduleCalendarContainer, { ScheduleCalendarContainerProps } from '../containers/ScheduleCalendarContainer'
import ReservationFormContainer from '../containers/ReservationFormContainer'
import SpaceBottomNavContainer from '../containers/SpaceBottomNavContainer'
import { SpaceInfo } from 'dataObjects/space';
import { isIE } from 'react-device-detect';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}
  
const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
  })
);
  
interface OwnProps {
    space_id: number,
    space_info: SpaceInfo,
    basedate: Date,
}

export type SpaceSchedulePageProps = OwnProps;

const SpaceSchedulePage: React.FC<SpaceSchedulePageProps> = (props: SpaceSchedulePageProps) => {
  const classes = useStyles({} as StyleProps);
  const calendarProps: ScheduleCalendarContainerProps = { space_id: props.space_id, basedate: props.basedate }

  const steperProps = { activeStep: 0 }

  if (isIE) {
    return (
      <BasePageContainer>
        <Container fixed className={classes.topcontainer} >
          <div className={classes.container}>
            <CustomizedBreadcrumbs />
            
            <Typography variant="h3" className={classes.subtitle}>
              お客様のブラウザでは表示できません。
            </Typography>            

          </div>                    
        </Container>
      </BasePageContainer>

    );
  } else {
    return (
        <BasePageContainer>
          <Container fixed className={classes.topcontainer} >
            <div className={classes.container}>
              <CustomizedBreadcrumbs />

              <Typography variant="h2" className={classes.title}>
                [スペース予約] {props.space_info.space_name}
              </Typography>

              <ReservationStepperContainer { ...steperProps } />
              
              <ScheduleCalendarContainer { ...calendarProps }/>

              <Typography variant="h3" className={classes.subtitle}>
                ご利用内容を記入してください
              </Typography>

              <ReservationFormContainer />
            </div>

            <SpaceBottomNavContainer />
          </Container>
        </BasePageContainer>

    );
  }

}

export default SpaceSchedulePage
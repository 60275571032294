import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { SubmissionError, stopAsyncValidation, startAsyncValidation } from 'redux-form'
import { Values, Errors } from '../types/formTypes'

import { userRegisterAction } from '../actions/authenticationActions'
import SignUp, { SignUpProps, validate } from '../components/Auth/SignUp';
import { ValidationError } from 'errors/RequestValidationError';
import { serverValidationClear } from 'actions/errorAction';

interface OwnProps {  
}

export type SignInContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

const CN_FORM_NAME = 'SignUp'

export default function SignInContainer(props: SignInContainerProps) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      ownDispatch(serverValidationClear())
      setIsDisplay(false)
      
      ownDispatch(userRegisterAction(values['name'], values['email'], values['password'], values['password_confirmation']))
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })

      ownDispatch(startAsyncValidation(CN_FORM_NAME))

      if(errors.hasOwnProperty('email')
        && errors['email'].hasOwnProperty('email')) {      
        errors['email'] = errors['email']['email']
      }
      if(errors.hasOwnProperty('password')
        && errors['password'].hasOwnProperty('password')) {      
        errors['password'] = errors['password']['password']
      }
      if(errors.hasOwnProperty('name')
        && errors['name'].hasOwnProperty('name')) {      
        errors['name'] = errors['name']['name']
      }
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation(CN_FORM_NAME, errors))
      setIsDisplay(true)
    }
  } 

  // 初期化
  useEffect(() => {
  }, []);
    
  const _props = {    
    onSubmit: submit,
    validationResult: state.ss422ValidationErrorResult,
    ss422Validation: showValidationError,
  }

  return <SignUp {..._props} />

}
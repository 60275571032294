import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MuiThemeProvider, Theme } from '@material-ui/core';
import { theme } from '../../../materialui/theme';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes/Route';
import BasePageContainer from 'containers/BasePageContainer';


interface StyleProps {
  container: BaseCSSProperties,
  img: BaseCSSProperties,
  message: BaseCSSProperties,
  link: BaseCSSProperties,
}


const useStyles = makeStyles((theme: Theme) => ({
  topcontainer: {
    marginTop: theme.spacing(2)
  },
  container: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  img: {
    width: '70%',
  },
  message: {
    paddingTop: theme.spacing(1),
  },
  link: {
    paddingTop: theme.spacing(2),
  },
}));

export type Props = {
  message?: string;
};

const NotFoundPage: React.FC<Props> = ({ message }) => {
  const classes = useStyles({} as StyleProps);

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <Typography variant="h3">404 Not Found</Typography>
        {message && (
          <Typography variant="h4" className={classes.message}>
            {message}
          </Typography>
        )}
        <Link to={routes.top.path}>
          <Typography variant="h6" className={classes.link}>
            トップページへ
          </Typography>
        </Link>
      </Container>

    </BasePageContainer>
  );
};

export { NotFoundPage };

import { combineReducers } from 'redux'
import settings from './settingsReducer'
import authStatus from './authStatusReducer'
import authRedirectRoute from './authRedirectRouteReducer'
import error from './errorReducer'
import page from './pageReducuer'
import snackBar from './snackBarReducer'
import viewingSpace from './viewingSpaceReducer'
import reservationSpace from './reservationReducer'
import payment from './paymentReducer'
import master from './masterReducer'
import userData from './userDataReducer'
import userInquiry from './userInquiryReducer'
import userReservationList from './userReservationListReducer'
import userReservation from './userReservationReducer'
import navMenuVisible from './navReducer'
import ss422ValidationErrorResult from './ss422ValidationErrorReducer'
import validationError from './validationErrorReducer'
import browserRestrict from './browserRestrictReducer'
import { regularEventList, regularEventInfoList, showEvent } from './regularScheduleReducer'
import { newsList, news } from './newsReducer'
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as reduxFormReducer } from 'redux-form';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    settings,
    master,
    authStatus,
    authRedirectRoute,
    error,
    page,
    navMenuVisible,
    newsList,
    news,
    snackBar,
    validationError,
    ss422ValidationErrorResult,
    viewingSpace,
    reservationSpace,
    payment,
    userData,
    userInquiry,
    userReservationList,
    userReservation,
    showEvent,
    regularEventList,
    regularEventInfoList,
    browserRestrict,
    form: reduxFormReducer
  });

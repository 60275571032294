import * as React from 'react';
import { Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';

import { Link, LinkProps } from 'react-router-dom';
import UserInfoFormContainer from 'containers/UserInfoFormContainer';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    }
  })
);

interface OwnProps {
  dialogOpen: boolean,
  handleClose: () => void
}

export type UserDataPageProps = OwnProps;

const UserDataPage: React.FC<UserDataPageProps> = (props: UserDataPageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <Typography variant="h3" className={classes.subtitle}>
          ユーザーデータの更新
        </Typography>

        <Typography variant="body1" className={classes.description}>
          ユーザーアカウント情報に変更がある場合は更新してください<br />
          お名前、住所等を登録されますと、スペース利用予約時の入力作業を省くことができます。
        </Typography>
        
        <UserInfoFormContainer />

        <Dialog
          open={props.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"更新処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ユーザーアカウント情報が更新されました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </BasePageContainer>

  );

}

export default UserDataPage
import React from 'react';
import './App.css';
import { withCookies, CookiesProvider } from 'react-cookie';
import { AppRoute } from './routes/Route';
import { HelmetProvider } from 'react-helmet-async';


const App: React.FC<{}> = () => {
  return (
    <CookiesProvider>
      <HelmetProvider>
        <AppRoute />
      </HelmetProvider>
    </CookiesProvider>
  );
};

export default withCookies(App);

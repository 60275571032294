import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import ReservationStepper, { ReservationStepperProps } from '../components/Space/ReservationStepper'

interface OwnProps {
  activeStep: number
}

type ReservationStepperContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

const getSteps = () => {
  return ['利用時間予約', 'お客様情報入力', 'お支払い手続き', '予約完了'];
}

export default function ReservationStepperContainer(props: ReservationStepperContainerProps) {
  const state = useSelector(appStateSelector)

  const _props = { ...props, steps: getSteps() }

  return <ReservationStepper {..._props} />

}
import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { closeDialog } from 'actions/dialogAction';
import ValidationErrorDialog from 'components/ValidationErrorDialog/ValidationErrorDialog';

interface Props {
}

const appStateSelector = (state: AppState) => state

export default function ValidationErrorDialogContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    validationErrorDialogOpen: state.page.validationErrorDialogOpen,
    validationResult: state.validationError,
    handleClose: () => {
      dispatch(closeDialog())
    }
  }

  return <ValidationErrorDialog {..._props} />
}

import { Action } from 'redux';

export const DIALOG_CLOSE = "DIALOG_CLOSE"


export type DialogActions = DialogClose;

export interface DialogClose extends Action<typeof DIALOG_CLOSE> {
}

export function closeDialog(): DialogClose {
  return { type: DIALOG_CLOSE };
}
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';

export const PAGE_SHOWN = 'PAGE_SHOWN';
export type PagingActions =
  | ShownAction
  | ErrorActions;

type PagingThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  PagingActions
>

export interface ShownAction
  extends Action<typeof PAGE_SHOWN> { }

const shownAction = (): ShownAction => ({
  type: 'PAGE_SHOWN',
});


export function observeShownAction(): PagingThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, PagingActions>,
    getState: () => AppState) => {
    dispatch(shownAction())
  }
}
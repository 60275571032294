export interface Values {
  [key: string]: any;
}

export interface Errors {
  [key: string]: any;
}

 
export interface FieldProps {
  input: any;
  label: string;
  type: string;
  meta: {
    touched: string;
    error: any;
    warning: string;
  };
}


/**
 * 指定したStateをRedux-FormのinitialValueにセットできるよう変換する
 * 
 * @param state 
 */
export const convertFormInitialValues = <T>(state: T) : Values => {
  let values: Values = {}
  if(state) {
    for (const [key, value] of Object.entries(state)) { 
      values[key] = value === undefined ? undefined 
                    : typeof value === 'string' ? value
                    : typeof value === 'number' ? value.toString() 
                    : typeof value === 'boolean' ? value.toString()
                    : value === null ? '' 
                    : Array.isArray(value) ? value
                    : typeOf(value) === 'object' ? Object.keys(value).map(function (key) { return value[key] })
                    : ''
    }
  }
  return values    
}

var toString = Object.prototype.toString;
function typeOf(obj) {
  return toString.call(obj).slice(8, -1).toLowerCase();
}


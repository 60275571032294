import React, { useEffect } from 'react'
import { RouteProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../types/stateTypes';
import { AuthRoute, AuthRouteProps } from '../components/Route/AuthRoute';
import { fetchSettings } from '../actions/settingsAction';
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { fetchMasterAction } from 'actions/masterAction';
import { afterSignInRedirectRouteSetAction, logOutAction } from 'actions/authenticationActions';
import { AuthenticationError } from 'reducers/errorReducer';

type Props = {
} & RouteProps;



const appStateSelector = (state: AppState) => state

export default function AuthContainer(props: Props) {
  const state = useSelector(appStateSelector)
  
  const stateProps =  {
    hasAuthenticate: !!state.authStatus.hasAuthenticate,
    loading: state.page.loading,
    error: state.error.errorType,
  }

  const dispatch = useDispatch<CustomThunkDispatch>()  

  if(state.error.errorType === AuthenticationError) {
    dispatch(logOutAction())
  }

  const _props = {...props, ...stateProps}

  return <AuthRoute { ..._props } />

}
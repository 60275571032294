import { AxiosResponse } from "axios";


export class ResponseError extends Error {
  private readonly res: AxiosResponse<any> | undefined;

  constructor(message: string, response?: AxiosResponse<any>) {
    super(message);
    this.res = response;
  }

  getResponse(): AxiosResponse<any> | undefined {
    return this.res;
  }
}

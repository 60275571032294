import { AfterSignInRedirectRoute } from "dataObjects/auth";
import { ActionTypes } from '../actions/actionTypes';
import { AFTER_SIGNIN_REDIRECT_ROUTE_SET, AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR, LOGOUT_SUCCESS } from "actions/authenticationActions";


export interface AfterSignInRedirectRouteState extends AfterSignInRedirectRoute {
}

const initialAfterSignInRedirectRoute: AfterSignInRedirectRouteState = {
  route: ''
}

const authRedirectRoute = (
  state: AfterSignInRedirectRoute = initialAfterSignInRedirectRoute,
  action: ActionTypes,
): AfterSignInRedirectRouteState => {
  switch (action.type) {
    case AFTER_SIGNIN_REDIRECT_ROUTE_SET: 
      return {
        ...state,
        ...action.payload,
      };
    case AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR:
      return initialAfterSignInRedirectRoute
    case LOGOUT_SUCCESS:
      return initialAfterSignInRedirectRoute
    default:
      return state;
  }
};

export default authRedirectRoute;
import React from 'react';

import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import RemoveIcon from '@material-ui/icons/Remove';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Period } from '../../dataObjects/space'

import Calendar from 'react-calendar'
import LoadingComponent, { LoadingComponentProps, LoadingStyleProps } from 'components/LoadingComponent';

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'start',
    alignContent: 'center'
  },
  grouped: {
    width: '130px',
    height: '70px',
    margin: theme.spacing(1),
    border: '1px solid #ececec',
    padding: theme.spacing(0, 1),

    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
      marginLeft: theme.spacing(1),
      border: '1px solid #ececec',
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },    
  },
}))(ToggleButtonGroup);


const StyledToggleButton = withStyles(theme => ({
  root: {
    fontSize: '1.4rem',
    color: '#000000',
    backgroundColor: '#FFFFFF',
    '&$disabled': {
      color: '#000000',
      backgroundColor: '#ECECEC'
    },
    '&$selected': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main      
    }
  },
  disabled: {

  },
  selected: {

  }  
}))(ToggleButton);


interface StyleProps {
  container: BaseCSSProperties,
  root: BaseCSSProperties,
  description: BaseCSSProperties,
  paper: BaseCSSProperties,
  periodlabel: BaseCSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    root: {
      width: '100%',
      borderColor: '#ececec',
      'tile--active': {
        backgroundColor: theme.palette.primary.main
      }
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    paper: {
      display: 'flex',
      border: `1px solid ${theme.palette.divider}`,
      flexWrap: 'wrap',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    periodlabel: {
      fontSize: '0.8em',
      fontWeight: 'bold'
    },
    status_img: {
			maxWidth: '100%',
    	height: 'auto',
		},
		notVacancy: {
			color: '#050505'
		},
		alittle: {
			color: '#f5ad42'
		},
		allowReserve: {
			color: '#42b6f5'
		},
    
  })
)

export interface DayContent {
  isClose: boolean,
  text: string,
  value: number
}

interface OwnProps {
  baseDate: Date,
  minDate: Date,
  maxDate: Date,
  monthDays: { [key: string]: DayContent },
  periods?: Period[],
  periodsLoading: boolean
}

export interface ScheduleCalendarAction {
  onChangeDate: (date: Date | Date[]) => void,
  onChangeSelectedPeriod: (event: any, value: any) => void
}

export type ScheduleCalendarProps = OwnProps & ScheduleCalendarAction


const ScheduleCalendar : React.FC<ScheduleCalendarProps> = (props: ScheduleCalendarProps) => {
  const classes = useStyles({} as StyleProps)
  const formatter = new Intl.NumberFormat('ja-JP')

  let selectedPeriodValues = []
  if (props.periods) {
    props.periods.map(p => {
      if (p.selected) {
        selectedPeriodValues.push(p.id)
      }
    })
  }
  console.log({ selectedPeriodValues: selectedPeriodValues })

  const createStatusIcon = (status: number) => {
		if(status === 0) {
			return (
				<RemoveIcon className={classes.status_img + " " + classes.notVacancy} />
			)
		} else if (status <= 0.3) {
			return (
				<ChangeHistoryIcon className={classes.status_img+ " " + classes.alittle} />
			)
		} else {
			return (
				<RadioButtonUncheckedIcon className={classes.status_img+ " " + classes.allowReserve} />
			)
		}		
  }
  
  // state の日付と同じ表記に変換
  const getFormatDate = (date: Date) => {
    return `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}`;
  }

  //日付のクラスを付与 (祝日用)
  const getTileClass = ({ date, view })=> {
    // 月表示のときのみ
    if (view !== 'month') {
      return '';
    }
    const day = getFormatDate(date);
    return (props.monthDays[day] && props.monthDays[day].isClose) ?
      'holiday' : '';
  }

  //日付の内容を出力
  const getTileContent = ({ date, view }) => {
    // 月表示のときのみ
    if (view !== 'month') {
      return null;
    }
    const day = getFormatDate(date);
    return (
      <p>
        {props.monthDays[day] ? createStatusIcon(props.monthDays[day].value) : ' ' }
        <br />
        {(props.monthDays[day] && props.monthDays[day].text) ?
          props.monthDays[day].text : ' '
        }
      </p>
    );
  }

  const trimSeconds = (time: string) => {
    if(time) {
      return time.substring(0, time.lastIndexOf(':'))
    }
    return time
  }

  const handlePeriodSelected = (event, value) => {
    props.onChangeSelectedPeriod(event, value)
  }

  const createToggleButton = (data: Period) => {
    if (data.reserved === '0' || data.reserved === '2') {
      return (
        <StyledToggleButton key={data.id} value={data.id} aria-label="bold" selected={data.selected || data.reserved === '2'}>
          <Grid container spacing={1} direction="column" alignItems="center" justify="center">
            <Grid item ><span className={classes.periodlabel}>{trimSeconds(data.startTime)} ～ {trimSeconds(data.endTime)}</span></Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center'}}><span className={classes.periodlabel}>￥{formatter.format(data.tax_included_price)}</span></Grid>
          </Grid>
        </StyledToggleButton>
      )
    } else {
      return (
        <StyledToggleButton key={data.id} value={data.id} aria-label="bold" disabled>
          <Grid container spacing={1} direction="column" alignItems="center" justify="center">
            <Grid item><span className={classes.periodlabel}>{trimSeconds(data.startTime)} ～ {trimSeconds(data.endTime)}</span></Grid>
            <Grid item><RemoveIcon /></Grid>
          </Grid>
        </StyledToggleButton>
      )
    }
  }

  const loadingProps: LoadingComponentProps = {
    size: 30,
    style: {
      progress: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        position: 'relative'
      }
    } 
  }

  return (
    <>
      <Typography variant="h3" className={classes.subtitle}>
        利用希望日を選択してください
      </Typography>

      <div className={classes.container}>
        <Calendar className={classes.root}
          locale="ja-JP"
          value={props.baseDate}
          minDate={props.minDate}
          maxDate={props.maxDate}
          tileClassName={getTileClass}
          tileContent={getTileContent}
          onChange={props.onChangeDate}
        />
      </div>

      <Typography variant="h3" className={classes.subtitle}>
        利用希望時間枠を選択してください
      </Typography>
      
      <Paper elevation={0} className={classes.paper}>
        { props.periodsLoading ? (
          <LoadingComponent {...loadingProps} />
        ) : (          
          <StyledToggleButtonGroup
            size="large"
            aria-label="period"
            value={selectedPeriodValues}
            onChange={handlePeriodSelected}
          >
            {
              props.periods && props.periods.map(period => (
                createToggleButton(period)
              ))
            }
          </StyledToggleButtonGroup>       
        ) 
        }
        
      </Paper>
    </>
  )
}

export default ScheduleCalendar
import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BasePageContainer from 'containers/BasePageContainer';
import { Container } from '@material-ui/core';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    legend: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },    
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
  })
)



const OperationPolicyPage: React.FC = () => {
  const classes = useStyles({} as StyleProps)
  

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            サービス運営ポリシー
          </Typography>

          <Typography variant="h3" className={classes.legend}>
            プライバシーポリシー
          </Typography>

          <p className={classes.description}>
          Shareal（以下、「当サイト」という。）は、「個人情報の保護に関する法律」に基づき、お客様やお取引先様を始めとする全ての個人情報の適切な保護と利用に関する取組方針(プライバシーポリシー)を公表し、このポリシーのもと個人情報を適切に取扱います。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
          1. 個人情報の定義
          </Typography>

          <p className={classes.description}>
          本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
          ２. 個人情報の利用目的
          </Typography>

          <p className={classes.description}>
            当サイトは、ご本人の同意を得た場合、及び法令により例外として認められている場合を除き、取得した個人情報を、下記利用目的又は取得の際に予め明示した目的においてのみ利用致します。<br />
            <br />
            (1)顧客情報<br />
            以下の利用目的において利用致します。<br />
            <br />
            1.新製品・サービス・キャンペーン等の郵便、電子メールによる情報の提供 <br />
            2.お問い合わせ、ご相談への対応及び記録の保管 <br />
            3.当サイトの改善に役立てるため <br />
            4.当サイトのサービスに関連して、個人を識別できない形式に加工したうえで統計データとして利用するため <br />

            <br />
            (2)お取引先様情報<br />
            業務上の連絡・対応、契約関係の管理、その他当サイトの商品・サービスに関するご案内送付の目的で利用致します。<br />

          </p>

          <Typography variant="h3" className={classes.subtitle}>
            ３. 安全管理措置の実施
          </Typography>

          <p className={classes.description}>
            当社サイトは、個人情報に関する規程に則り、個人情報へのアクセス管理、個人情報の持ち出し手段の制限を適正に実施するとともに、外部からの不正アクセス、個人情報漏えい、滅失又は毀損等の防止のための対策を実施します。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            ４．継続的改善
          </Typography>

          <p className={classes.description}>
            当社サイトは、個人情報の取り扱いについて全ての役員及び従業員に対して教育活動を実施する他、管理責任者を設置し適切な管理を行います。また、個人情報に関して適用される法令、規範を遵守するとともに、適切な運用が実施されるよう管理と必要な是正を継続的に行います。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            ５．訂正・利用停止・削除
          </Typography>

          <p className={classes.description}>
            当社サイトは個人情報に関する本人情報の訂正、利用停止、削除を、お客様やお取引先様より求められた場合には、お申し出いただいた方がご本人であることを確認したうえで、個人情報に関する本人の利益を尊重しすみやかに対応させていただきます。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            ６．個人情報の第三者提供
          </Typography>

          <p className={classes.description}>
            当社サイトは、個人情報について、あらかじめ本人の同意を得ることなく業務委託先以外の第三者（又は共同利用が認められている第三者）に提供することはありません。但し、法令で認められている以下の例外に該当する場合はこの限りではありません。<br />
            <br />
            (1)法令に基づく場合 <br />
            (2)人の生命、身体または財産の保護のため必要があり、かつ、本人の同意を得ることが困難な場合 <br />
            (3)公衆衛生の向上のために特に必要な場合であって、本人の同意を得ることが困難であるとき <br />
            (4)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力をする必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </p>

          <Typography variant="h3" className={classes.subtitle}>
          ７．プライバシーポリシーの変更
          </Typography>

          <p className={classes.description}>
            (1)本ポリシーの内容は，事前に通知することなく，変更することができるものとします。<br />
            (2)当サイトが別途定める場合を除いて，変更後のプライバシーポリシーは，当サイトに掲載したときから効力を生じるものとします。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
          ８．個人情報に関するお問い合わせ窓口
          </Typography>

          <p className={classes.description}>
            当サイトが保有している個人情報に関するお問合せは、以下の窓口にて受け付けております。<br />
            <br />
            株式会社川上ビル<br />
            兵庫県神戸市北区鈴蘭台西町４丁目９番１５号<br />
            電話：078-591-3149 <br />
            受付時間：9:00～18:00 <br />
            休日：土日祝日（夏期・冬期休業あり）<br />
            <br />
            <Link to={routes.inquiry.path}>お問い合わせフォーム</Link>
          </p>


          <Typography variant="h3" className={classes.legend}>
            キャンセルポリシー
          </Typography>


          <Typography variant="h3" className={classes.subtitle}>
          ユーザー様の都合でキャンセルした場合
          </Typography>

          <p className={classes.description}>
          &nbsp;&nbsp;利用申込後、やむをえない理由によりご予約をキャンセルされる場合は、すみやかに当社Sharealホームページ(<Link to={routes.userReservationList.path}>https://shareal.jp/user/reservation/list</Link>)よりキャンセル手続きをお願い致します。キャンセル手数料は以下の通りです。<br />
          <br />
          <table className={classes.table}>
            <thead></thead>
            <tbody>
              <tr>
                <th>ご利用日の前日まで</th>
                <td>キャンセル料なし<br />(但し、キャンセルの頻度があまりに高いお客様は利用資格を剥奪することがあります)</td>
              </tr>
              <tr>
                <th>ご利用日当日</th>
                <td>ご利用料金の１００％</td>
              </tr>
            </tbody>
          </table>
          </p>


          <Typography variant="h3" className={classes.subtitle}>
          その他の要因で利用できなくなった場合
          </Typography>

          <p className={classes.description}>
          天災や事故及びレンタルスペース利用に伴う人身事故などの事件、事故等、お客様の責に帰さない事由においてレンタルスペースを利用していただけない場合はキャンセル料は発生致しません。その場合は「ご利用の手引き」に記載の緊急連絡先までご連絡ください。
          </p>


        </div>

      </Container>

    </BasePageContainer>
  )
}

export default OperationPolicyPage
import React, { useEffect } from 'react'
import { routes } from '../routes/Route';
import { RouteComponentProps } from 'react-router-dom'
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { fetchSpaceInfoAction, fetchSpaceWeeklyScheduleStatusAction, resetSelectedDateAction } from '../actions/spacePageDataAction'
import SpacePage, { SpacePageProps } from '../pages/SpacePage'
import { observeShownAction } from 'actions/pagingAction';
import { isIE } from 'react-device-detect';
import { showRestrictDialog } from 'actions/browserRestrictActions';
import Slider from 'react-slick'
import moment from 'moment';


interface OwnProps {
}

export type SpacePageContainerProps = OwnProps & RouteComponentProps<{space_id?: string}>

const appStateSelector = (state: AppState) => state

export default function SpacePageContainer(props: SpacePageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()
      
  const fromDate = new Date()
  const toDate = new Date()
  toDate.setDate(toDate.getDate() + 13)

  const calendarLink = (day: string) => {
    console.log('SpacePageContainer::calendarLink ' + day)
    dispatch(push(routes.spaceSchedule_exact.getPath(state.viewingSpace.space_info.space_id.toString(), day)))
  }

  const linkTo = (route: string) => {
    console.log('SpacePageContainer::linkTo ' + route)
    dispatch(push(route))
  }

  const initSlider = (slider: Slider) => {
    if(slider) {
      slider.slickGoTo(0)
    }
  }

  useEffect(() => {
    const space_id = props.match.params.space_id    
    dispatch(resetSelectedDateAction())
    dispatch(fetchSpaceInfoAction(space_id))
    dispatch(fetchSpaceWeeklyScheduleStatusAction(space_id, fromDate, toDate))
    if(isIE && state.browserRestrict.shouldShowRestrictDialog) {
      // ブラウザ非対応
      dispatch(showRestrictDialog())
    }
    dispatch(observeShownAction());
  }, []);

  const days = state.viewingSpace.weeklyScheduleStatus.map((dayStatus, i) => {
    const mdate = moment(dayStatus.day)
    return { day: dayStatus.day ? mdate.format('YYYY-MM-DD') : '', status: dayStatus.occupancyRate}

  })

  const _props: SpacePageProps = {
    space_id: parseInt(props.match.params.space_id),
    spaceInfo: state.viewingSpace.space_info,
    days: days,    
    linkTo: linkTo,
    onClickDate: calendarLink,
    initSlider: initSlider
  }

  return <SpacePage {..._props} />

}
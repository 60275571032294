import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { push, CallHistoryMethodAction } from "connected-react-router"
import { routes } from '../routes/Route'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SpacePaymentPage, { SpacePaymentPageProps } from '../pages/SpacePaymentPage'
import { observeShownAction } from 'actions/pagingAction';
import { reservationBeginPaymentAction } from 'actions/spaceReservationAction';
import { fetchSettings } from 'actions/settingsAction';
import { fetchMasterAction } from 'actions/masterAction';

interface OwnProps {
}

export type SpacePaymentPageContainerProps = OwnProps & RouteComponentProps<{space_id?: string, basedate?: string}>

const appStateSelector = (state: AppState) => state

export default function SpacePaymentPageContainer(props: SpacePaymentPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // トランザクションIDがなければ時間予約まで画面を戻す
  useEffect(() => {    
    dispatch(fetchSettings())
    dispatch(fetchMasterAction()) 

    if(!state.reservationSpace.payload.reserve_id) {
      dispatch(push(routes.spaceSchedule.getPath(props.match.params.space_id)))
    }
    // 決済開始
    dispatch(reservationBeginPaymentAction());
    dispatch(observeShownAction());
  }, []);
  
  const timeRange = state.reservationSpace.payload.reserveTimeRange;

  const selectedPeriods = state.reservationSpace.payload.selectedPeriods;

  const _props: SpacePaymentPageProps = {
    space_id: state.viewingSpace.space_info.space_id,    
    space_name: state.viewingSpace.space_info.space_name,
    timerange: state.reservationSpace.payload.selectedDate.toString(),
    selectedPeriods: state.reservationSpace.payload.selectedPeriods,
    users_adult: state.reservationSpace.payload.enquete.users_adult,
    users_children: state.reservationSpace.payload.enquete.users_children,
    usage: state.reservationSpace.payload.enquete.usage,
    detail: state.reservationSpace.payload.enquete.detail,
    invoice: state.reservationSpace.payload.invoice,
    family_name: state.reservationSpace.payload.contractor.family_name,
    given_name: state.reservationSpace.payload.contractor.given_name,
    family_name_kana: state.reservationSpace.payload.contractor.family_name_kana,
    given_name_kana: state.reservationSpace.payload.contractor.given_name_kana,
    email: state.reservationSpace.payload.contractor.email,
    postal_code: state.reservationSpace.payload.contractor.postal_code,
    address_pref: state.reservationSpace.payload.contractor.address_pref,
    address_city: state.reservationSpace.payload.contractor.address_city,
    address_street: state.reservationSpace.payload.contractor.address_street,
    address_room: state.reservationSpace.payload.contractor.address_room,
    tel: state.reservationSpace.payload.contractor.tel,
    stripe_public_key: state.settings.stripe.public_key,
    client_secret: state.payment.payload.client_secret,
    onBack: () => {
      dispatch(push(routes.spaceCustomerInfo.path))
    }
  }

  return <SpacePaymentPage {..._props} />

}
import React, { SyntheticEvent } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { accessor } from "react-big-calendar/lib/utils/accessors";
import 'moment/locale/ja';
import moment from 'moment-timezone'
import { RegularEvent } from 'dataObjects/space'

import "react-big-calendar/lib/css/react-big-calendar.css";


import { createStyles, makeStyles, Theme } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar_container: {
      marginTop: theme.spacing(3),
      width: '100%',
      height: '80vh'     
    },    
  })
)

/**
 * 日時を指定したタイムゾーンに変換する
 * quoted from: https://github.com/intljusticemission/react-big-calendar/issues/118#issuecomment-301954768
 * @param {*} datetime 変換したい日時 (Date)
 * @param {*} timeZoneName タイムゾーン名(String)
 */
export const convertDateTimeToDate = (datetime, timeZoneName) => {
  const m = moment.tz(datetime, timeZoneName);
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

interface OwnProps {
  timeZone: string,
  events: RegularEvent[],
  view: string,
  defDate: Date
}

interface OwnAction {
  onNavigate: (focusDate: Date, flipUnit: any, prevOrNext: String) => void,
  onView: (view: string, date: Date) => void,
  onSelectEvent: (event: any, e: SyntheticEvent) => void
}

export type RegularCalendarProps = OwnProps & OwnAction

const RegularCalendar: React.FC<RegularCalendarProps> = (props: RegularCalendarProps) => {  
  const classes = useStyles({})

  const localizer = momentLocalizer(moment) // or globalizeLocalizer

  const { events, timeZone, view, defDate } = props

  const navigate = (focusDate, flipUnit, prevOrNext) => {
    props.onNavigate(focusDate, flipUnit, prevOrNext)
  }

  const changeView = (view: string, date: Date) => {
    props.onView(view, date)
  }

  const selectedEvent = (event: any, e: SyntheticEvent) => {
    props.onSelectEvent(event, e)
  }

  const Event = ({ event }) => {
    const start_time = moment(event.start).format("HH:mm")
    return (
      <span>        
        <span>{start_time}</span>
        {event.title && '  ' + event.title}
      </span>
    )
  }

  const EventDay = ({ event }) => {
    return (
      <span style={{lineHeight: 1.8}}>        
        <span>{event.name}</span>
        {event.title && '  ' + event.title}
      </span>
    )
  }

  const EventWeekly = ({ event }) => {
    return (
      <span style={{lineHeight: 1.8}}>        
        <strong>{event.name}</strong>
        {event.title && '  ' + event.title}
      </span>
    )
  }
  
  const EventAgenda = ({ event }) => {
    return (
      <span style={{lineHeight: 1.8}}>
        <em style={{ color: '#222' }}>{event.name}</em>
        <p>{event.title}</p>
      </span>
    )
  }
  
  const customDayPropGetter = date => {
    return {}
  }
  
  const customSlotPropGetter = date => {
    return {}
  }

  const startAccessor = event => {
    return convertDateTimeToDate(accessor(event, "start"), timeZone)
  }

  const endAccessor = event => {
    return convertDateTimeToDate(accessor(event, "end"), timeZone)
  }

  const dt = new Date()
  dt.setDate(1)

  return (
    <Calendar
      className={classes.calendar_container}
      events={events}
      localizer={localizer}
      culture='ja-JP' 
      defaultDate={defDate}
      defaultView={view}
      dayPropGetter={customDayPropGetter}
      slotPropGetter={customSlotPropGetter}
      startAccessor={startAccessor} // startAccessorはタイムゾーンを動的に変化させるため、関数をセット
      endAccessor={endAccessor} // endAccessorはタイムゾーンを動的に変化させるため、関数をセット    
      messages={{'today': "今日", "previous":'前', "next":"次", 'month':"月表示", 'week':"週表示", 'day':"日表示", 'agenda':"アジェンダ", 'date':"日付", 'time':"時間", 'event':"イベント"}}    
      components={{
        event: Event,
        week: {
          event: EventWeekly
        },
        day: {
          event: EventDay
        },
        agenda: {
          event: EventAgenda,
        }
      }}
      onNavigate={navigate}
      onView={changeView}
      onSelectEvent={selectedEvent}
      popup={true}
    />
  )

}


export default RegularCalendar
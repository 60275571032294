import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SpaceSchedulePage, { SpaceSchedulePageProps } from '../pages/SpaceSchedulePage'
import { fetchSpaceInfoAction } from '../actions/spacePageDataAction'
import { toDateNonDelimiter } from '../utils/convertor'
import { observeShownAction } from 'actions/pagingAction';
import { reservationSteteResetAction, fetchReservation } from 'actions/spaceReservationAction';
import { fetchSettings } from 'actions/settingsAction';
import { fetchMasterAction } from 'actions/masterAction';

interface OwnProps {
}

export type SpaceSchedulePageContainerProps = OwnProps & RouteComponentProps<{space_id?: string, basedate?: string}>

const appStateSelector = (state: AppState) => state

export default function SpaceSchedulePageContainer(props: SpaceSchedulePageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // スペースページで読み込まれていなければ初期化
  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchMasterAction()) 

    if(state.viewingSpace.hasLoadSpaceInfo === false) {
      console.log('SpaceSchedulePage useEffect fetchSpaceInfo')
      dispatch(fetchSpaceInfoAction(props.match.params.space_id))
    }
    // 予約キャンセル及び完了のデータが残って入れば初期化
    if(state.reservationSpace.payload.status == '3' 
      || state.reservationSpace.payload.status == '9' ) {
        dispatch(reservationSteteResetAction())
    } else if(state.reservationSpace.payload.reserve_id) {
      // 予約IDがあれば予約データ再読み込み
      dispatch(fetchReservation(state.reservationSpace.payload.reserve_id, null))
    }
    dispatch(observeShownAction())
  }, []);
  

  const _props: SpaceSchedulePageProps = {
    space_id: parseInt(props.match.params.space_id),
    space_info: state.viewingSpace.space_info,
    basedate: toDateNonDelimiter(props.match.params.basedate),
  }

  return <SpaceSchedulePage {..._props} />

}
import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError } from 'redux-form'
import InquiryForm, { validate } from '../components/Inquiry/InquiryForm'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import {
  commonInquiryPostAction  
} from '../actions/inquieryAction'
import { Inquiry } from '../dataObjects/inquiry';

interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function InquiryFormContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      const inquriy: Inquiry = {
        name: values['name'],
        email: values['email'],
        content: values['content']
      }
      const payload: Inquiry = inquriy
      ownDispatch(commonInquiryPostAction(payload))
    }
  }

  const _props = {
    onSubmit: submit    
  }

  return <InquiryForm {..._props} />

}

export interface ValidationError {
  [key: string]: {[key: number]: any} | any
}

export interface RequestValidationResult {
  status: string,
  summary: string,
  errors: ValidationError  
}

export class RequestValidationError 
{
  public data: RequestValidationResult
  constructor(data) {
    if(data.errors) {
      const errors: ValidationError = {}
      for (let key of Object.keys(data.errors)) {
        const keyArray = key.split('.')
        let prevLevelError = {}
        for (let idx = keyArray.length - 1; idx >= 0; idx--) {
          const curLevelError = {}
          if(idx == keyArray.length - 1) {
            // 終端
            if(data.errors[key].length == 1){
              const errorMessage = data.errors[key][0]
              curLevelError[keyArray[idx]] = errorMessage
            } else {
              curLevelError[keyArray[idx]] = data.errors[key]
            }
            prevLevelError = curLevelError
          } else if(idx > 0) {
            curLevelError[keyArray[idx]] = prevLevelError
            prevLevelError = curLevelError
          }

          if (idx === 0) {
            const err = errors[keyArray[idx]]
            if(err) {
              errors[keyArray[idx]] = { ...err, ...prevLevelError }
            } else {
              errors[keyArray[idx]] = prevLevelError
            }
          }
        }
      }
      data.errors = errors
    }
    this.data = data

    console.log('RequestValidationError constructor')
    console.log({data})
  }
}
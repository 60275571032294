import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SignIn, { SignInProps, validate } from '../components/Auth/SignIn'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { Values, Errors } from '../types/formTypes'

import { sharealLoginAction, socialLoginAction } from '../actions/authenticationActions'
import { useCookies } from "react-cookie";
import { ValidationError } from 'errors/RequestValidationError';
import { serverValidationClear } from 'actions/errorAction';

interface OwnProps {  
}

export type SignInContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

const CN_FORM_NAME = 'SignIn'

export default function SignInContainer(props: SignInContainerProps) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()
  const [cookies, setCookie] = useCookies();

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      ownDispatch(serverValidationClear())
      setIsDisplay(false)
      ownDispatch(sharealLoginAction(values['email'], values['password']))
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation(CN_FORM_NAME, errors))
      setIsDisplay(true)
    }
  } 


  // 初期化
  useEffect(() => {
    //dispatch(fetchPeriodAtDayAction(baseDate))
  }, []);
    
  const _props = {    
    onSubmit: submit,  
    googleClick: () => {
      ownDispatch(socialLoginAction("google", cookies, setCookie));
    },
    facebookClick: () => {
      ownDispatch(socialLoginAction("facebook", cookies, setCookie));
    },
    validationResult: state.ss422ValidationErrorResult,
    ss422Validation: showValidationError,
  }

  return <SignIn {..._props} />

}

export interface StartAndEndDateTuple {
  startDate: Date,
  endDate: Date
}

export const getFirstDayAtWeek: (currentDate: Date) => Date = (currentDate) => {  
  const wkSE = getStartAndEndDateOfWeek(currentDate)
  return wkSE.startDate;
}

export const getLastDayAtWeek: (currentDate: Date) => Date = (currentDate) => {
  const wkSE = getStartAndEndDateOfWeek(currentDate)
  return wkSE.endDate;
}

export const getStartAndEndDateOfWeek: (currentDate: Date) => StartAndEndDateTuple = (currentDate) => {
  const currentWeekDay = currentDate.getDay();
  const wkStart = new Date(new Date(currentDate).setDate(currentDate.getDate() - currentWeekDay));
  const wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));

  return {
    startDate: wkStart,
    endDate: wkEnd
  }
}
import { ActionTypes } from '../actions/actionTypes'
import { SpaceReservation, ReservationValidationError } from '../dataObjects/space';
import {
  RESERVATION_PERIOD_START,
  RESERVATION_PERIOD_INVALID,
  RESERVATION_CONTRACTOR_START,
  RESERVATION_CONTRACTOR_INVALID,
  RESERVATION_BEGIN_PAYMENT_START,
  RESERVATION_BEGIN_PAYMENT_INVALID,
  RESERVATION_STATE_RESET,
  RESERVATION_CANCEL_INVALID,
  RESERVATION_CANCEL_START,
  RESERVATION_PAYMENT_START,
  RESERVATION_PAYMENT_INVALID
} from '../actions/spaceReservationAction';
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';
import { LOGIN_INVALID } from 'actions/authenticationActions'


export interface ReservationValidationErrorListState {
  message: string,
  errors: ReservationValidationError[]
}

const initialReservationValidationErrorListState: ReservationValidationErrorListState = {
  message: '',
  errors: []
}


const validationError = (
  state: ReservationValidationErrorListState = initialReservationValidationErrorListState,
  action: ActionTypes,
): ReservationValidationErrorListState => {
  switch (action.type) {
    // コマ予約
    case RESERVATION_PERIOD_START:
      return { ...state };
    case RESERVATION_PERIOD_INVALID:
      return { ...state, ...action.payload }
    // 契約者情報
    case RESERVATION_CONTRACTOR_START:
      return { ...state };
    case RESERVATION_CONTRACTOR_INVALID:
      return { ...state, ...action.payload }
    // 決済処理
    case RESERVATION_BEGIN_PAYMENT_START:
      return { ...state }
    case RESERVATION_BEGIN_PAYMENT_INVALID:
      return { ...state, ...action.payload }
    case RESERVATION_PAYMENT_START:
      return { ...state }
    case RESERVATION_PAYMENT_INVALID:
      return { ...state, ...action.payload }
    case RESERVATION_CANCEL_START:
      return { ...state }
    case RESERVATION_CANCEL_INVALID:
      return { ...state, ...action.payload }
    case LOGIN_INVALID:
        return { ...state, ...action.payload }
    case RESERVATION_STATE_RESET:
      return { ...state }    
    case LOGOUT_SUCCESS:
      return { ...state }
    default:
      return { ...state };
  }
};

export default validationError
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 300,
      maxWidth: 400,
      flex: 1
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    description: {
      whiteSpace: 'pre-line'
    },
    link: {
      fontSize: '1.4rem',
      color: '#26A78E',
      marginLeft: theme.spacing(1)
    }
  }),
);


interface OwnProps {
  id: number,
  title: string,
  name: string,
  image: string,
  description: string,
  link?: string
}

type RegularOwnerCardProps = OwnProps

const RegularOwnerCard: React.FC<RegularOwnerCardProps> = (props: RegularOwnerCardProps) => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={props.title}
        subheader={props.name}
      />
      <CardMedia
        className={classes.media}
        image={`${process.env.REACT_APP_API_SERVER_HOST}storage/images/regular/${props.id}/${props.image}`}
        title=""
      />
      <CardContent>
        <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
          {props.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {props.link ? (
          <a className={classes.link} href={props.link} target="_blank">関連サイト&nbsp;<OpenInNewIcon fontSize="small" /></a>
         ) : ('')
        }
      </CardActions>
    </Card>
  );
}

export default RegularOwnerCard
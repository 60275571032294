import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';
import { routes } from '../routes/Route';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';

import { Link, LinkProps } from 'react-router-dom';
import InquiryFormContainer from 'containers/InquiryFormContainer';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    link: {
      color: '#26A78E'
    }
  })
);

interface OwnProps {

}

export type InquiryPageProps = OwnProps;

const InquiryPage: React.FC<InquiryPageProps> = (props: InquiryPageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <Typography variant="h3" className={classes.subtitle}>
          お問い合わせ
        </Typography>

        <Typography variant="body1" className={classes.description}>
          お問い合わせ前に <Link className={classes.link} to={routes.faq.path}>よくある質問ページ</Link> をご確認いただければ、お客様のお問い合わせ内容に対する解決策が見つかるかもしれません。ぜひご活用ください。<br /><br />
          受付時間：月曜日〜金曜日 9時〜18時 (土日祝・年末年始は翌営業日での確認となります)<br />
          お名前、返信用メールアドレス、お問い合わせ内容をご記入し送信ボタンを押下してください。<br />
          ユーザー登録されている方はログイン後に問い合わせ送信していただくと、履歴が残り便利です。<br />
        </Typography>
        
        <InquiryFormContainer />

      </Container>

    </BasePageContainer>

  );

}

export default InquiryPage
import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import BasePageContainer from 'containers/BasePageContainer';
import Linkify from 'react-linkify'
import { Container, Button } from '@material-ui/core';
import { News, NewsAttachmentFile } from 'dataObjects/news';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    legend: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },    
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2),
      whiteSpace: 'pre-line'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


interface OwnProps {
  news: News,
  onBack: () => void
}

export type NewsPageProps = OwnProps

const NewsPage: React.FC<NewsPageProps> = (props: NewsPageProps) => {
  const classes = useStyles({})

  const attachmentFile = (files: NewsAttachmentFile[]) => {
    return files.map(file => {
      const href = "/news/file/" + file.id
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 12, padding: 4}}>
          <div>
            <a href={href} target="_blank">
              <DescriptionIcon style={{width: '50px', height: '50px'}} /> 
            </a>
          </div>
          <div>
            <a href={href} target="_blank">
              {file.file_name}
            </a>
          </div>
        </div>
      )
    })
  }

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            お知らせ
          </Typography>

          <Typography variant="h3" className={classes.legend}>            
            {props.news.title}
          </Typography>

          <p className={classes.description}>
            <Linkify properties={{ target: '_blank', style: { color: 'blue', fontWeight: 'bold' } }}>
              {props.news.contents}
            </Linkify>                        
          </p>

          

          <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '30px'}}>
            <Grid item xs={6}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                {attachmentFile(props.news.files)}
              </div>
            </Grid>
          </Grid> 

        </div>

      </Container>

      <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '200px'}}>
        <Grid item xs={6}>
          <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={props.onBack}>
            戻る
          </Button>
        </Grid>
      </Grid> 

    </BasePageContainer>
  )
}

export default NewsPage
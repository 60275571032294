import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { closeDialog } from 'actions/dialogAction';
import InternalErrorDialog from 'components/InternalErrorDialog/InternalErrorDialog';


interface Props {
}

const appStateSelector = (state: AppState) => state

export default function InternalErrorDialogContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    internalErrorDialogOpen: state.page.internalErrorDialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
    }
  }

  return <InternalErrorDialog {..._props} />
}

import { ActionTypes } from '../actions/actionTypes';
import { SNACK_BAR_CLOSE } from '../actions/snackBarAction';
import { SnackBarVariant } from '../components/SnackBar';
import { LOGIN_FAIL, USER_REGISTER_FAIL } from '../actions/authenticationActions';
import { RESERVATION_PERIOD_FAIL, RESERVATION_BEGIN_PAYMENT_INVALID, RESERVATION_BEGIN_PAYMENT_FAIL, RESERVATION_CANCEL_FAIL } from '../actions/spaceReservationAction'
import { PATH_NAME_CHANGE } from '../actions/locationChangeAction';
import { SS_VALIDATION_ERROR } from 'actions/errorAction';

export interface SnackBarState {
  reason: string;
  available: boolean;
  variant: SnackBarVariant;
}

const initialState: SnackBarState = {
  reason: '',
  available: false,
  variant: 'success',
};

const snackBar = (
  state: SnackBarState = initialState,
  action: ActionTypes,
): SnackBarState => {
  switch (action.type) {
    case SS_VALIDATION_ERROR:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      };
    case USER_REGISTER_FAIL:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      };
    case LOGIN_FAIL:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      };
    case RESERVATION_PERIOD_FAIL:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      }
    case RESERVATION_BEGIN_PAYMENT_FAIL:
      console.log(action.snackBarMessage)
      return {
        reason: '決済処理でエラーが発生しました。予約は完了していません。',
        available: true,
        variant: action.variant,
      }
    case RESERVATION_CANCEL_FAIL:
      console.log(action.snackBarMessage)
      return {
        reason: '予約キャンセル処理でエラーが発生しました。予約キャンセルは完了していません。',
        available: true,
        variant: action.variant,
      }
      /*
    case USER_REGISTER_FAIL:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      }
      */
    case SNACK_BAR_CLOSE:
      return initialState;
    case PATH_NAME_CHANGE:
      return initialState;
    default:
      return { ...state };
  }
};

export default snackBar;

import { ActionTypes } from '../actions/actionTypes';
import { ViewingSpace } from '../dataObjects/space'
import {
  FETCH_SPACE_INFO_START,
  FETCH_SPACE_INFO_SUCCESS,
  FETCH_SPACE_INFO_FAIL,
  FETCH_PERIOD_AT_DAY_START,
  FETCH_PERIOD_AT_DAY_SUCCESS,
  FETCH_PERIOD_AT_DAY_FAIL,
  SELECT_PERIOD,  
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START,
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS,
  FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS,
  FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL,
  RESET_SELECTED_DATE,
} from '../actions/spacePageDataAction';
import { RESERVATION_STATE_RESET } from 'actions/spaceReservationAction';


/* 閲覧中スペースステート */
export interface ViewingSpaceState extends ViewingSpace {
  hasLoadSpaceInfo: boolean
}

const initialViewingSpaceState: ViewingSpaceState = {
  hasLoadSpaceInfo: false,
  // スペース情報
  space_info: {
    space_id: 0,
    // スペース名
    space_name: '',
    // 紹介文
    description: '',
    // おすすめ利用用途
    usage: [],
    // 広さ
    area: '',
    // 収容人数
    capacity: '', 
    // 住所
    address: '', 
    // 周辺情報
    around_info: '',
    // スペース画像
    image_info: [],
    // 設備情報
    facility_info: [],
    // 地図情報
    map_info: {
      lat: 0,
      lng: 0
    },
    // アクセス方法
    access_info: {
      car: '',
      train: '' 
    },
    // 入退室方法
    entry_exit_method: '',
    // 禁止事項
    prohibited_matter: [],
    // よくある質問
    faq: [],
    term_of_service: {
      id: 0,
      contents: ''
    }
  },
  // 選択した日付
  selectedDate: undefined,
  // 選択した日付のコマ情報
  periodsAtDate: [],
  // 週単位の予約状況
  weeklyScheduleStatus: [],
  // 月単位の予約状況
  monthlyScheduleStatus: []
};

/* スペースReducer */
const viewingSpace = (
  state: ViewingSpaceState = initialViewingSpaceState,
  action: ActionTypes,
): ViewingSpaceState => {
  switch (action.type) {
    // スペース選択
    case FETCH_SPACE_INFO_START: 
      return { ...state };
    case FETCH_SPACE_INFO_SUCCESS:
      return { ...state, hasLoadSpaceInfo: true, ...action.payload };
    case FETCH_SPACE_INFO_FAIL:
      return { ...state, hasLoadSpaceInfo: false };
    // コマ情報取得
    case FETCH_PERIOD_AT_DAY_START:
      return { ...state };
    case FETCH_PERIOD_AT_DAY_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_PERIOD_AT_DAY_FAIL:
      return { ...state };    
    // スケジュールステータス取得
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_START:
      return { ...state };
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_SPACE_WEEKLY_SHCEDULE_STATUS_FAIL:
      return { ...state };    
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_START:
      return { ...state };
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_SPACE_MONTHLY_SHCEDULE_STATUS_FAIL:
      return { ...state };  
    // 選択日クリア
    case RESET_SELECTED_DATE:
      return { ...state, selectedDate: undefined }  
    // コマ選択
    case SELECT_PERIOD:
      const reflectedPeriods = state.periodsAtDate.map(p => {
        if (action.payload.periodsAtDate) {
          p.selected = action.payload.periodsAtDate.some(selp => selp.id === p.id)
        }
        return p
      })
      return {
        ...state,
        periodsAtDate: [
          ...reflectedPeriods
        ] 
      };    
    // 予約完了後のリセット
    case RESERVATION_STATE_RESET:
      return initialViewingSpaceState
    default:
      return { ...state };
  }
};

export default viewingSpace

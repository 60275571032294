import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BasePageContainer from 'containers/BasePageContainer';
import { Container } from '@material-ui/core';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)



const SctlPage: React.FC = () => {
  const classes = useStyles({} as StyleProps)
  

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            特定商取引法に基づく表示
          </Typography>

          <Typography variant="h3" className={classes.subtitle}>
            運営責任者
          </Typography>

          <p className={classes.description}>
            株式会社川上ビル　代表取締役　川上欣一
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            所在地
          </Typography>

          <p className={classes.description}>
          兵庫県神戸市北区鈴蘭台西町４丁目９番１５号
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            連絡先
          </Typography>

          <p className={classes.description}>
          電話：078-591-3149 <br />
受付時間：9:00～18:00 <br />
休日：土日祝日（夏期・冬期休業あり）
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            販売価格
          </Typography>

          <p className={classes.description}>
            各スペースページの予約処理時に表示される料金
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            代金のお支払時期および方法
          </Typography>

          <p className={classes.description}>
            クレジットカード：決済時
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            キャンセルについて
          </Typography>

          <p className={classes.description}>
            キャンセルについては<Link to={routes.operationPolicy.path}>運営ポリシー</Link>ページと各スペースページに記載しております。お申し込み前に必ずご確認ください。
          </p>

        </div>

      </Container>

    </BasePageContainer>
  )
}

export default SctlPage
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';

export interface ListTableColumn {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: any) => string;
}

/*
const columns: ListTableColumn[] = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString(),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toLocaleString(),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value: number) => value.toFixed(2),
  },
];


interface Data {
  name: string;
  code: string;
  population: number;
  size: number;
  density: number;
}
*/

/*
const rows = [
  createData('India', 'IN', 1324171354, 3287263),
  createData('China', 'CN', 1403500365, 9596961),
  createData('Italy', 'IT', 60483973, 301340),
  createData('United States', 'US', 327167434, 9833520),
  createData('Canada', 'CA', 37602103, 9984670),
  createData('Australia', 'AU', 25475400, 7692024),
  createData('Germany', 'DE', 83019200, 357578),
  createData('Ireland', 'IE', 4857000, 70273),
  createData('Mexico', 'MX', 126577691, 1972550),
  createData('Japan', 'JP', 126317000, 377973),
  createData('France', 'FR', 67022000, 640679),
  createData('United Kingdom', 'GB', 67545757, 242495),
  createData('Russia', 'RU', 146793744, 17098246),
  createData('Nigeria', 'NG', 200962417, 923768),
  createData('Brazil', 'BR', 210147125, 8515767),
];
*/


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: '500px',
    height: '500px',
    overflow: 'auto',
  },
});



interface OwnProps {
  columns: ListTableColumn[],
  data: {[key: number]: any},
  total: number,
  page: number,
  rowsPerPage: number,
  rowsPerPageOptions?: Array<number | { value: number; label: string }>
}

interface OwnAction {
  setPage: (page: number) => void,
  setRowsPerPage: (perPage: number) => void,
  selectedRow: <T>(data: T) => void
}

export type ListTableProps = OwnProps & OwnAction

const ListTable: React.FC<ListTableProps> = (props: ListTableProps) => {
  const classes = useStyles({});
  const {columns, data, total, page, rowsPerPage, rowsPerPageOptions, setPage, setRowsPerPage, selectedRow} = props;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCellClick = (data: any) => {
    selectedRow(data)
  }

  const createTaebleRow = (data: {[key: number]: any}) => {
    let elements = []
    for (let key of Object.keys(data)) {
      elements.push((
        <TableRow hover role="checkbox" tabIndex={-1} key={key} >
          {columns.map(column => {
            const value = data[key][column.id];
            return (
              <TableCell key={column.id} align={column.align} onClick={() => handleCellClick(data[key])}>
                {column.format ? column.format(value) : value}
              </TableCell>
            );
          })}
        </TableRow>
      ))
    }
    return elements
  }

  let index = 0
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {createTaebleRow(data)}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ListTable;
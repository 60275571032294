import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { Masters } from 'dataObjects/masters';
import { AxiosResponse } from 'axios';
import { SnackBarAction } from './actionTypes';

/* MasterアクションType */
export const FETCH_MASTER_START = 'FETCH_MASTER_START'
export const FETCH_MASTER_SUCCESS = 'FETCH_MASTER_SUCCESS'
export const FETCH_MASTER_FAIL = 'FETCH_MASTER_FAIL'

/* スペースデータ取得アクション */
export type FetchMasterActions =
  | FetchMasterStartAction
  | FetchMasterSuccessAction
  | FetchMasterFailAction
  | ErrorActions

type MasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchMasterActions
>


/* MASTER取得START Action */
export interface FetchMasterStartAction
  extends Action<typeof FETCH_MASTER_START> { }

const fetchMasterStartAction = (): FetchMasterStartAction => {
  return {
    type: FETCH_MASTER_START,
  }
}

/* MASTER取得成功 Action */
export interface FetchMasterSuccessAction
  extends Action<typeof FETCH_MASTER_SUCCESS> {
  payload: Masters
}

const fetchMasterSuccessAction = (payload: Masters)
  : FetchMasterSuccessAction => {
  return {
    type: FETCH_MASTER_SUCCESS,
    payload,
  }
}

/* MASTER取得失敗 Action */
export interface FetchMasterFailAction
  extends SnackBarAction<typeof FETCH_MASTER_FAIL> {
}

const fetchMasterFailAction = (
  message: string
): FetchMasterFailAction => {
  return {
    type: FETCH_MASTER_FAIL, snackBarMessage: message,
    variant: 'error' };
};


/* --------------------------------------------------------------------------------- */

/* サーバーからマスタを取得 */
async function fetchMasterData(): Promise<Masters> {  
  const periodDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/masters`);  
  const masterData: Masters = await periodDataRes.data;

  return masterData;
}

/* SiteMasterを取得する */
export function fetchMasterAction(): MasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      if (state.master.fetchErrorCount > 5) {
        console.error('state.master.fetchErrorCount over limit');
        dispatch(
          fetchMasterSuccessAction({
            facilities: [],
            items: [],
            taxratetypes: [],
            taxrates: [],
            stripesettings: { public_key: '' }
          }),
        )
      } else {
        dispatch(fetchMasterStartAction())

        const data: Masters = await fetchMasterData()

        dispatch(
          fetchMasterSuccessAction(data),
        )
      }
    } catch (err) {
      dispatch<FetchMasterActions>(
        await ajaxErrorHandler(err, fetchMasterFailAction)
      )
    }
  }
}

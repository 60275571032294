import React, { useEffect, SyntheticEvent } from 'react'
import EventDialog from "components/Regular/EventDialog";
import { RegularEvent } from "dataObjects/space"
import { AppState } from 'types/stateTypes';
import { useSelector, useDispatch } from 'react-redux';
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { closeDialog } from 'actions/dialogAction';


interface OwnPops {
}

export type EventDialogContainerProps = OwnPops;

const appStateSelector = (state: AppState) => state
export default function EventDialogContainer(
  props: EventDialogContainerProps
) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    event: state.showEvent,
    eventDialogOpen: state.page.dialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
    }
  };

  return <EventDialog {..._props} />;
}

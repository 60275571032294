import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SignUpPage, { SignUpPageProps } from '../pages/SignUpPage'
import { observeShownAction } from 'actions/pagingAction';

interface OwnProps {
}

export type SignUpPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state


export default function SignUpPageContainer(props: SignUpPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);


  return <SignUpPage />

}
import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BasePageContainer from 'containers/BasePageContainer';
import { Container } from '@material-ui/core';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: 1.8,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
    browserNotice: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px'
    },
    kaitei: {
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2),
      textAlign: 'right'
    }
  })
)



const TermsOfServicePage: React.FC = () => {
  const classes = useStyles({} as StyleProps)


  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            利用規約
          </Typography>

          <p className={classes.description}>
            株式会社川上ビル（以下「当社」といいます。）が提供するレンタルスペース「Shareal」以下「当レンタルスペース」といいます。）の利用に係る規約をここに定めます。ご利用になられる方は利用規約を遵守してください。
            本規約をお守りいただけない場合は次回からのご利用をお断りさせていただく場合がございます。予めよくお読みになり、ご理解とご了承のほど宜しくお願い致します。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１条（利用目的）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;当レンタルスペースは主に私的なカルチャースクールや会合、パーティー、会議などの目的で利用することを原則といたします。物販や飲食業等の営業目的、宗教や政治等の勧誘目的、法律に反する目的、その他、違法行為及び非常識な行為等によるご利用は固くお断りいたします。
            また、暴力団、暴力団員、暴力団関係団体またはその関係者、その他反社会的勢力に属されている方並びにその関係者またはその疑いがあると当社が判断する方のご利用はお断りいたします。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第２条（ご利用時間）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;利用時間は事前にお申し込みいただいた時間内での利用を厳守してください。利用時間延長希望の場合は必ず１０分前までに予約サイト上で延長枠の予約と決済を完了してください。次の予約枠が埋まっている場合は延長利用受付できません。
            利用時間は準備及び片付け時間を含みます。退室時間の10分前には後片付けを完了し、利用終了後は「ご利用の手引き」に基づき原状回復してください。<br />
            後片付けの確認と備品点検をご自身でしていただき、ご退室となります。退室時間になりましたら、すみやかにご退出をお願い致します。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第３条（当レンタルスペース内の映像録画）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;当レンタルスペースは管理、防犯の為に防犯カメラを複数台設置し、常時録画しております。この録画情報は通常の業務上、当社が確認する事はありませんが、当社が管理、運営上必要と判断した場合のみ再生し確認することがございます。また、この情報を第三者に漏洩する事はありません。ただし、事件、事故等により警察からの情報提供要請があった場合はこの限りでないものとします。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第４条（搬入・搬出）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;荷物などの搬入出をしていただく場合、当レンタルスペースの前面道路に車両を駐車、停車させることは厳禁です。近くの時間貸し駐車場等をご利用ください。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第５条（スペース内での喫煙について）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;当レンタルスペースは電子タバコを含め、全面禁煙となっております。喫煙された場合は火災警報機が検知する可能性があり、その場合ビル全体の火災警報装置が鳴り響きます。ご注意ください。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第６条（当レンタルスペース利用制限及び禁止行為）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;利用申込者は、第三者に当レンタルスペースの利用権の全部または一部の譲渡あるいは転貸することはできません。利用申込者が、この規約に反し当レンタルスペースの利用権の全部または一部の譲渡あるいは転貸した場合、直ちに利用を停止し、今後一切の利用資格を剥奪いたします。また、当社に損害が発生した場合はその損害額を全額賠償していただきます。<br />
            利用申し込み受付後、または利用途中においても、次の場合は当社の判断で申込の取り消しや利用停止の措置をとる場合があります。この場合において、利用料金の返金は行わないものとし、かつ利用者に生じるいかなる損害に対しても当社は一切の責任を負いません。<br />
            <br />
            ①	申込時の利用目的と実際の利用内容が著しく異なる場合。<br />
            ②	利用申込書の内容に、嘘、偽りがあると認められる場合。<br />
            ③	管理上、もしくは風紀上好ましくないと認められる場合。<br />
            ④	許認可もしくは資格が必要な内容での利用を、許認可もしくは資格がない状態で当スペースを利用する場合。<br />
            ⑤	当レンタルスペース前面（当該店舗の出入り口より外）で作業や催事行為（撮影、掲示、印刷物の配布、募金行為、販売行為、各種勧誘等）をした場合。<br />
            ⑥	暴力行為、反社会的行為、及びそれらの活動、または業務内容が不明確な団体または個人が主催、協賛及び後援等を行う場合。<br />
            ⑦	当レンタルスペースを違法若しくは社会通念上不当な目的で使用し、又は使用しようとする場合。<br />
            ⑧	危険物等の持込、または危険物の持込等による人身事故、建物・レンタルスペース・備品等を破損、汚損、紛失した場合。<br />
            ⑨	展示及び装飾等、会場内に釘、鋲、アンカーを打ったり、許可なく糊・強粘着テープ等を貼った場合、及びその他当社の承諾なく当レンタスペースに改変を加えた場合。<br />
            ⑩	音、振動、臭気の発生等により、周囲の店舗や近隣住民に迷惑を及ぼす、またはその恐れがある場合。<br />
            ⑪	来場者数が当レンタルスペースの許容範囲を超え、周囲に迷惑を及ぼすと当社が判断した場合。<br />
            ⑫	当レンタルスペース前面道路や近隣建物敷地、路上等にバイク、自転車を駐輪、または自動車を駐車した場合。<br />
            ⑬	当社からの注意に従わず、これが本規約に違反すると当社が判断した場合。<br />
            ⑭	未成年のみのご利用の場合（未成年のご利用は保護者、責任者を同伴してください。）<br />
            ⑮	当レンタルスペース内で喫煙が発覚した場合（当レンタルスペースは全面禁煙。）<br />
            ⑯	その他、当レンタルスペースの管理運営上、支障があると当社が判断した場合。<br />
          </p>


          {/* <Typography variant="h3" className={classes.subtitle}>
            （利用許諾の取り消し）
          </Typography> */}

          <p className={classes.description}>
            &nbsp;&nbsp;利用の有無にかかわらず、本規約に反すると当社が判断した場合は、利用を取り消します。また、その場合は受領した利用料の返金は致しません。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第７条（予約後のキャンセル）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;利用申込後、やむをえない理由によりご予約をキャンセルされる場合は、すみやかに当社Sharealホームページ(<Link to={routes.userReservationList.path}>https://shareal.jp/user/reservation/list</Link>)よりキャンセル手続きをお願い致します。キャンセル手数料は以下の通りです。<br />
            <br />
            <table className={classes.table}>
              <thead></thead>
              <tbody>
                <tr>
                  <th>ご利用日の前日まで</th>
                  <td>キャンセル料なし<br />(但し、キャンセルの頻度があまりに高いお客様は利用資格を剥奪することがあります)</td>
                </tr>
                <tr>
                  <th>ご利用日当日</th>
                  <td>ご利用料金の１００％</td>
                </tr>
              </tbody>
            </table>
            <br />
            ただし、天災や事故及びレンタルスペース利用に伴う人身事故などの事件、事故等、お客様の責に帰さない事由においてレンタルスペースを利用していただけない場合はキャンセル料は発生致しません。その場合は「ご利用の手引き」に記載の緊急連絡先までご連絡ください。<br />
            また、定期利用のお客様に関しては解約のご連絡をいただいた翌月末でのご解約となります。解約月の日割計算はできません。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第８条（利用料金のお支払い）
          </Typography>

          <p className={classes.description}>
            &nbsp;&nbsp;ご利用料金のお支払いは当社ホームページ上よりクレジットカードでの一括決済のみとさせて頂きます。また、予約された日から利用日当日までの間、クレジットカード決済は行われず、定期的に与信確保処理を自動で行っております。<br />
            その為、利用日までの間に何らかの事情で与信確保ができない場合（限度額超過や有効期限切れ等）はご登録のメールアドレスにご連絡させていただきます。<br />
            その場合は、メールのリンク先から利用可能な他のクレジットカードにて手続きをお願いしております。もし、与信確保処理が行えず、利用日当日までに再手続きが行われない場合、予約はキャンセルとなります。
            また、定期利用ご希望のお客様に関しては、クレジットカード以外の支払い方法も別途ご相談させていただきます。

          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第９条（免責及び損害賠償）
          </Typography>

          <p className={classes.description}>
            ・当施設利用中の展示物及び利用者、参加者がお持ち込みになられた物（貴重品を含む）等の盗難、破損事故については、その原因の如何を問わず当社は一切の責任を負いません。<br />
            ・天災や事故及びレンタルスペース利用に伴う人身事故ならびに紛失、盗難等のあらゆる事件事故等、当社の責に帰さない事由によりやむを得ずレンタルスペース利用が停止もしくは遅延となった場合でも、当社は利用者に生じた損害を賠償する責は負いません。<br />
            ・当レンタルスペース内外の建造物、設備、什器、備品等を破損、汚損、紛失させた場合には、その損害について全額賠償請求いたします。<br />
            ・当社の責に帰すべき事由により、利用申込者が損害を被り、その損害の賠償を当社に請求した場合は、受領した料金を限度として賠償するものとします。ただし、利用申込者の損害の内、機会損失等の逸失利益については、その損害の責任を負いません。<br />
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１０条（安全管理）
          </Typography>

          <p className={classes.description}>
            ・当レンタルスペース利用中はご利用者側の責任の下に防災・防犯等の安全管理を行ってください。また、当レンタルスペース利用中は利用責任者が常駐してください。<br />
            ・利用者は来場者や関係者の安全のために、利用方法等を予め熟知してください。<br />
            ・当レンタルスペースの保全管理のために当社が必要と判断した場合は、ご利用中であってもレンタルスペース内に立ち入ることがありますので、予めご了承ください。<br />
            ・盲導犬、介助犬以外の動物の入場はご遠慮ください。<br />
            ・防犯上、必要と判断した場合には、使用中であっても荷物や機材等の移動をお願いする場合がございますので、ご了承ください。<br />
            ・当レンタルスペース内には、危険物の持ち込みは一切できません。<br />
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１１条（案内状等の掲示物の設置）
          </Typography>

          <p className={classes.description}>
            ・当レンタルスペースにおける案内状、広告物、会場誘導板等の掲示は基本的に禁止しております。<br />
            ・定期利用契約のお客様で誘導板等のご利用をご希望の場合は事前にご相談ください。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１２条（利用後の原状回復）
          </Typography>

          <p className={classes.description}>
            ・利用終了後は利用前の状態まで原状回復してください。<br />
            ・利用していただいた上で出た残材やゴミは必ずお持ち帰りください。<br />
            ・当レンタルスペース内外の建造物・設備・什器備品等を破損、汚損、紛失させた場合は、必ず「ご利用の手引き」に記載の緊急連絡先までご連絡ください。
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１３条（キッチン利用について）
          </Typography>

          <p className={classes.description}>
            ・飲食物の販売目的でキッチンを利用される事は固くお断りいたします。<br />
            ・当キッチンで調理されたものを当スペース外へ持ち出すことはできません。<br />
            ・熱機器や刃物等のお取り扱いには十分にご注意ください。また、調理中の事故、怪我、食中毒、子供のイタズラなどのトラブルに関しても当社は一切の責任を負いかねますので、安全、衛生面にも十分にご配慮ください。<br />
            ・キッチン、調理器具等ご使用後は必ず綺麗に洗浄後に布巾等で水分を十分に取った後、所定の位置に片付けてください。<br />
            ・油をご使用の場合は、必ず油を固める溶剤をご用意ください。<br />
            ・加熱調理中は必ず換気を行い、火傷等の怪我に十分にご注意ください。<br />
            ・キッチン用洗剤、スポンジ、たわし等の清掃・洗浄用具、その他の消耗品（オーブンペーパー、ラップ、キッチンペーパー、ふきん、アルミホイル、調味料等）は全てご利用者様がご用意ください。<br />
            ・下記の様な調理はご遠慮ください。<br />
            &nbsp;&nbsp;①	調理時や調理後に強い匂いを発するもの、残臭の強い調理<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;例）匂いの強い魚介類、並びにその干物の加熱調理<br />
            &nbsp;&nbsp;②室内に油や汚れが飛散したり付着したりする調理
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            第１４条（片付けについて）
          </Typography>

          <p className={classes.description}>
            ・調理器具と食器の洗浄は必ず時間に余裕を持って行ってください。<br />
            ・IH機器やオーブンレンジ、調理台の汚れは全て拭き取ってください。また、床は掃除機をかけてください。<br />
            ・次の利用者様が気持ちよくご利用いただける様にご協力ください。<br />
            ・ゴミは必ずお持ち帰りください。ゴミを放置し、ご退出された場合は違約金として、利用料金の倍額を請求させていただきます。<br />
          </p>

          <Typography variant="h3" className={classes.subtitle}>
            （当サイトのご利用環境制限について）
          </Typography>

          <p className={classes.description}>
            ・当サイトは以下のご利用環境でのみ正常に表示/動作します<br />
          </p>

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>

              <Typography variant="body1" className={classes.browserNotice}>
                デスクトップ・ノートPC
              </Typography>

              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>Windows</th>
                    <th>mac</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chrome 68以上</td>
                    <td>Chrome 68以上</td>
                  </tr>
                  <tr>
                    <td>Edge 42以上</td>
                    <td>Safari 11以上</td>
                  </tr>
                  <tr>
                    <td>Firefox 61以上</td>
                    <td>Firefox 61以上</td>
                  </tr>
                </tbody>
              </table>

              <Typography variant="body1" className={classes.browserNotice}>
                スマートフォン
              </Typography>

              <table className={classes.table}>
                <thead>
                  <tr>
                    <th>Android</th>
                    <th>iOS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Chrome 61, 68以上</td>
                    <td>Chrome 68以上</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Safari 11以上</td>
                  </tr>
                </tbody>
              </table>

            </Grid>
          </Grid>

          <p className={classes.kaitei}>
            2021年5月25日改訂
          </p>

        </div>

      </Container>

    </BasePageContainer>
  )
}

export default TermsOfServicePage
import { NotFoundError } from '../errors/NotFoundError';
import {
  internalServerError,
  InternalServerErrorAction,
  notFoundError,
  NotFoundErrorAction,
  authenticationError,
  AuthenticationErrorAction,
  ServerValidationErrorAction,
  serverValidationError
} from '../actions/errorAction';
import { InternalServerError } from '../errors/InternalServerError';
import { Action } from 'redux';
import { AuthenticationError } from '../errors/AuthenticationError';
import { RequestValidationError } from 'errors/RequestValidationError';

export async function ajaxErrorHandler<T extends Action<any>>(
  err: Error,
  actionCreate: (message: string) => T,
): Promise<T | NotFoundErrorAction | InternalServerErrorAction | AuthenticationErrorAction | ServerValidationErrorAction> {
  if (err instanceof NotFoundError) {
    return notFoundError(err.message);
  }

  if (err instanceof AuthenticationError) {
    return authenticationError(err.message);
  }

  if (err instanceof InternalServerError) {
    return internalServerError(err.message);
  }

  if (err instanceof RequestValidationError) {
    const castErr = err as RequestValidationError
    return serverValidationError('入力内容に誤りがあります', castErr.data)
  }

  return actionCreate(err.message);
}

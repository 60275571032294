import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import RegularInquiryPage from '../pages/RegularInquiryPage'
import { observeShownAction } from 'actions/pagingAction';
import { CustomThunkDispatch } from 'types/dispatchTypes';

interface OwnProps {
}

export type RegularInquiryPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function RegularInquiryPageContainer(props: RegularInquiryPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction())
  }, []);

  return <RegularInquiryPage />

}
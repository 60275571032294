import { AppResponseError } from '../errors/AppResponseError';

import axios, { AxiosResponse } from "axios";
import { checkAppResponse } from 'actionHelper/responseChecker';


/**
 * HTTP client for main app
 */
class AppClient {
  private defaultHeaders: HeadersInit = {};

  async get(path: string, header: Record<string, string> = {}, params: Record<string, any> = {}): Promise<AxiosResponse<any>> {
    const headers = Object.assign({}, this.defaultHeaders, header, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })

    try {
      const res = await axios.get(path, { params: params, headers: headers })      
      return res
    
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`AppClient Error! HTTP Status: ${status} ${statusText}`);

      await checkAppResponse(error.response)

      return error.response
    }

  }

  async post(
    path: string,
    header: Record<string, string> = {},
    params: any = {},
  ): Promise<AxiosResponse<any>> {
    const headers = Object.assign({}, this.defaultHeaders, header, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })

    try {
      const res = await axios.post(path, params, { headers: headers })
      return res
    
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`AppClient Error! HTTP Status: ${status} ${statusText}`);

      await checkAppResponse(error.response)

      return error.response
    }
  }


  
}

export default new AppClient();

import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Invoice } from 'dataObjects/space';

import { Grid, Container, Button, useMediaQuery, useTheme } from '@material-ui/core';

import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Cation } from 'components/Cation';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4)   
    },  
    stripeField: {
      backgroundColor: '#f6f9fc',
      borderRadius: '4px',
      padding: '24px'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },  
  })
)


interface OwnProps {
  invoice: Invoice,  
  errorState: { 
    errorMessages: string
  },
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>,
  submitting: boolean
}

export type PaymentFormProps = OwnProps 


const PaymentForm: React.FC<PaymentFormProps> = ({
  invoice,    
  errorState,
  handleSubmit,  
  submitting
}) => {
  const theme = useTheme();
  const classes = useStyles({})
  const xssmMatches = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <Container component="div" maxWidth="sm" className={classes.root}>
      <Grid container spacing={2} style={{ marginBottom: "100px" }}>
        <Grid item direction="row" justify="space-between" xs={12} style={{width: '100%', marginBottom: "24px"}}>
          <span>ご利用可能カードブランド</span><br />
          <img src="/images/creditcard.svg" alt="VISA MasterCard Amex JCB DinersClub Discover" style={{width: '100%', marginTop: '12px'}} />
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>

            <Grid container spacing={2} className={classes.stripeField}>            
              <Grid item direction="row" justify="space-between" xs={12} style={{width: '100%'}}>                                    
                <label>カード番号</label>
                <CardNumberElement options={{
                    style: {
                      base: {
                        fontSize: xssmMatches ? '16px' : '24px',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }} />
              </Grid>
              {xssmMatches ? (
                <Grid item direction="row" justify="space-between" xs={12} style={{width: '100%'}}>                  
                    <div>
                      <label>有効期限</label>
                      <CardExpiryElement options={{
                          style: {
                            base: {
                              fontSize: '16px',                              
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }} />   
                    </div>                  
                    <div>
                      <label>セキュリティコード</label>
                      <CardCvcElement options={{
                          style: {
                            base: {
                              fontSize: '16px',                              
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },
                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }} /> 
                    </div>                  
                </Grid>
              ) : (
                <Grid item direction="row" justify="space-between" xs={12} style={{width: '100%'}}>
                  <Grid container spacing={2}>
                    <Grid item direction="column" style={{width: '40%'}}>
                      <div>
                        <label>有効期限</label>
                        <CardExpiryElement options={{
                            style: {
                              base: {
                                fontSize: '24px',                              
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }} />   
                      </div>
                    </Grid>
                    <Grid item direction="column" style={{width: '60%'}}>
                      <div>
                        <label>セキュリティコード</label>
                        <CardCvcElement options={{
                            style: {
                              base: {
                                fontSize: '24px',                              
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }} /> 
                      </div>            
                    </Grid>
                  </Grid>
                </Grid>
              )}

              

              <Grid item justify="space-between" xs={12} style={{width: '100%', marginTop: '16px', marginBottom: '16px'}}>
                <div>
                  <span>{errorState.errorMessages}</span>
                </div>
              </Grid>

              <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                  <Button type="submit" disabled={submitting} fullWidth className={classes.submitButton}>
                    クレジットカード決済を行う
                  </Button>             
                </Grid>
              </Grid>
              
              <Cation>
                決済完了後に解錠に必要な暗証番号が記載された予約確認メールが送信されますが<br />
                お客様のメールサーバーによっては迷惑メールとして扱われることがあります。<br />
                30分経過してもメールが届かない場合は迷惑メールフォルダをご確認ください。
              </Cation>

            </Grid>            

          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentForm
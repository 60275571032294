import React, { PropsWithChildren } from 'react';
import {
  Container,
  MuiThemeProvider,
  Theme,
  makeStyles,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import LoadingComponent, { LoadingComponentProps, LoadingStyleProps } from './LoadingComponent';
import HeaderContainer from '../containers/HeaderContainer';
import SnackBarContainer from '../containers/SnackBarContainer';
import createStyles from '@material-ui/core/styles/createStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { theme } from '../materialui/theme';
import { Footer } from './Footer/Footer';
import ValidationErrorDialogContainer from 'containers/ValidationErrorDialogContainer';
import SignInSuccessDialogContainer from 'containers/SignInSuccessDialogContainer';
import InternalErrorDialog from './InternalErrorDialog/InternalErrorDialog';
import InternalErrorDialogContainer from 'containers/InternalErrorDialogContainer';
import { Helmet } from 'react-helmet-async';

interface StyleProps {
  container: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    main: {
      width: '100%',
      overflow: 'hidden',
      marginTop: '64px',
      minHeight: '800px',
      flexGrow: 1,
      flexShrink: 0
    },
  })
);

interface BaseProps {
  loading: boolean,
  title: string
}

export type Props = PropsWithChildren<BaseProps>;

const BasePageComponent: React.FC<Props> = (props: Props) => {
  const classes = useStyles({} as StyleProps)

  const loadingProps: LoadingComponentProps = {
    size: 80,
    style: {} as LoadingStyleProps
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.container} >
        <Helmet>
          { props.title ?
            (<title>{props.title} - Shareal あなたの生活を彩るレンタルスペース</title>)
            : ((<title>Shareal あなたの生活を彩るレンタルスペース</title>) )}
        </Helmet>

        <HeaderContainer />

        <div className={classes.main}>

          { props.loading ?
          // スクロール位置をトップにセット
  window.scrollTo(0, 0) : null}

          { props.loading ? (
              <LoadingComponent {...loadingProps} />
            )
          : (
            props.children || null
          )
          }


          <InternalErrorDialogContainer />

          <SnackBarContainer />

          <ValidationErrorDialogContainer />

          <SignInSuccessDialogContainer />

        </div>

        <Footer />

      </div>
    </MuiThemeProvider>
  );
}

export default BasePageComponent;

import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { closeDialog } from 'actions/dialogAction';
import SignInSuccessDialog from 'components/Auth/SignInSuccessDialog';

interface Props {
}

const appStateSelector = (state: AppState) => state

export default function SignInSuccessDialogContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    loginSuccessDialogOpen: state.page.loginSuccessDialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
    }
  }

  return <SignInSuccessDialog {..._props} />
}

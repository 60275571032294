import React from 'react'
import { MuiThemeProvider } from '@material-ui/core';
import { routes } from '../../routes/Route';
import { useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

interface StyleProps {
  root: BaseCSSProperties,
  menuButton: BaseCSSProperties,
  title: BaseCSSProperties,
  list: BaseCSSProperties,
  nested: BaseCSSProperties
}


interface Props {
}


export const Footer: React.FC<Props> = (props) => {
  const theme = useTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <footer id="footer">
        <div id="info">
          <h4>妥協の賃貸から選択の賃貸へ</h4>
          <div className="contact">
            <div className="company"><img src="/images/kawakami_bldg.svg" alt="株式会社川上ビル" /></div>
            <div className="btn"><Link to={routes.inquiry.path}>お問い合わせはこちら</Link></div>
          </div>
        </div>
        <address>Copyright © 2020 Shareal All rights reserved.</address>
      </footer>
    </MuiThemeProvider>
  );
};

import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { fetchMyInquiry } from 'actions/userAction'
import { ListTableColumn } from '../components/ListTable/ListTable'
import UserInquiryPage from 'pages/UserInquiryPage'

interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

const columns: ListTableColumn[] = [
  { id: 'id', label: 'ID', minWidth: 50 },
  { 
    id: 'category', 
    label: '問い合わせ種別', 
    minWidth: 100,
    format: (value: string) => value === 'COMMON' ? '一般' : '定期利用'
  },
  {
    id: 'content',
    label: '問い合わせ内容',
    minWidth: 200,
  },
  {
    id: 'str_inquiry_at',
    label: '受付日時',
    minWidth: 150,
    align: 'center'
  }
];

export default function UserInquiryPageContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(fetchMyInquiry())
  }, [])

  const _props = {
    columns: columns,
    data: state.userInquiry.data,
    total: state.userInquiry.total,
    page: state.userInquiry.current_page - 1,
    rowsPerPage: Object.keys(state.userInquiry.data).length,
    rowsPerPageOptions: [20, 50, 100, Object.keys(state.userInquiry.data).length],
    setPage: (page: number) => {
      dispatch(fetchMyInquiry(state.userInquiry.per_page, page))
    },
    setRowsPerPage: (perPage: number) => {
      dispatch(fetchMyInquiry(perPage, 0))
    },
    selectedRow: (data: any) => {
      
    }
  }

  return <UserInquiryPage {..._props} />

}
import React from 'react'
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'


import { renderTextField, renderRadio, renderFormHelper, renderCheckbox } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength, number, requiredArray } from "../../utils/validation";
import { SpaceUsage, TermOfService } from 'dataObjects/space';
import { ValidationError } from 'errors/RequestValidationError';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'      
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'users_adult',
    'usage',
    'terms_of_service'
  ]
  requiredFields.forEach(field => {    
    if (values['enquete'] && !required(values['enquete'][field])) {
      if(!errors['enquete']) {
        errors['enquete'] = {}
      }
      errors['enquete'][field] = '必須項目です'
    }
  })

  if(values['enquete'] && values['enquete']['terms_of_service'] && values['enquete']['terms_of_service'] != true) {
    if(!errors['enquete']) {
      errors['enquete'] = {}
    }
    errors['enquete']['terms_of_service'] = 'スペースのご利用は規約への同意が必要です'    
  }

  return errors
}

/**
 * 利用用途
 * @param usage 
 */
const renderUsageList = (props: WrappedFieldProps & OwnProps) => {
  const { error } = props.meta

  if (outerMasters) {
    return outerMasters.map(u => {
      return (
        <FormControlLabel
          key={`${u.id}`}
          value={`${u.id}`}
          control={<Radio />}
          label={u.usage}
        />
      );
    });
  } else {
    return null;
  }
}

interface OwnProps {
  usage: SpaceUsage[],
  terms_of_service: TermOfService,
  validationResult?: ValidationError,
  ss422Validation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  specialError?: string,
}

export type ReservationFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

let outerMasters;

const Form: React.FC<ReservationFormPropsType> = (props: ReservationFormPropsType) => {
  const classes = useStyles({} as StyleProps)
  const { handleSubmit, pristine, submitting, usage, terms_of_service, specialError, touch } = props

  const ownPristine = false

  outerMasters = usage

  if(props.validationResult && props.validationResult.status != '') {
    if (props.ss422Validation) {
      props.ss422Validation(props.validationResult.errors, touch);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.formContainer}>
        
          <dl style={{height: '250px'}}>
            <dt>利用人数</dt>
            <dd>
              <div>        
                <Field
                  name="enquete.users_adult"
                  component={renderTextField}
                  label="大人"
                  rowsMax="1"
                  margin="normal"
                  variant="outlined"
                  normalize={number}
                  style={{marginRight: '15px'}}
                />
                <Field
                  name="enquete.users_children"
                  component={renderTextField}
                  label="子供(小学生以下)"
                  rowsMax="1"
                  margin="normal"
                  variant="outlined"
                  normalize={number}
                />
              </div>
            </dd>
          </dl>
          <dl style={{height: '250px'}}>
            <dt>利用用途(選択)</dt>
            <dd>
              <div>
              <Field name="enquete.usage.id" component={renderRadio}>
                {usage.map(u => {
                  return (
                    <FormControlLabel
                      key={`${u.id}`}
                      value={`${u.id}`}
                      control={<Radio />}
                      label={u.usage}
                      />
                    );
                  })
                }
              </Field> 
              {/*
              <FieldArrayCustom
                    name="enquete.usage.id"
                    component={renderUsageList} />
              */}
              </div>
            </dd>      
          </dl>
          <dl>
            <dt>連絡事項</dt>
            <dd>
              <div>
                <Field
                  name="enquete.detail"
                  component={renderTextField}
                  label="利用目的などオーナーに伝えたいことがあれば記入してください。"
                  multiline
                  rows="2"
                  rowsMax="4"
                  margin="normal"
                  variant="outlined"
                  className={classes.inputTextField80}
                />
              </div>
            </dd>
          </dl>
        </div>

        <Typography variant="h3" className={classes.subtitle}>
          利用規約
        </Typography>

        <div style={{ marginTop: '8px', width: '100%', height: '400px', overflowY: 'scroll'}}>
          <p className={classes.description} style={{ whiteSpace: 'pre-line' }}>{terms_of_service.contents}</p>
        </div>

        <table className={classes.formContainer}>
          <tr key={'terms_of_service_tr'}>
            <td>
              <div style={{ textAlign: 'center' }}>              
              <Field name="enquete.terms_of_service" component={renderCheckbox} label="規約に同意する" style={{fontSize: '1.3rem'}} />
              </div>
            </td>
          </tr>
        </table>

        <Grid container spacing={2} justify="center">
          <Grid item xs={6}>
            <Button type="submit" disabled={ownPristine || submitting} fullWidth className={classes.submitButton}>
              利用時間予約をする
            </Button>             
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={6}>  
            <div style={{width: '100%', textAlign: 'center'}} >
              {specialError && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError}</span>}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center">
          <Grid item xs={12} className={classes.notice}>
            <div style={{textAlign: 'center'}}>
              <p className={classes.description}>利用時間予約を行うことで仮予約状態となり、お支払い手続きが完了するまで他のお客様からの予約を受け付けないようになります。<br />
              ただし、利用時間予約完了から30分以内にお支払い手続きまで完了しなかった場合は自動的に時間予約はキャンセルされます。</p>
            </div>
          </Grid>
        </Grid>
        
      </form>

      
    </>
  )
}

const ReservationForm = reduxForm({
  form: 'ReservationForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)

export default ReservationForm
import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_MY_USERDATA_START,
  FETCH_MY_USERDATA_SUCCESS,
  FETCH_MY_USERDATA_FAIL,
  STORE_MY_USERDATA_START,
  STORE_MY_USERDATA_SUCCESS,
  STORE_MY_USERDATA_FAIL
} from '../actions/userAction'
import { UserData } from '../dataObjects/user'
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';


export interface UserDataState extends UserData {
}

const initialUserDataState: UserDataState = {
  user_id: undefined,
  user_name: '',
  user_email: '',
  contact_family_name: '',
  contact_given_name: '',
  contact_family_name_kana: '',
  contact_given_name_kana: '',
  company_name: '',
  contact_email: '',
  contact_email_confirmation: '',
  contact_postal_code: '',
  contact_address_pref: '',
  contact_address_city: '',
  contact_address_street: '',
  contact_address_room: '',
  contact_tel: ''
};

/* userData Reducer */
const userData = (
  state: UserDataState = initialUserDataState,
  action: ActionTypes,
): UserDataState => {
  switch (action.type) {
    // ユーザーデータ読み込み
    case FETCH_MY_USERDATA_START: 
      return { ...state }
    case FETCH_MY_USERDATA_SUCCESS:
      console.log(action.payload)
      return { ...state, ...action.payload };
    case FETCH_MY_USERDATA_FAIL:
      return { ...state }   
    // ユーザーデータ保存
    case STORE_MY_USERDATA_START: 
      return { ...state }
    case STORE_MY_USERDATA_SUCCESS:
      return { ...state, ...action.payload };
    case STORE_MY_USERDATA_FAIL:
      return { ...state } 
    case LOGOUT_SUCCESS:
        return { ...state, ...initialUserDataState}  
    default:
      return { ...state };
  }
};

export default userData
import React, { PropsWithChildren } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';


interface StyleProps {
  root: BaseCSSProperties,
  avatar: BaseCSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    frame: {
        width: "100%",
        margin: "30px auto 20px",
        padding: "40px 20px 20px",
        border: "3px solid #aaa",
        position: "relative",
        borderRadius: "4px",
        backgroundColor: "#fff"
    },
    frameTitle: {
        position: "absolute",
        top: "0px",
        left: "0px",
        padding: "2px 5px",
        backgroundColor: "#fff",
        fa: {
            marginRight: "5px"
        }
    },       
    caution: {
        backgroundColor: "#ff4500",
        border: "2px solid #ff4500",
        color: "#fff",
        borderRadius: "0px 0px 4px 0px",
        padding: "1px 4px 4px",
    },
    frameRed: {
        borderColor: "#ff4500"
    },
    description: {
        fontWeight: 'normal',
        fontSize: '1.3rem',
        lineHeight: 1.4,
        color: "#555"
    }
  })
)

interface BaseProps {
}
  
export type Props = PropsWithChildren<BaseProps>;

export const Cation: React.FC<Props> = (props) => {  
  const classes = useStyles({} as StyleProps);

  return (
    <div className={`${classes.frame} ${classes.frameRed}`}>
        <div className={`${classes.frameTitle} ${classes.caution}`}>
            ご注意ください
        </div>
        <p className={classes.description}>
            {props.children}
        </p>
    </div>
  )
}
import * as React from 'react';
import { Theme, createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { theme } from 'materialui/theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3)
    },
  })
)

interface OwnProps {
  steps: string[],
  activeStep: number
}

export type ReservationStepperProps = OwnProps

const ReservationStepper: React.FC<ReservationStepperProps> = (props: ReservationStepperProps) => {
  const classes = useStyles({})

  return (
    <Stepper activeStep={props.activeStep} alternativeLabel className={classes.root}>
      {props.steps.map(label => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default ReservationStepper
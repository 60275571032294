import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';

import { AppState } from '../types/stateTypes';
import { push } from 'connected-react-router';
import { routes } from '../routes/Route';
import { Header } from '../components/Header'
import { menuVisibleChangeAction, menuVisibleInitializeAction } from 'actions/navAction';
import { logOutAction, logout } from 'actions/authenticationActions';

interface OwnProps {

}

const appStateSelector = (state: AppState) => state

export default function LandingPageContainer(props: OwnProps) {
  const state = useSelector(appStateSelector)
    const mockProps = {
      hasLogin: state.authStatus.hasAuthenticate,
      isNavMenuOpen: state.navMenuVisible.isOpen,
      username: state.authStatus.name,
      changeMenuView: () => {
        dispatch(menuVisibleChangeAction())
      },
      logout: () => {
        dispatch(logout())
      }
    };

    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(menuVisibleInitializeAction());
    }, []);

    const _props = { ...mockProps };

    return <Header {..._props} />;
}
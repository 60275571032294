import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { observeShownAction } from '../actions/pagingAction'
import SignOutPage from '../pages/SignOutPage'
import { logOutAction } from 'actions/authenticationActions';

interface OwnProps {
}

export type SignOutPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function SignOutPageContainer(props: SignOutPageContainerProps) {
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);

  return <SignOutPage />

}
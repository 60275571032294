import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SignInPage, { SignInPageProps } from '../pages/SignInPage'
import { observeShownAction } from 'actions/pagingAction';

interface OwnProps {
}

export type SignInPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state


export default function SignInPageContainer(props: SignInPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);


  return <SignInPage />

}
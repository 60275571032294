import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_NEWS_LIST_START,
  FETCH_NEWS_LIST_SUCCESS,
  FETCH_NEWS_LIST_FAIL,
  FETCH_NEWS_START,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAIL
} from '../actions/newsAction'
import { News } from 'dataObjects/news';


export interface NewsListState {
  [key: number]: News
}

const initialNewsListState: NewsListState = {
  0: {
    id: undefined,
    title: '',
    contents: '',
    publish_date: undefined,
    files: []    
  }
};

/* newsList Reducer */
export const newsList = (
  state: NewsListState = initialNewsListState,
  action: ActionTypes,
): NewsListState => {
  switch (action.type) {
    case FETCH_NEWS_LIST_START: 
      return { ...state };
    case FETCH_NEWS_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_NEWS_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};


export interface NewsState extends News {
}

const initialNewsState: NewsState = {
  id: undefined,
  title: '',
  contents: '',
  publish_date: undefined,
  files: []
};

/* news Reducer */
export const news = (
  state: NewsState = initialNewsState,
  action: ActionTypes,
): NewsState => {
  switch (action.type) {
    case FETCH_NEWS_START: 
      return { ...state };
    case FETCH_NEWS_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_NEWS_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};
import React from 'react'
import { routes } from '../routes/Route';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BasePageContainer from 'containers/BasePageContainer';
import { Container } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
      lineHeight: 1.7,
      marginBottom: theme.spacing(12)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.5em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#fbfbfb',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },    
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'center',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        },
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
  })
)



const UsagePage: React.FC = () => {
  const classes = useStyles({})
  

  return (
    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        
        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            ご利用の手引き
          </Typography>

          <p className={classes.description}>
          シェアール(Shareal)は良い設備と空間を少しでも皆様へ安価にご提供させていただく工夫をしております。そのため、運営には皆様のご協力が必要となっております。必ずご利用の手引をよくお読みいただき、ご理解とご協力のほど、よろしくお願いいたします。<br />
          <br />
          <div style={{textAlign: 'center', color: '#26A78E', fontSize: '1.7rem'}}>
          時間厳守<br />
          <br />
          片付けと清掃<br />
          <br />
          ゴミの持ち帰り<br />
          <br />
          </div>
          <div style={{textAlign: 'center', fontSize: '1.7rem'}}>          
          以上の3点は特にご注意ください。
          </div>
          <br />
          詳細については予約確認メールに添付されている「ご利用の手引」をよくお読みいただきますようお願いいたします。

          </p>

          <Typography variant="h3" className={classes.subtitle}>
          ご利用の流れ
          </Typography>

          <p className={classes.description}>
          一般利用のお客様　予約
          </p>

          <ul id="flow">
            <li>
              <span id="flow_icon">STEP.01</span>
              <dl>
                  <dt>ご利用日時の予約</dt>
                  <dd>予約画面から希望日時を選択してください</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.02</span>
              <dl>
                  <dt>お客様情報の入力</dt>
                  <dd>ご利用する方、グループであれば決済に使用するカードの名義人の方の情報を入力してください</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.03</span>
              <dl>
                  <dt>クレジットカード決済</dt>
                  <dd>クレジットカードにより事前決済が行われます</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.04</span>
              <dl>
                  <dt>予約確認メール受信</dt>
                  <dd>解錠に必要な暗証番号が記載された予約確認メールが届きます。添付されている「ご利用の手引」「利用規約」もご確認ください</dd>
              </dl>
            </li>
          </ul>

          <p className={classes.description}>
          一般利用のお客様　ご利用の当日
          </p>

          <ul id="flow">
            <li>
              <span id="flow_icon">STEP.05</span>
              <dl>
                  <dt>入室</dt>
                  <dd>ご利用時間になりましたら暗証番号で入室してください</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.06</span>
              <dl>
                  <dt>楽しい時間を</dt>
                  <dd>利用規約に定められた中で楽しい時間をお過ごしください。</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.07</span>
              <dl>
                  <dt>片付けと清掃</dt>
                  <dd>ご協力をお願いいたします。</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.08</span>
              <dl>
                  <dt>ゴミを忘れず、ご退室</dt>
                  <dd>忘れ物がないように、ご退室ください。</dd>
              </dl>
            </li>
          </ul>

          
          <Typography variant="h3" className={classes.subtitle}>
          定期利用をご希望のお客様
          </Typography>

          <p className={classes.description}>
          定期利用をご希望のお客様は、メールまたはお電話にてご連絡ください
          </p>

          <table className={classes.table}>
            <thead></thead>
            <tbody>
              <tr>
                <th>E-mail</th>
                <td>shareal2020_info@shareal.jp</td>
              </tr>
              <tr>
                <th>TEL</th>
                <td>078-591-3149</td>
              </tr>
              <tr>
                <th>FAX</th>
                <td>078-593-6006</td>
              </tr>
            </tbody>
          </table>

          <ul id="flow">
            <li>
              <span id="flow_icon">STEP.01</span>
              <dl>
                  <dt>お問い合わせ</dt>
                  <dd>お客様から当社へご連絡</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.02</span>
              <dl>
                  <dt>面談日決定</dt>
                  <dd>当社からご面談と定期利用契約日時のご相談連絡をいたします</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.03</span>
              <dl>
                  <dt>面談</dt>
                  <dd>当社との打ち合わせと定期利用契約のご説明</dd>
              </dl>
            </li>

            <li>
              <span id="flow_icon">STEP.04</span>
              <dl>
                  <dt>定期利用契約</dt>
                  <dd>双方合意のうえ契約となります</dd>
              </dl>
            </li>
          </ul>

        </div>

      </Container>

    </BasePageContainer>
  )
}

export default UsagePage
import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import ReservationForm, { ReservationFormPropsType, validate } from '../components/Space/ReservationForm'
import { Values, Errors } from '../types/formTypes'
import {
  reservationPeriodAction  
} from '../actions/spaceReservationAction'
import { SpaceReservation, SpaceReservationEnquete, SpaceUsage } from '../dataObjects/space';
import { ValidationError } from 'errors/RequestValidationError';
import { serverValidationClear } from 'actions/errorAction';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function ReservationFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const [specialError, setSpecialError] = useState('')
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      const formEnquete = values['enquete']

      let enquete: SpaceReservationEnquete
      if(formEnquete) {
        enquete = {
          users_adult: parseInt(values['enquete']['users_adult']),
          users_children: parseInt(values['enquete']['users_children']),
          usage: {
            id: parseInt(values['enquete']['usage'] ? values['enquete']['usage']['id'] : undefined) 
          },
          detail: values['enquete']['detail'],
          terms_of_service: values['enquete']['terms_of_service'] === true,
        }      
      }
      const payload: SpaceReservation = { ...state.reservationSpace.payload, ...{
        space_id: state.viewingSpace.space_info.space_id,
        selectedDate: state.viewingSpace.selectedDate,
        selectedPeriods: state.viewingSpace.periodsAtDate ? state.viewingSpace.periodsAtDate.filter(p => p.selected) : undefined,
        enquete: enquete
      }}

      console.log({enquete})

      ownDispatch(serverValidationClear())
      setSpecialError('')
      setIsDisplay(false)
      ownDispatch(reservationPeriodAction(payload))
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})
      if(errors.hasOwnProperty('enquete')
        && errors['enquete'].hasOwnProperty('usage') 
        && errors['enquete']['usage'].hasOwnProperty('id') ) {
          errors['enquete']['usage'] = errors['enquete']['usage']['id']
        }
      if(errors.hasOwnProperty('selectedPeriods')
        && errors['selectedPeriods'].hasOwnProperty('selectedPeriods')) {          
          setSpecialError(errors['selectedPeriods']['selectedPeriods'])
      }

      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation('ReservationForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    usage: state.viewingSpace.space_info.usage,
    terms_of_service: state.viewingSpace.space_info.term_of_service,
    validationResult: state.ss422ValidationErrorResult,
    ss422Validation: showValidationError,
    specialError: specialError,
  };  

  return <ReservationForm {..._props} />

}
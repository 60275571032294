import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppState } from '../types/stateTypes';
import { NotFoundPage } from '../pages/error/NotFoundPage';
import { observeShownAction } from 'actions/pagingAction';
import { InternalServerErrorPage } from 'pages/error/InternalServerErrorPage';

interface Props {
}

const appStateSelector = (state: AppState) => state

export default function InternalServerErrorContainer(props: Props) {
  const state = useSelector(appStateSelector)

  const stateProps = {
    message: state.error.errorMessage
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(observeShownAction())
  }, [])
  
  const _props = { ...stateProps };

  return <InternalServerErrorPage {..._props} />;
}

import React, { useEffect } from 'react'
import { RouteProps } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../types/stateTypes';
import { AnyAction } from 'redux';
import { NonAuthRoute } from '../components/Route/NonAuthRoute';
import { fetchSettings } from '../actions/settingsAction';
import { ThunkDispatch } from 'redux-thunk';
import { fetchMasterAction } from 'actions/masterAction';

type Props = {
} & RouteProps;

type ReduxDispatch = ThunkDispatch<AppState, undefined, AnyAction>

const appStateSelector = (state: AppState) => state

export default function NonAuthContainer(props: Props) {
  const state = useSelector(appStateSelector)

  const stateProps = {
    loading: state.page.loading,
    error: state.error.errorMessage,
  }
  const dispatch = useDispatch<ReduxDispatch>()
  
  const _props = {...props, ...stateProps}

  return <NonAuthRoute { ..._props } />

}
import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { ErrorActions } from './errorAction';
import { News } from 'dataObjects/news';
import { SnackBarAction } from './actionTypes';

export const FETCH_NEWS_LIST_START = 'FETCH_NEWS_LIST_START';
export const FETCH_NEWS_LIST_SUCCESS = 'FETCH_NEWS_LIST_SUCCESS';
export const FETCH_NEWS_LIST_FAIL = 'FETCH_NEWS_LIST_FAIL';

export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';

export type FetchNewsListActions =
  | FetchNewsListStartAction
  | FetchNewsListSuccessAction
  | FetchNewsListFailAction
  | ErrorActions;
type FetchNewsListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchNewsListActions>;

export type FetchNewsActions =
  | FetchNewsStartAction
  | FetchNewsSuccessAction
  | FetchNewsFailAction
  | ErrorActions;
type FetchNewsThunkResult<R> = ThunkAction<R, AppState, undefined, FetchNewsActions>;


/* ---------------------------------------------------------------------------------------------------- */

/* 最新のニュース10件取得 ThunkAction */
export function fetchNewsList(): FetchNewsListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchNewsListActions>,
    getState: () => AppState) => {
    try {            
      dispatch(fetchNewsListStartAction());
  
      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/news/list`)
      const body: News[] = await response.data;
    
      dispatch(
        fetchNewsListSuccessAction(body),
      )
    

    } catch(err) {
      dispatch<FetchNewsListActions>(
        await ajaxErrorHandler(err, fetchNewsListFailAction),
      )
    }
  }
}

export interface FetchNewsListStartAction
  extends Action<typeof FETCH_NEWS_LIST_START> {}

const fetchNewsListStartAction = (): FetchNewsListStartAction => ({
  type: 'FETCH_NEWS_LIST_START',
});

export interface FetchNewsListSuccessAction
  extends Action<typeof FETCH_NEWS_LIST_SUCCESS> {
  payload: News[]
}

const fetchNewsListSuccessAction = (
  news: News[],
): FetchNewsListSuccessAction => ({
  type: 'FETCH_NEWS_LIST_SUCCESS',
  payload: news
})

export interface FetchNewsListFailAction
  extends SnackBarAction<typeof FETCH_NEWS_LIST_FAIL> {
}

const fetchNewsListFailAction = (message: string): FetchNewsListFailAction => ({
  type: 'FETCH_NEWS_LIST_FAIL',
  snackBarMessage: message,
  variant: 'error'
})


/* ---------------------------------------------------------------------------------------------------- */


/* 指定したニュースIDのニュースを取得 ThunkAction */
export function fetchNews(news_id: string): FetchNewsThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchNewsActions>,
    getState: () => AppState) => {
    try {            
      dispatch(fetchNewsStartAction());
  
      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/news/get/${news_id}`)
      const body: News = await response.data;
    
      dispatch(
        fetchNewsSuccessAction(body),
      )
    

    } catch(err) {
      dispatch<FetchNewsActions>(
        await ajaxErrorHandler(err, fetchNewsFailAction),
      )
    }
  }
}

export interface FetchNewsStartAction
  extends Action<typeof FETCH_NEWS_START> {}

const fetchNewsStartAction = (): FetchNewsStartAction => ({
  type: 'FETCH_NEWS_START',
});

export interface FetchNewsSuccessAction
  extends Action<typeof FETCH_NEWS_SUCCESS> {
  payload: News
}

const fetchNewsSuccessAction = (
  news: News,
): FetchNewsSuccessAction => ({
  type: 'FETCH_NEWS_SUCCESS',
  payload: news
})

export interface FetchNewsFailAction
  extends SnackBarAction<typeof FETCH_NEWS_FAIL> {
  
}

const fetchNewsFailAction = (message: string): FetchNewsFailAction => ({
  type: 'FETCH_NEWS_FAIL',
  snackBarMessage: message,
  variant: 'error',
})

import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { ErrorActions } from './errorAction';
import moment from 'moment'
import { RegularEvent, RegularEventInfo } from 'dataObjects/space';
import { SnackBarAction } from './actionTypes';

export const FETCH_REGULAR_SCHEDULE_LIST_START = 'FETCH_REGULAR_SCHEDULE_LIST_START';
export const FETCH_REGULAR_SCHEDULE_LIST_SUCCESS = 'FETCH_REGULAR_SCHEDULE_LIST_SUCCESS';
export const FETCH_REGULAR_SCHEDULE_LIST_FAIL = 'FETCH_REGULAR_SCHEDULE_LIST_FAIL';

export const FETCH_REGULAR_INFO_LIST_START = 'FETCH_REGULAR_INFO_LIST_START';
export const FETCH_REGULAR_INFO_LIST_SUCCESS = 'FETCH_REGULAR_INFO_LIST_SUCCESS';
export const FETCH_REGULAR_INFO_LIST_FAIL = 'FETCH_REGULAR_INFO_LIST_FAIL';

export type FetchRegularScheduleListActions =
  | FetchRegularScheduleListStartAction
  | FetchRegularScheduleListSuccessAction
  | FetchRegularScheduleListFailAction
  | ErrorActions;
type FetchRegularScheduleListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularScheduleListActions>;

export type FetchRegularInfoListActions =
  | FetchRegularInfoListStartAction
  | FetchRegularInfoListSuccessAction
  | FetchRegularInfoListFailAction
  | ErrorActions;
type FetchRegularInfoListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularInfoListActions>;


export const SHOW_SCHEDULE_EVENT = 'SHOW_SCHEDULE_EVENT';

/* ---------------------------------------------------------------------------------------------------- */

/* 最新のニュース10件取得 ThunkAction */
export function fetchRegularScheduleList(space_id: number, startDate: Date, endDate: Date, flipUnit?: string, defDate?: Date)
  : FetchRegularScheduleListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchRegularScheduleListActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchRegularScheduleListStartAction());

      const sDateStr = (moment(startDate)).format('YYYY-MM-DD')
      const eDateStr = (moment(endDate)).format('YYYY-MM-DD')

      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/regular/schedule/${space_id}/${sDateStr}/${eDateStr}`)
      const body: RegularEvent[] = await response.data;

      if(!flipUnit) {
        flipUnit = 'month'
      }
      if(!defDate) {
        defDate = new Date()
        defDate.setDate(1)
      }
      const payload = {
        body: body,
        schedule_from: startDate,
        schedule_to: endDate,
        view: flipUnit,
        defdate: defDate
      }
      dispatch(
        fetchRegularScheduleListSuccessAction(payload),
      )


    } catch (err) {
      dispatch<FetchRegularScheduleListActions>(
        await ajaxErrorHandler(err, fetchRegularScheduleListFailAction),
      )
    }
  }
}

export interface FetchRegularScheduleListStartAction
  extends Action<typeof FETCH_REGULAR_SCHEDULE_LIST_START> { }

const fetchRegularScheduleListStartAction = (): FetchRegularScheduleListStartAction => ({
  type: 'FETCH_REGULAR_SCHEDULE_LIST_START',
});

export interface FetchRegularScheduleListSuccessAction
  extends Action<typeof FETCH_REGULAR_SCHEDULE_LIST_SUCCESS> {
  payload: {
    body: RegularEvent[],
    schedule_from: Date,
    schedule_to: Date,
    view: string,
    defdate: Date
  }
}

const fetchRegularScheduleListSuccessAction = (
  payload: {
    body: RegularEvent[],
    schedule_from: Date,
    schedule_to: Date,
    view: string,
    defdate: Date
  }
): FetchRegularScheduleListSuccessAction => ({
  type: 'FETCH_REGULAR_SCHEDULE_LIST_SUCCESS',
  payload: payload
})

export interface FetchRegularScheduleListFailAction
  extends SnackBarAction<typeof FETCH_REGULAR_SCHEDULE_LIST_FAIL> {
  
}

const fetchRegularScheduleListFailAction = (message: string): FetchRegularScheduleListFailAction => ({
  type: 'FETCH_REGULAR_SCHEDULE_LIST_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ---------------------------------------------------------------------------------------------------- */


/* 指定した定期利用IDの定期利用情報リストを取得 ThunkAction */
export function fetchRegularInfoList(space_id: number, startDate: Date, endDate: Date): FetchRegularInfoListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchRegularInfoListActions>,
    getState: () => AppState) => {
    try {
      dispatch(fetchRegularInfoListStartAction());

      const sDateStr = (moment(startDate)).format('YYYY-MM-DD')
      const eDateStr = (moment(endDate)).format('YYYY-MM-DD')

      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/regular/info/${space_id}/${sDateStr}/${eDateStr}`)
      const body: RegularEventInfo[] = await response.data;

      dispatch(
        fetchRegularInfoListSuccessAction(body),
      )


    } catch (err) {
      dispatch<FetchRegularInfoListActions>(
        await ajaxErrorHandler(err, fetchRegularInfoListFailAction),
      )
    }
  }
}

export interface FetchRegularInfoListStartAction
  extends Action<typeof FETCH_REGULAR_INFO_LIST_START> { }

const fetchRegularInfoListStartAction = (): FetchRegularInfoListStartAction => ({
  type: 'FETCH_REGULAR_INFO_LIST_START',
});

export interface FetchRegularInfoListSuccessAction
  extends Action<typeof FETCH_REGULAR_INFO_LIST_SUCCESS> {
  payload: RegularEventInfo[]
}

const fetchRegularInfoListSuccessAction = (
  list: RegularEventInfo[],
): FetchRegularInfoListSuccessAction => ({
    type: 'FETCH_REGULAR_INFO_LIST_SUCCESS',
  payload: list
})

export interface FetchRegularInfoListFailAction
  extends SnackBarAction<typeof FETCH_REGULAR_INFO_LIST_FAIL> {
  
}

const fetchRegularInfoListFailAction = (message: string): FetchRegularInfoListFailAction => ({
  type: 'FETCH_REGULAR_INFO_LIST_FAIL',
  snackBarMessage: message,
  variant: 'error',
})

/* ---------------------------------------------------------------------------------------------------- */

export interface ShowScheduleEvent extends Action<typeof SHOW_SCHEDULE_EVENT> {
  payload: RegularEvent
}

export const showScheduleEvent = (payload: RegularEvent) : ShowScheduleEvent => ({
  type: 'SHOW_SCHEDULE_EVENT',
  payload : payload
})

import React from 'react'
import { withStyles, Theme, createStyles, makeStyles, useTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import { Invoice } from '../../dataObjects/space'
import { Snackbar, SnackbarContent } from '@material-ui/core';

interface StyleProps {
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNav: {      
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: 'block',
      width: '100%',
      height: '100px',
      pading: '15px 15px',
      position: 'fixed',
      marginTop: '50px',
      left: 0,
      bottom: 0,
      zIndex: 1000,
    },
    bottomNavContent: {
      width: '100%',
      margin: '0 auto'
    },
    bottomNavli: {
      display: 'block',
      listStyle: 'none',
      float: 'right',
      textAlign: 'left',
      padding: '0',
      letterSpacing: '1px',
      lineHeight: 1.5,
      fontWeight: 'bold',
      margin: '1% 1% 0 0',    
    },
    spanAmount: {
     
    },
    spanAttent: {
      display: 'block',
      fontSize: '1em'
    },
    root: {
      maxWidth: 300,
      display: 'block',
      height: '100px',
      pading: '15px 15px',
      position: 'fixed',
      marginTop: '50px',
      left: 0,
      bottom: 0,
      zIndex: 1000,
    },
    snackbar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      margin: theme.spacing(1),
      textAlign: 'right'
    },
    currency: {
      fontSize: '1.8rem',
    }
  })
)

interface OwnProps {
  invoice: Invoice
}

export type SpaceBottomNavProps = OwnProps 


const SpaceBottomNav: React.FC<SpaceBottomNavProps> = (props: SpaceBottomNavProps) => {
  const theme = useTheme()
  const classes = useStyles({} as StyleProps)
  const formatter = new Intl.NumberFormat('ja-JP')
  const { invoice } = props

  const currency = formatter.format(invoice.amount)
  const vertical = 'bottom'
  const horizontal = 'right'

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <Snackbar
          open={true}
          anchorOrigin={{ vertical, horizontal }}
        >
          <SnackbarContent
            className={classes.snackbar}
            aria-describedby="client-snackbar"
            message={
              <span id="message-id" className={classes.currency}>合計金額 ￥{currency}(税込)</span>
            }
          />
        </Snackbar>
      </div>
    </MuiThemeProvider>
  )
}


export default SpaceBottomNav
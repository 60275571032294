import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { Masters } from 'dataObjects/masters';
import { Inquiry } from 'dataObjects/inquiry';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { SnackBarAction } from './actionTypes';

/* InquieryアクションType */
export const COMMON_INQUIRY_POST_START = 'COMMON_INQUIRY_POST_START'
export const COMMON_INQUIRY_POST_SUCCESS = 'COMMON_INQUIRY_POST_SUCCESS'
export const COMMON_INQUIRY_POST_FAIL = 'COMMON_INQUIRY_POST_FAIL'

/* RegularInquieryアクションType */
export const REGULAR_INQUIRY_POST_START = 'REGULAR_INQUIRY_POST_START'
export const REGULAR_INQUIRY_POST_SUCCESS = 'REGULAR_INQUIRY_POST_SUCCESS'
export const REGULAR_INQUIRY_POST_FAIL = 'REGULAR_INQUIRY_POST_FAIL'

/* 一般問い合わせアクション */
export type CommonInquiryActions =
  | CommonInquiryStartAction
  | CommonInquirySuccessAction
  | CommonInquiryFailAction
  | CallHistoryMethodAction
  | ErrorActions

type CommonInquiryThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  CommonInquiryActions
>


/* 定期利用問い合わせアクション */
export type RegularInquiryActions =
  | RegularInquiryStartAction
  | RegularInquirySuccessAction
  | RegularInquiryFailAction
  | CallHistoryMethodAction
  | ErrorActions

type RegularInquiryThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  CommonInquiryActions
>

/* --------------------------------------------------------------------------------- */

/* 一般問い合わせSTART Action */
export interface CommonInquiryStartAction
  extends Action<typeof COMMON_INQUIRY_POST_START> { }

const commonInquiryStartAction = (): CommonInquiryStartAction => {
  return {
    type: COMMON_INQUIRY_POST_START,
  }
}

/* 一般問い合わせ成功 Action */
export interface CommonInquirySuccessAction
  extends Action<typeof COMMON_INQUIRY_POST_SUCCESS> {
  payload: Inquiry
}

const commonInquirySuccessAction = (payload: Inquiry)
  : CommonInquirySuccessAction => {
  return {
    type: COMMON_INQUIRY_POST_SUCCESS,
    payload,
  }
}

/* 一般問い合わせ失敗 Action */
export interface CommonInquiryFailAction
  extends SnackBarAction<typeof COMMON_INQUIRY_POST_FAIL> {
}

const commonInquiryFailAction = (
  message: string
): CommonInquiryFailAction => {
  return {
    type: COMMON_INQUIRY_POST_FAIL, snackBarMessage: message,
    variant: 'error' };
};


/* --------------------------------------------------------------------------------- */

/* 定期利用問い合わせSTART Action */
export interface RegularInquiryStartAction
  extends Action<typeof REGULAR_INQUIRY_POST_START> { }

const regularInquiryStartAction = (): RegularInquiryStartAction => {
  return {
    type: REGULAR_INQUIRY_POST_START,
  }
}

/* 定期利用問い合わせ成功 Action */
export interface RegularInquirySuccessAction
  extends Action<typeof REGULAR_INQUIRY_POST_SUCCESS> {
  payload: Inquiry
}

const regularInquirySuccessAction = (payload: Inquiry)
  : RegularInquirySuccessAction => {
  return {
    type: REGULAR_INQUIRY_POST_SUCCESS,
    payload,
  }
}

/* 定期利用問い合わせ失敗 Action */
export interface RegularInquiryFailAction
  extends SnackBarAction<typeof REGULAR_INQUIRY_POST_FAIL> {
  
}

const regularInquiryFailAction = (
  message: string
): RegularInquiryFailAction => {
  return {
    type: REGULAR_INQUIRY_POST_FAIL, snackBarMessage: message,
    variant: 'error' };
};


/* --------------------------------------------------------------------------------- */

/* 一般問い合わせ送信 */
async function postCommonInquiry(data: Inquiry, access_token: string): Promise<Masters> {  

  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token

  const periodDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/inquiry`, header, data);
  const masterData: Masters = await periodDataRes.data;

  return masterData;
}

/* 一般問い合わせを送信する */
export function commonInquiryPostAction(data: Inquiry): CommonInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, CommonInquiryActions>,
    getState: () => AppState) => {
    try {
      dispatch(
        commonInquiryStartAction()
      )

      const state = getState()

      await postCommonInquiry(data, state.authStatus.access_token)

      dispatch(
        commonInquirySuccessAction(data),
      )
      /* 完了画面へ遷移 */
      dispatch(push(routes.inquiryComplete.path)) 
    } catch (err) {
      dispatch<CommonInquiryActions>(
        await ajaxErrorHandler(err, commonInquiryFailAction)
      )
    }
  }
}

/* 定期問い合わせ送信 */
async function postRegularInquiry(data: Inquiry, access_token: string): Promise<Masters> {  
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token
  const periodDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/regularinquiry`, header, data);  
  const masterData: Masters = await periodDataRes.data;

  return masterData;
}

/* 定期利用問い合わせを送信する */
export function regularInquiryPostAction(data: Inquiry): RegularInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, RegularInquiryActions>,
    getState: () => AppState) => {
    try {
      dispatch(
        regularInquiryStartAction()
      )

      const state = getState()
      await postRegularInquiry(data, state.authStatus.access_token)

      dispatch(
        regularInquirySuccessAction(data),
      )
      /* 完了画面へ遷移 */
      dispatch(push(routes.inquiryComplete.path))         
    } catch (err) {
      dispatch<RegularInquiryActions>(
        await ajaxErrorHandler(err, regularInquiryFailAction)
      )
    }
  }
}

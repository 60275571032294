import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import ForgotPasswordForm, { ForgotPasswordFormProps, validate } from '../components/Auth/ForgotPasswordForm'
import { SubmissionError } from 'redux-form'
import { Values, Errors } from '../types/formTypes'

import { beginResetPassword } from '../actions/authenticationActions'

interface OwnProps {  
}

export type ForgotPasswordFormContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

export default function ForgotPasswordFormContainer(props: ForgotPasswordFormContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      
      ownDispatch(beginResetPassword(values['email']))
    }
  }

  // 初期化
  useEffect(() => {
    
  }, []);
    
  const _props = {    
    onSubmit: submit
  }

  return <ForgotPasswordForm {..._props} />

}